import * as React from "react";

import { INJECTION_TOKENS } from "../../../application";
import { ItemService } from "../../../application";
import { useDependencyInjectionService } from "../../../presentation";

export const useItemService = (): [ItemService] => {
  const [containerSvc] = useDependencyInjectionService();
  const itemSvc = containerSvc.getService<ItemService>(
    INJECTION_TOKENS.ItemService
  );

  return [itemSvc];
};
