import * as React from "react";

import { CommonIconButton, HeaderNormal } from "../../../../presentation";

import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core";

export interface TitleWithCloseButtonProps {
  title: string;
  onClose(): void;
}

export const TitleWithCloseButton: React.FC<TitleWithCloseButtonProps> = ({
  title,
  onClose,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.headerTitle}>
        <HeaderNormal title={title} />
      </div>
      <div className={classes.headerCloseButton}>
        <CommonIconButton icon={<CloseIcon />} onClick={onClose} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
  },
  headerTitle: {
    flexGrow: 1,
  },
  headerCloseButton: {},
}));

export default TitleWithCloseButton;
