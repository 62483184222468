import * as React from "react";

import {
  AddItemsModel,
  ItemOverviewViewModel,
  Result,
} from "../../../../application";

import { useTopicService } from "../../../../presentation";

export interface ItemsContainerProps {
  topicId: number;
  children(api: Api): React.ReactNode;
}

interface Api {
  itemsVM: ItemOverviewViewModel[];
  loading: boolean;
  reasons: string[] | null;
  addItems(itemTypes: number[]): Promise<Result>;
}

export const ItemsContainer: React.FC<ItemsContainerProps> = ({
  topicId,
  children,
}) => {
  const [topicSvc] = useTopicService();
  const [itemsVM, setItemsVM] = React.useState<ItemOverviewViewModel[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [reasons, setReasons] = React.useState<string[] | null>(null);

  React.useEffect(() => {
    loadItems();
  }, [topicId]);

  const loadItems = async () => {
    setReasons(null);
    setLoading(true);
    const result = await topicSvc.getItems(topicId);
    setLoading(false);

    if (result.isSuccess && result.value) setItemsVM(result.value);
    if (result.isFailed) setReasons(result.reasons);
  };

  const addItems = async (itemTypes: number[]): Promise<Result> => {
    setReasons(null);
    const model = AddItemsModel.fromItemTypes(itemTypes);

    setLoading(true);
    const result = await topicSvc.addItems(topicId, model);
    setLoading(false);

    if (result.isSuccess) loadItems();
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const api: Api = {
    itemsVM,
    loading,
    reasons,
    addItems,
  };
  return <>{children(api)}</>;
};

export default ItemsContainer;
