import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  makeStyles,
} from "@material-ui/core";
import {
  HeaderExtraSmall,
  ItemTypeImage,
  Text,
} from "../../../../../presentation";
import React, { useState } from "react";

import { ItemTypeHelper } from "../../../../../application";

const useStyles = makeStyles((theme) => ({
  image: {
    height: 50,
  },
  listItem: {
    marginBottom: 24,
  },
  listItemText: {
    color: theme.palette.text.secondary,
  },
}));

export interface ItemsSelectorProps {
  existingItemTypes: number[];
  onChange(selected: number[]): void;
}

export const ItemsSelector: React.FC<ItemsSelectorProps> = ({
  existingItemTypes,
  onChange,
}) => {
  const classes = useStyles();
  const itemTypes = ItemTypeHelper.getItemTypes();
  const [checked, setChecked] = useState<number[]>(existingItemTypes);

  React.useEffect(() => {
    onChange(existingItemTypes);
  }, [existingItemTypes]);

  const isChecked = (itemType: number): boolean => {
    return checked.indexOf(itemType) !== -1;
  };

  const handleToggle = (itemType: number) => {
    const newChecked = [...checked];

    const index = checked.indexOf(itemType);

    if (index !== -1) {
      // UNCHECK
      newChecked.splice(index, 1);
    } else {
      // CHECK
      newChecked.push(itemType);
    }

    setChecked(newChecked);
    onChange(newChecked);
  };

  return (
    <List>
      {itemTypes.map((itemType: number) => (
        <Box key={itemType}>
          <ListItem
            button
            divider
            onClick={() => handleToggle(itemType)}
            className={classes.listItem}
          >
            <ListItemIcon>
              <Switch checked={isChecked(itemType)} color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <HeaderExtraSmall
                  color="primary"
                  title={ItemTypeHelper.getItemTypeLabel(itemType)}
                />
              }
              secondary={
                <Text>{ItemTypeHelper.getItemTypeDescription(itemType)}</Text>
              }
              className={classes.listItemText}
            />
            <ListItemIcon>
              <ItemTypeImage itemType={itemType} className={classes.image} />
            </ListItemIcon>
          </ListItem>
        </Box>
      ))}
    </List>
  );
};

export default ItemsSelector;
