import * as React from "react";

import { CommonIconButton } from "../../../../../../presentation";
import RemarksIcon from "@material-ui/icons/ChatOutlined";
import { makeStyles } from "@material-ui/core";

export interface RemarksIconButtonProps {
  totalRemarks: number;
  totalUnSeenRemarks: number;
  onClick(): void;
}

export const RemarksIconButton: React.FC<RemarksIconButtonProps> = ({
  totalRemarks: totalNewRemarks,
  totalUnSeenRemarks,
  onClick,
}) => {
  // STYLES
  const classes = useStyles();

  // VARIABLES
  const newRemarks = totalUnSeenRemarks > 0;
  const amount = newRemarks ? `+${totalUnSeenRemarks}` : `${totalNewRemarks}`;

  return (
    <CommonIconButton
      badgeContent={amount}
      badgeClass={classes.badge}
      badgeColor={newRemarks ? "error" : undefined}
      icon={<RemarksIcon />}
      size="small"
      className={classes.button}
      onClick={onClick}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    zIndex: 10,
  },
  badge: {
    "& .MuiBadge-badge": {
      transform: "scale(0.8) translate(50%, -40%)",
      "&.MuiBadge-invisible": {
        transform: "scale(0)",
      },
    },
  },
}));

export default RemarksIconButton;
