import * as React from "react";

import {
  Aligner,
  HeaderLarge,
  HeaderNormal,
  LoadingSpinner,
  SectionSmall,
} from "../../../../presentation";

export interface LoadingScreenProps {
  spinnerSize?: number;
}

export const LoadingScreen: React.FC<LoadingScreenProps> = ({
  spinnerSize = 50,
}) => {
  return (
    <>
      <Aligner horizontal>
        <SectionSmall>
          <HeaderLarge title="Een ogenblik geduld" />
          <HeaderNormal title="Informatie wordt opgehaald" />
        </SectionSmall>

        <SectionSmall>
          <LoadingSpinner size={spinnerSize} />
        </SectionSmall>
      </Aligner>
    </>
  );
};

export default LoadingScreen;
