import * as React from "react";

import {
  RemarksModel,
  Result,
  TasksItemModel,
  TasksItemService,
} from "../../../application";

import { useTasksItemService } from "../../../presentation";

interface HookState {
  tasksItem: TasksItemModel | null;
  remarks: RemarksModel | null;
  loading: boolean;
  reasons: string[] | null;
}

interface HookMethods extends TasksItemService {}

export const useTasksItemUsecases = (): [HookState, HookMethods] => {
  const [tasksItemSvc] = useTasksItemService();
  const [tasksItem, setTasksItem] = React.useState<TasksItemModel | null>(null);
  const [remarks, setRemarks] = React.useState<RemarksModel | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [reasons, setReasons] = React.useState<string[] | null>(null);

  const getTasksItem = async (itemId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await tasksItemSvc.getTasksItem(itemId);
    setLoading(false);

    if (result.isSuccess && result.value) setTasksItem(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return Result.toGenericResult(result);
  };

  const addTask = async (
    itemId: number,
    description: string,
    assignedTo: number[]
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await tasksItemSvc.addTask(itemId, description, assignedTo);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return Result.toGenericResult(result);
  };

  const updateTask = async (
    taskId: number,
    description: string,
    assignedTo: number[]
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await tasksItemSvc.updateTask(
      taskId,
      description,
      assignedTo
    );
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return Result.toGenericResult(result);
  };

  const removeTask = async (taskId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await tasksItemSvc.removeTask(taskId);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return Result.toGenericResult(result);
  };

  const getTaskRemarks = async (taskId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await tasksItemSvc.getTaskRemarks(taskId);
    setLoading(false);

    if (result.isSuccess && result.value) setRemarks(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return Result.toGenericResult(result);
  };

  const addTaskRemark = async (
    taskId: number,
    text: string
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await tasksItemSvc.addTaskRemark(taskId, text);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return Result.toGenericResult(result);
  };

  const removeTaskRemark = async (remarkId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await tasksItemSvc.removeTaskRemark(remarkId);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return Result.toGenericResult(result);
  };

  const updateTaskStatus = async (
    taskId: number,
    completed: boolean
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await tasksItemSvc.updateTaskStatus(taskId, completed);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return Result.toGenericResult(result);
  };

  return [
    { tasksItem, remarks, loading, reasons },
    {
      getTasksItem,
      addTask,
      updateTask,
      removeTask,
      getTaskRemarks,
      addTaskRemark,
      removeTaskRemark,
      updateTaskStatus,
    },
  ];
};
