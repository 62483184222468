import { DateTime, DateTimeOptions, Settings } from "luxon";

Settings.defaultLocale = "nl";

const getShortDateString = (isoDate: string, toLocal = true): string => {
  const format = "ccc dd-MM-yy";
  const options = getOptions(toLocal);

  return DateTime.fromISO(isoDate, options).toFormat(format);
};

const getShortDateString2 = (isoDate: string, toLocal = true): string => {
  const format = "dd-MM-yyyy";
  const options = getOptions(toLocal);

  return DateTime.fromISO(isoDate, options).toFormat(format);
};

const getShortDateTimeString = (isoDate: string, toLocal = true): string => {
  const format = "dd-MM-yyyy HH:mm";
  const options = getOptions(toLocal);

  return DateTime.fromISO(isoDate, options).toFormat(format);
};

const getFullDateString = (isoDate: string, toLocal = true): string => {
  const format = "cccc d MMMM yyyy";
  const options = getOptions(toLocal);

  return DateTime.fromISO(isoDate, options).toFormat(format);
};

const getOptions = (toLocal: boolean): DateTimeOptions => {
  return toLocal ? {} : { zone: "utc" };
};

const localToUtcDate = (localDate: Date): string => {
  const date = DateTime.fromObject({
    year: localDate.getFullYear(),
    month: localDate.getMonth() + 1,
    day: localDate.getDate(),
    zone: "utc",
  });

  return date.toISO();
};

export {
  getShortDateString,
  getShortDateString2,
  getShortDateTimeString,
  getFullDateString,
  localToUtcDate,
};
