import * as React from "react";

import { CostsItemService, INJECTION_TOKENS } from "../../../application";

import { useDependencyInjectionService } from "../../../presentation";

export const useCostsItemService = (): [CostsItemService] => {
  const [containerSvc] = useDependencyInjectionService();
  const costsItemSvc = containerSvc.getService<CostsItemService>(
    INJECTION_TOKENS.CostsItemService
  );

  return [costsItemSvc];
};
