import * as React from "react";

import {
  ActivitiesDialog,
  ButtonBar,
  ButtonItem,
  CommonButton,
  CommonIconButton,
  CommonMenu,
  CommonPaper,
  ContainerLarge,
  DatePropositionList,
  HeaderNormal,
  HideOnMobile,
  ItemStatusBar,
  LoadingScreen,
  MobileNavigationBar,
  MobileOnly,
  OpenActionsReasonsBox,
  Paragraph,
  PinIcon,
  RemarksDialog,
  SectionBox,
  useDateItemUsecases,
  useDialog,
  useItemUsecases,
} from "../../../../presentation";
import {
  DatePropositionModel,
  DateUtils,
  GenericVoteType,
  ItemType,
  Result,
} from "../../../../application";
import { makeStyles, useTheme } from "@material-ui/core";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import AddDateIcon from "@material-ui/icons/AddCircle";
import AddDateMobileIcon from "@material-ui/icons/AddCircleOutline";
import BackIcon from "@material-ui/icons/ArrowBack";
import CancelMobileIcon from "@material-ui/icons/CloseOutlined";
import DatePinnedIcon from "@material-ui/icons/Done";
import SortIcon from "@material-ui/icons/SortOutlined";
import UndoIcon from "@material-ui/icons/Replay";

export interface DateItemPageProps {}

interface RouteParams {
  topicId: string;
  dateItemId: string;
}

export const DateItemPage: React.FC<DateItemPageProps> = () => {
  // STYLES
  const classes = useStyles();
  const theme = useTheme();

  // ROUTER
  const params = useParams<RouteParams>();
  const { push } = useHistory();
  const { url } = useRouteMatch();

  // STATE
  const [selectionMode, setSelectionMode] = React.useState(false);
  const [sortType, setSortType] = React.useState<number>(1);
  const [remarksParentId, setRemarksParentId] = React.useState<number>(0);

  // APPLICATION
  const [
    { dateItem, loading: loadingItem, reasons: reasonsItem },
    { loadDateItem },
  ] = useItemUsecases();
  const [
    { reasons: reasonsDateItem, remarksModel },
    {
      removeDateProposition,
      voteOnDateProposition,
      pinDate,
      getRemarks,
      addRemark,
      removeRemark,
    },
  ] = useDateItemUsecases();

  // DIALOGS
  const [
    { isOpen: isActivityDialogOpen },
    { open: openActivityDialog, close: closeActivityDialog },
  ] = useDialog();
  const [
    { isOpen: isRemarksDialogOpen },
    { open: openRemarksDialog, close: closeRemarksDialog },
  ] = useDialog();

  // VARIABLES
  const topicId = parseInt(params.topicId);
  const dateItemId = parseInt(params.dateItemId);
  let summaryPrimaryText: any = null;
  const summarySecondary: any = {};

  const pinnedDateProposition = dateItem?.datePropositions.find(
    (dp) => dp.isPinned === true
  );
  if (pinnedDateProposition) {
    // Extract date info
    summaryPrimaryText = DateUtils.getFullDateString(
      pinnedDateProposition.startDateInfo.startDate
    );

    const startTime = pinnedDateProposition.startDateInfo.startTime;
    const endTime = pinnedDateProposition.endDateInfo.endTime;

    if (startTime) {
      summarySecondary.leftBlock = startTime;
      if (!endTime) summarySecondary.rightBlock = "-";
    }

    if (endTime) {
      summarySecondary.rightBlock = endTime;
      if (!startTime) summarySecondary.leftBlock = "-";
    }
  }

  React.useEffect(() => {
    if (!dateItemId) return;

    load();
  }, [dateItemId]);

  const load = async () => {
    const result = await loadDateItem(dateItemId);

    if (result.value && result.value.done === true) setSortType(2);
  };

  if (isNaN(topicId) || isNaN(dateItemId)) return null;

  const navigateBackToTopicOverview = () => {
    push(`/topics/${params.topicId}`);
  };

  const navigateToNewDatePropositionPage = () => {
    push(`${url}/new-proposition`);
  };

  const navigateToDatePropositionChangeTimePage = (
    datePropositionId: number
  ) => {
    push(`${url}/date-propositions/${datePropositionId}/change-time`);
  };

  const handleEditTime = async (datePropositionId: number) => {
    navigateToDatePropositionChangeTimePage(datePropositionId);
  };

  const handleDelete = async (datePropositionId: number) => {
    const result = await removeDateProposition(datePropositionId);

    if (result.isSuccess) await load();
  };

  const handleVote = async (
    datePropositionId: number,
    voteType: GenericVoteType
  ) => {
    const result = await voteOnDateProposition(datePropositionId, voteType);

    if (result.isSuccess) await load();
  };

  const handlePinDate = async (datePropositionId: number) => {
    const result = await pinDate(datePropositionId);
    setSelectionMode(false);

    if (result.isSuccess) await load();
  };

  const handleOpenRemarks = async (id: number) => {
    await getRemarks(id);
    setRemarksParentId(id);
    openRemarksDialog();
  };

  const handleRemarksSend = async (message: string): Promise<Result> => {
    const result = await addRemark(remarksParentId, message);

    if (result.isSuccess) await getRemarks(remarksParentId);

    return result;
  };

  const handleRemarksDelete = async (remarkId: number) => {
    const result = await removeRemark(remarkId);

    if (result.isSuccess) await getRemarks(remarksParentId);
  };

  const handleRemarksClose = () => {
    load();
    closeRemarksDialog();
  };

  const handleUnPinDate = async () => {
    const pinnedProposition = dateItem?.datePropositions.find(
      (dp) => dp.isPinned === true
    );
    if (!pinnedProposition) return;

    await handlePinDate(pinnedProposition.id);
  };

  const renderDefinitiveButton = (mobile: boolean = false) => {
    if (dateItem && !dateItem.done && !selectionMode) {
      const style: React.CSSProperties = {
        color: "#fff",
      };

      return (
        <CommonButton
          color={mobile ? "primary" : undefined}
          disabled={!dateItem.datePropositions.length}
          label="Prik"
          startIcon={<PinIcon style={mobile ? style : undefined} />}
          variant={mobile ? "contained" : "outlined"}
          onClick={() => setSelectionMode(true)}
        />
      );
    }

    if (selectionMode) {
      return (
        <CommonButton
          label="Annuleer"
          variant="contained"
          className={classes.cancelSelectionButton}
          onClick={() => setSelectionMode(false)}
        />
      );
    }

    if (dateItem && dateItem.done) {
      return (
        <CommonButton
          color="primary"
          label="Geprikt"
          startIcon={<DatePinnedIcon />}
          onClick={handleUnPinDate}
        />
      );
    }

    return null;
  };

  const renderButton = (): ButtonItem => {
    let icon = null;
    let onClickHandler = () => {};

    if (dateItem && !dateItem.done && !selectionMode) {
      icon = <PinIcon />;
      onClickHandler = () => setSelectionMode(true);
    } else if (!dateItem?.done && selectionMode) {
      icon = <CancelMobileIcon style={{ color: theme.palette.error.main }} />;
      onClickHandler = () => setSelectionMode(false);
    } else {
      icon = <UndoIcon />;
      onClickHandler = handleUnPinDate;
    }

    return {
      icon: icon,
      onClick: onClickHandler,
    };
  };

  const sortPropositions = (
    propositions: DatePropositionModel[],
    sortType: number
  ) => {
    switch (sortType) {
      case 1:
        return propositions.sort((a, b) => {
          if (a.startDateInfo.startDate > b.startDateInfo.startDate) return 1;
          if (a.startDateInfo.startDate < b.startDateInfo.startDate) return -1;

          return 0;
        });
      case 2:
        return propositions.sort((a, b) => {
          const totalPointsA = a.votes.reduce((previous, current) => {
            let points = 0;

            switch (current.voteType) {
              case GenericVoteType.Yes:
                points = 2;
                break;
              case GenericVoteType.Maybe:
                points = 1;
                break;
            }

            return previous + points;
          }, 0);
          const totalPointsB = b.votes.reduce((previous, current) => {
            let points = 0;

            switch (current.voteType) {
              case GenericVoteType.Yes:
                points = 2;
                break;
              case GenericVoteType.Maybe:
                points = 1;
                break;
            }

            return previous + points;
          }, 0);

          if (totalPointsA > totalPointsB) return -1;
          if (totalPointsA < totalPointsB) return 1;

          return 0;
        });
      default:
        return propositions;
    }
  };

  return (
    <ContainerLarge>
      {dateItem && (
        <ItemStatusBar itemType={ItemType.Date} done={dateItem.done} />
      )}

      <MobileOnly>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <HeaderNormal
              title="Datum"
              disableGutter
              style={{ margin: "8px 0" }}
            />
          </div>
          <div>
            <CommonMenu>
              {({ open }) => ({
                button: <CommonIconButton icon={<SortIcon />} onClick={open} />,
                items: [
                  {
                    label: "Datum",
                    selected: sortType === 1,
                    handleClick: () => setSortType(1),
                  },
                  {
                    label: "Beste score",
                    selected: sortType === 2,
                    handleClick: () => setSortType(2),
                  },
                ],
              })}
            </CommonMenu>
          </div>
        </div>
      </MobileOnly>

      <HideOnMobile>
        <SectionBox>
          <CommonButton
            label="Naar overzicht"
            startIcon={<BackIcon />}
            variant="text"
            onClick={navigateBackToTopicOverview}
          />
        </SectionBox>

        <SectionBox>
          <HeaderNormal title="Datum" />
          <Paragraph>
            Voeg datums toe en prik als groep de datum die het beste uitkomt.
          </Paragraph>
        </SectionBox>
      </HideOnMobile>

      {dateItem && dateItem.hasOpenActions && (
        <SectionBox>
          <OpenActionsReasonsBox
            reasons={[
              "Er zijn nog datumvoorstel(len) waar jij niet op hebt gestemd",
            ]}
          />
        </SectionBox>
      )}

      <CommonPaper>
        <HideOnMobile>
          <SectionBox>
            <ButtonBar>
              <CommonButton
                disabled={!dateItem ? true : dateItem.done}
                label="Toevoegen"
                startIcon={<AddDateIcon />}
                onClick={navigateToNewDatePropositionPage}
              />

              {renderDefinitiveButton()}

              <CommonMenu>
                {({ open }) => ({
                  button: (
                    <CommonButton
                      label="Sorteren"
                      startIcon={<SortIcon />}
                      onClick={open}
                    />
                  ),
                  items: [
                    {
                      label: "Datum",
                      selected: sortType === 1,
                      handleClick: () => setSortType(1),
                    },
                    {
                      label: "Beste score",
                      selected: sortType === 2,
                      handleClick: () => setSortType(2),
                    },
                  ],
                })}
              </CommonMenu>

              {/* <CommonButton
                label="Activiteiten"
                startIcon={<NotificationsIcon />}
                onClick={openActivityDialog}
              /> */}
            </ButtonBar>
          </SectionBox>
        </HideOnMobile>

        {loadingItem && !dateItem && <LoadingScreen />}
        {dateItem && dateItem.datePropositions.length > 0 && (
          <SectionBox className={classes.datePropositionListBox}>
            <DatePropositionList
              datePropositions={
                sortPropositions(dateItem.datePropositions, sortType) || []
              }
              selectionMode={selectionMode}
              done={dateItem.done}
              onEditTime={handleEditTime}
              onDelete={handleDelete}
              onVote={handleVote}
              onPinDate={handlePinDate}
              onOpenRemarks={handleOpenRemarks}
            />
          </SectionBox>
        )}

        {dateItem && dateItem.datePropositions.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <Paragraph>Er zijn nog geen datums toegevoegd.</Paragraph>
          </div>
        )}
      </CommonPaper>

      <MobileNavigationBar>
        {() => ({
          buttons: [
            renderButton(),
            {
              icon: <AddDateMobileIcon />,
              disabled: !dateItem || selectionMode ? true : dateItem.done,
              onClick: navigateToNewDatePropositionPage,
            },
            // { icon: <NotificationsIcon />, onClick: openActivityDialog },
            { icon: <BackIcon />, onClick: navigateBackToTopicOverview },
          ],
        })}
      </MobileNavigationBar>

      <ActivitiesDialog
        isOpen={isActivityDialogOpen}
        topicId={topicId}
        onClose={closeActivityDialog}
      />

      <RemarksDialog
        open={isRemarksDialogOpen}
        subject={remarksModel?.subject}
        remarks={remarksModel?.remarks}
        onClose={handleRemarksClose}
        onSend={handleRemarksSend}
        onDelete={handleRemarksDelete}
      />
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  datePropositionListBox: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "0rem",
    },
  },
  cancelSelectionButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export default DateItemPage;
