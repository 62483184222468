import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    const options = {
      hitType: "pageview",
      page: location.pathname + location.search,
    };
    ReactGA.send(options);
  }, [location]);
};
