import * as React from "react";

import { makeStyles } from "@material-ui/core";

// MATERIAL-UI HOOKS

export interface CardHeaderProps {
  confirmed?: boolean;
  locked?: boolean;
}

export const CardHeader: React.FC<CardHeaderProps> = ({
  confirmed = false,
  locked = false,
  children,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div
      className={`
      ${classes.cardHeader}  
  `}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 5,
    borderBottom: "1px solid #eee",
    transition: "background-color 600ms",
  },
  cardHeaderConfirmed: {
    backgroundColor: theme.palette.success.main,
  },
  cardHeaderLocked: {
    backgroundColor: theme.palette.error.main,
  },
}));

export default CardHeader;
