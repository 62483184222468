import * as React from "react";

import {
  CommonButton,
  HeaderNormal,
  ItemsSelector,
  ReasonList,
} from "../../../../../presentation";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { Result } from "../../../../../application";

export interface AddItemsDialogProps {
  isOpen: boolean;
  topicId: number;
  existingItemTypes: number[];
  loading: boolean;
  reasons: string[] | null;
  onClose(): void;
  addItems(itemTypes: number[]): Promise<Result>;
}

export const AddItemsDialog: React.FC<AddItemsDialogProps> = ({
  isOpen,
  topicId,
  existingItemTypes,
  loading,
  reasons,
  addItems,
  onClose,
}) => {
  // HOOKS
  const theme = useTheme();

  // STATE
  const [selected, setSelected] = React.useState<number[]>([]);

  // VARIABLES
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));

  // METHODS
  const handleChange = (selected: number[]) => {
    setSelected(selected);
  };

  const handleSave = async () => {
    const result = await addItems(selected);

    if (result.isSuccess) handleClose();
  };

  const handleClose = () => {
    setSelected([]);
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        fullScreen={isSmallScreen}
      >
        <DialogTitle disableTypography>
          <HeaderNormal
            title="Welke blokkies wil je toevoegen?"
            disableGutter
          />
        </DialogTitle>
        <DialogContent dividers>
          <ItemsSelector
            existingItemTypes={existingItemTypes}
            onChange={handleChange}
          />

          <ReasonList reasons={reasons} />
        </DialogContent>
        <DialogActions>
          <CommonButton
            label="Annuleren"
            color="secondary"
            variant="outlined"
            onClick={() => handleClose()}
          />
          <CommonButton
            label="Opslaan"
            color="primary"
            loading={loading}
            variant="contained"
            onClick={() => handleSave()}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddItemsDialog;
