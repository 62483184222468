import * as React from "react";

import { CommonIconButton, Paragraph } from "../../../../../presentation";
import { Grid, makeStyles } from "@material-ui/core";

import AddIcon from "@material-ui/icons/AddCircleOutline";
import { NumberFormatUtils } from "../../../../../application";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";

export interface CostDivisionProps {
  userId: number;
  username: string;
  share: number;
  amount: number;
  onChange(userId: number, share: number): void;
}

export const CostDivision: React.FC<CostDivisionProps> = ({
  userId,
  username,
  share,
  amount,
  onChange,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div className={classes.costDivision}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={4}>
          <Paragraph>{username}</Paragraph>
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          <div className={classes.shareWrapper}>
            <CommonIconButton
              disabled={share === 0}
              icon={<RemoveIcon />}
              onClick={() => onChange(userId, share - 1)}
            />
            <Paragraph>{share}</Paragraph>
            <CommonIconButton
              icon={<AddIcon />}
              onClick={() => onChange(userId, share + 1)}
            />
          </div>
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          <Paragraph>
            <strong>
              € {NumberFormatUtils.format(amount, { minimumFractionDigits: 2 })}
            </strong>
          </Paragraph>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  costDivision: {},
  gridItem: { textAlign: "center" },
  shareWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

export default CostDivision;
