import * as React from "react";

import { InputBaseComponentProps, TextField } from "@material-ui/core";

export interface GenericTextFieldProps {
  displayLabel: string;
  fieldName: string;
  type?: string | "text" | "email" | "number";
  inputRef?: React.Ref<any>;
  errorMessage?: string | null;
  value?: string | null;
  defaultValue?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  className?: string;
  inputProps?: InputBaseComponentProps;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const GenericTextField: React.FC<GenericTextFieldProps> = ({
  displayLabel,
  fieldName,
  type = "text",
  inputRef,
  errorMessage,
  value,
  defaultValue,
  disabled = false,
  fullWidth = true,
  style,
  className,
  inputProps,
  onChange = (e) => {},
}) => {
  return (
    <TextField
      label={displayLabel}
      name={fieldName}
      type={type}
      inputRef={inputRef}
      inputProps={inputProps}
      error={!!errorMessage}
      helperText={errorMessage}
      margin="normal"
      fullWidth={fullWidth}
      variant="outlined"
      value={value}
      disabled={disabled}
      defaultValue={defaultValue}
      classes={{ root: className }}
      style={style}
      onChange={onChange}
    />
  );
};

export default GenericTextField;
