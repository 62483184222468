import * as React from "react";

import {
  ButtonBar,
  CommonButton,
  CommonSelect,
  ContainerLarge,
  GenericTextField,
  HeaderNormal,
  ReasonList,
  SectionBox,
  useTasksItemUsecases,
  useTopicUsecases,
} from "../../../../presentation";
import { MenuItem, makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import { useForm } from "react-hook-form";

export interface NewTaskPageProps {}

interface RouteParams {
  topicId: string;
  tasksItemId: string;
}

export const NewTaskPage: React.FC<NewTaskPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // ROUTER
  const params = useParams<RouteParams>();
  const { push } = useHistory();

  // USECASES
  const [{ members, loading: membersLoading }, { getMembers }] =
    useTopicUsecases();
  const [{ loading, reasons }, { addTask }] = useTasksItemUsecases();

  // VARIABLES
  const topicId = parseInt(params.topicId);
  const tasksItemId = parseInt(params.tasksItemId);
  const url_Back = `/topics/${topicId}/tasks-items/${tasksItemId}`;
  const { register, handleSubmit, control, errors } = useForm();

  React.useEffect(() => {
    loadMembers();
  }, []);

  // METHODS
  const loadMembers = async () => {
    if (!topicId) return;
    await getMembers(topicId);
  };

  const handleNavigationBack = () => {
    push(url_Back);
  };

  const handleActualSubmit = async (fieldValues: any) => {
    const assignedTo = [];

    if (fieldValues.assignedTo) assignedTo.push(fieldValues.assignedTo);

    const result = await addTask(
      tasksItemId,
      fieldValues.description,
      assignedTo
    );

    if (result.isSuccess) handleNavigationBack();
  };

  return (
    <ContainerLarge>
      <SectionBox>
        <HeaderNormal title="Taak toevoegen" />
      </SectionBox>

      <SectionBox>
        <div className={classes.formWrapper}>
          <form autoComplete="off" onSubmit={handleSubmit(handleActualSubmit)}>
            <div>
              <GenericTextField
                displayLabel="Omschrijving *"
                fieldName="description"
                inputRef={register({
                  required: true,
                })}
              />
            </div>

            <div>
              <CommonSelect
                label="Toewijzen aan"
                name="assignedTo"
                control={control}
              >
                <MenuItem value="">(Leeg)</MenuItem>
                {members &&
                  members.map((m) => (
                    <MenuItem key={m.id} value={m.id}>
                      {m.username}
                    </MenuItem>
                  ))}
              </CommonSelect>
            </div>

            <SectionBox>
              <ButtonBar>
                <CommonButton
                  label="Annuleren"
                  onClick={handleNavigationBack}
                />
                <CommonButton
                  color="primary"
                  label="Toevoegen"
                  loading={loading}
                  type="submit"
                  variant="contained"
                />
              </ButtonBar>
            </SectionBox>
          </form>
          {reasons && (
            <SectionBox>
              <ReasonList reasons={reasons} />
            </SectionBox>
          )}
        </div>
      </SectionBox>
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    maxWidth: 600,
  },
}));

export default NewTaskPage;
