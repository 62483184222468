import * as React from "react";

// MATERIAL-UI HOOKS
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonBar: {
    "& > *": {
      marginLeft: 10,
      marginBottom: 5,
    },
    "& > *:first-child": {
      [theme.breakpoints.up("sm")]: { marginLeft: 0 },
    },
  },
}));

export interface ButtonBarProps {
  justifyContent?: "space-around" | "space-between";
}
export const ButtonBar: React.FC<ButtonBarProps> = ({
  justifyContent,
  children,
}) => {
  const classes = useStyles();

  let styles: React.CSSProperties = {};

  if (justifyContent) {
    styles.justifyContent = justifyContent;
    styles.display = "flex";
  }

  return (
    <div className={justifyContent ? "" : classes.buttonBar} style={styles}>
      {children}
    </div>
  );
};
