import * as React from "react";

import {
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  HeaderExtraSmall,
  HeaderSmall,
  MembersHelper,
  Paragraph,
} from "../../../../../../presentation";

import { MemberVoteModel } from "../../../../../../application";

export interface VotedUserListProps {
  memberVotes: MemberVoteModel[];
}

export const VotedUserList: React.FC<VotedUserListProps> = ({
  memberVotes,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <List>
      <ListItem>
        <ListItemText
          primary={
            <div className={classes.wrapper}>
              <div className={classes.usernameBox}>
                <HeaderSmall title="Gebruikers" />
              </div>
              <div className={classes.labelBox}>
                <HeaderSmall title="Stem" />
              </div>
            </div>
          }
        />
      </ListItem>
      {memberVotes.map((mv, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            <Avatar style={{ textTransform: "uppercase" }}>
              {MembersHelper.getInitials(mv.username)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <div className={classes.wrapper}>
                <div className={classes.usernameBox}>
                  <Paragraph>{`${mv.username}`}</Paragraph>
                </div>
                <div className={classes.labelBox}>
                  {mv.isVoted ? <Chip label={mv.voteLabel} /> : "-"}
                </div>
              </div>
            }
            secondary={`${mv.email}`}
          />
        </ListItem>
      ))}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: 36,
  },
  usernameBox: {
    flexGrow: 1,
  },
  labelBox: {
    marginTop: 4,
    width: 85,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      width: 150,
    },
  },
  chipSecondary: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

export default VotedUserList;
