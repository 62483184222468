import {
  DependencyInjectionService,
  IDependencyInjectionService,
} from "../../../presentation";

import React from "react";

export const useDependencyInjectionService = (): [
  IDependencyInjectionService
] => {
  return [new DependencyInjectionService()];
};
