import React, { useEffect, useState } from "react";
import {
  Result,
  SignupModel,
  TopicRegistrationViewModel,
} from "../../../../../application";

import { useTopicService } from "../../../../../presentation";

export interface TopicRegistrationContainerProps {
  topicId: number;
  registrationKey: string;
  children(api: Api): React.ReactNode;
}

interface Api {
  viewModel: TopicRegistrationViewModel | null;
  loadingGetByRegistrationKey: boolean;
  loadingSignup: boolean;
  reasons: string[] | null;
  signup(
    id: number,
    registrationKey: string,
    model: SignupModel
  ): Promise<Result>;
}

export const TopicRegistrationContainer: React.FC<TopicRegistrationContainerProps> =
  ({ topicId, registrationKey, children }) => {
    const [topicSvc] = useTopicService();
    const [viewModel, setViewModel] =
      useState<TopicRegistrationViewModel | null>(null);
    const [loadingGetByRegistrationKey, setLoadingGetByRegistrationKey] =
      useState(false);
    const [loadingSignup, setLoadingSignup] = useState(false);
    const [reasons, setReasons] = useState<string[] | null>(null);

    useEffect(() => {
      getByRegistrationKey(topicId, registrationKey);
    }, []);

    const getByRegistrationKey = async (
      id: number,
      registrationKey: string
    ): Promise<Result<TopicRegistrationViewModel> | Result> => {
      setReasons(null);
      setLoadingGetByRegistrationKey(true);
      const result = await topicSvc.getByRegistrationKey(id, registrationKey);
      setLoadingGetByRegistrationKey(false);

      if (result.isSuccess && result.value) setViewModel(result.value);
      if (result.isFailed) setReasons(result.reasons);

      return result;
    };

    const signup = async (
      id: number,
      registrationKey: string,
      model: SignupModel
    ): Promise<Result> => {
      setReasons(null);
      setLoadingSignup(true);
      const result = await topicSvc.signup(id, registrationKey, model);
      setLoadingSignup(false);

      if (result.isFailed) setReasons(result.reasons);

      return result;
    };

    const api: Api = {
      viewModel,
      loadingGetByRegistrationKey,
      loadingSignup,
      reasons,
      signup,
    };

    return <>{children(api)}</>;
  };

export default TopicRegistrationContainer;
