import {
  CostAndCalculationSummaryModel,
  CostForUpdateViewModel,
  CostItemRepository,
  CostsItemModel,
  CostsItemStatus,
  RemarksModel,
  Result,
  UpdateCostDivision,
} from "../../../application";
import { get, post, put, remove } from "../../../infrastructure";

export class CostsItemRepositoryImpl implements CostItemRepository {
  async getCostsItem(
    itemId: number
  ): Promise<Result<CostsItemModel> | Result<void>> {
    return await get<CostsItemModel>(`items/costs/${itemId}`);
  }

  async addCost(
    itemId: number,
    amount: number,
    description: string,
    divisions: UpdateCostDivision[],
    paidById: number
  ): Promise<Result<void>> {
    const request = { amount, description, divisions, paidById };

    return await post(`costs-items/${itemId}/costs/add`, request);
  }

  async getCost(
    costId: number
  ): Promise<Result<void> | Result<CostForUpdateViewModel>> {
    return await get<CostForUpdateViewModel>(`costs-items/costs/${costId}`);
  }

  async updateCost(
    costId: number,
    amount: number,
    description: string,
    divisions: UpdateCostDivision[],
    paidById: number
  ): Promise<Result<void>> {
    const request = { amount, description, divisions, paidById };

    return await put(`costs-items/costs/${costId}/update`, request);
  }

  async removeCost(costId: number): Promise<Result<void>> {
    return await remove(`costs-items/costs/${costId}/remove`);
  }

  async getRemarks(
    costId: number
  ): Promise<Result<void> | Result<RemarksModel>> {
    return await get<RemarksModel>(`costs-items/costs/${costId}/remarks`);
  }

  async addRemark(costId: number, text: string): Promise<Result<void>> {
    const request = { text };

    return await post(`costs-items/costs/${costId}/remarks/add`, request);
  }

  async removeRemark(remarkId: number): Promise<Result<void>> {
    return await remove(`costs-items/costs/remarks/${remarkId}/remove`);
  }

  async updateStatus(
    itemId: number,
    itemStatus: CostsItemStatus
  ): Promise<Result<void>> {
    const request = { itemStatus };

    return await put(`costs-items/${itemId}/update-status`, request);
  }

  async getCostAndCalculationSummary(
    itemId: number
  ): Promise<Result<CostAndCalculationSummaryModel> | Result<void>> {
    return await get<CostAndCalculationSummaryModel>(
      `costs-items/${itemId}/calculation-summary`
    );
  }
}
