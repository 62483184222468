import * as React from "react";

import { Chip, makeStyles } from "@material-ui/core";
import {
  CommonButton,
  ContainerLarge,
  HeaderNormal,
  HideOnMobile,
  LoadingScreen,
  LoadingSpinner,
  MobileOnly,
  Paragraph,
  RemarkScreen,
  SectionBox,
  TitleWithCloseButton,
  UserContext,
  useLocationItemUsecases,
} from "../../../../presentation";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import BackIcon from "@material-ui/icons/ArrowBack";
import { Result } from "../../../../application";

export interface LocationPropositionRemarksPageProps {}

interface RouteParams {
  topicId: string;
  locationItemId: string;
  propositionId: string;
}

export const LocationPropositionRemarksPage: React.FC<LocationPropositionRemarksPageProps> =
  () => {
    // STYLES
    const classes = useStyles();

    // ROUTER
    const routeParams = useParams<RouteParams>();
    const { push } = useHistory();
    const { url } = useRouteMatch();

    // CONTEXTS
    const { user } = React.useContext(UserContext);

    // APPLICATION
    const [{ remarksModel, loading }, { getRemarks, addRemark, removeRemark }] =
      useLocationItemUsecases();

    // VARIABLES
    const topicId = parseInt(routeParams.topicId);
    const locationItemId = parseInt(routeParams.locationItemId);
    const propositionId = parseInt(routeParams.propositionId);
    const backUrl = `/topics/${topicId}/location-items/${locationItemId}`;
    const subject = remarksModel?.subject;

    React.useEffect(() => {
      if (!propositionId) return;

      getRemarks(propositionId);
    }, []);

    // METHODS
    const handleNavigateBack = () => {
      push(backUrl);
    };

    const reload = async () => {
      getRemarks(propositionId);
    };

    const handleSend = async (message: string): Promise<Result> => {
      const result = await addRemark(propositionId, message);

      if (result.isSuccess) reload();

      return result;
    };

    const handleDelete = async (remarkId: number) => {
      const result = await removeRemark(remarkId);

      if (result.isSuccess) reload();
    };

    return (
      <ContainerLarge>
        <SectionBox>
          <MobileOnly>
            <TitleWithCloseButton
              title="Opmerkingen"
              onClose={handleNavigateBack}
            />
          </MobileOnly>

          <HideOnMobile>
            <CommonButton
              label="Terug"
              startIcon={<BackIcon />}
              variant="text"
              onClick={handleNavigateBack}
            />
            <HeaderNormal title="Opmerkingen" />
          </HideOnMobile>

          <Paragraph>
            Onderwerp:{" "}
            {loading && !remarksModel ? (
              <LoadingSpinner size={20} />
            ) : (
              <Chip
                color="primary"
                label={subject}
                className={classes.subject}
              />
            )}
          </Paragraph>
        </SectionBox>

        <div className={classes.remarkScreenWrapper}>
          <ContainerLarge className={classes.remarkScreenContainer}>
            {loading && !remarksModel && <LoadingScreen />}
            {remarksModel && (
              <RemarkScreen
                currentUserId={user?.id}
                remarks={remarksModel?.remarks}
                onSend={handleSend}
                onDelete={handleDelete}
              />
            )}
          </ContainerLarge>
        </div>
      </ContainerLarge>
    );
  };

const useStyles = makeStyles((theme) => ({
  subject: {
    [theme.breakpoints.only("xs")]: {
      maxWidth: 200,
    },
  },
  remarkScreenWrapper: {
    position: "absolute",
    top: 150,
    right: 0,
    bottom: 0,
    left: 0,
    [theme.breakpoints.only("xs")]: {
      top: 110,
    },
  },
  remarkScreenContainer: {
    height: "100%",
    [theme.breakpoints.only("xs")]: {
      paddingBottom: "0 !important",
    },
  },
}));

export default LocationPropositionRemarksPage;
