import * as React from "react";

import { Theme, makeStyles, useMediaQuery } from "@material-ui/core";

import { ItemImageContainer } from "../../../../../presentation";
import { ItemType } from "../../../../../application";

export interface ItemStatusBarProps {
  itemType: ItemType;
  done?: boolean;
  summaryPrimary?: string;
  summarySecondary?: {
    leftBlock?: string;
    rightBlock?: string;
  };
}

export const ItemStatusBar: React.FC<ItemStatusBarProps> = ({
  itemType,
  done = false,
  summaryPrimary = null,
  summarySecondary = null,
}) => {
  // STYLES
  const classes = useStyles({ summaryPrimary });
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  return (
    <div className={`${classes.statusBar} ${done ? "done" : ""}`}>
      <div className={classes.imageColumn}>
        <div>
          <ItemImageContainer
            itemType={itemType}
            size={isSmallScreen ? 50 : 65}
            className={classes.imageContainer}
          />
        </div>
      </div>
      <div className={classes.infoColumn}>
        {/* <div>
          <HeaderNormal
            title={done ? "Gepiept!" : ""}
            style={{ color: "#fff" }}
            disableGutter
          />
        </div>
        <div className={classes.infoColumnSummary}>
          <div className={classes.infoColumnSummaryPrimary}>
            <Paragraph style={{ color: "#fff" }}>{summaryPrimary}</Paragraph>
          </div>
          <>
            {summarySecondary?.leftBlock && summarySecondary.rightBlock && (
              <div className={classes.infoColumnSummarySecondary}>
                <div
                  className={`${classes.infoColumnSummarySecondaryBlock} ${classes.infoColumnSummarySecondaryBlockLeft}`}
                >
                  <Text style={{ color: "#fff" }}>
                    {summarySecondary.leftBlock}
                  </Text>
                </div>
                <div
                  className={`${classes.infoColumnSummarySecondaryBlock} ${classes.infoColumnSummarySecondaryBlockRight}`}
                >
                  <Text style={{ color: "#fff" }}>
                    {summarySecondary.rightBlock}
                  </Text>
                </div>
              </div>
            )}
          </>
        </div> */}
      </div>
      <div className={classes.iconColumn}>
        {/* <div className={classes.iconContainer}>{done && <DoneIcon />}</div> */}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  statusBar: {
    position: "relative",
    backgroundColor: theme.palette.warning.main,
    borderRadius: 10,
    display: "flex",
    transition: "background-color 600ms",
    "&.done": {
      backgroundColor: theme.palette.success.main,
    },
    [theme.breakpoints.only("xs")]: {
      marginLeft: -16,
      marginRight: -16,
      paddingBottom: 30,
      borderRadius: 0,
    },
  },
  imageColumn: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translate(-50%, 50%)",
    },
  },
  imageContainer: {
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
  },
  infoColumn: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  infoColumnSummary: {
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  infoColumnSummaryPrimary: {
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },
  infoColumnSummarySecondary: {
    display: "flex",
    minHeight: 20,
    justifyContent: "space-between",
  },
  infoColumnSummarySecondaryBlock: {
    [theme.breakpoints.only("xs")]: {
      width: "50%",
      textAlign: "center",
    },
  },
  infoColumnSummarySecondaryBlockLeft: {
    [theme.breakpoints.only("xs")]: {
      paddingRight: 40,
    },
  },
  infoColumnSummarySecondaryBlockRight: {
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 40,
    },
  },
  iconColumn: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      position: "absolute",
      top: 0,
      right: 0,
    },
  },
  iconContainer: {
    padding: 8,
    color: "#fff",
  },
}));

export default ItemStatusBar;
