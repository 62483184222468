import {
  INJECTION_TOKENS,
  type AuthRepository,
  AuthenticationService,
  Result,
  type TokenService,
  UserModel,
} from "../../../application";
import { inject, injectable } from "tsyringe";

@injectable()
export class AuthenticationServiceImpl implements AuthenticationService {
  constructor(
    @inject(INJECTION_TOKENS.AuthRepository)
    private authRepository: AuthRepository,
    @inject(INJECTION_TOKENS.TokenService)
    private tokenService: TokenService
  ) {}

  async requestUserKey(email: string): Promise<Result> {
    return await this.authRepository.requestUserKey(email);
  }

  async login(userKey: string): Promise<Result> {
    const result = await this.authRepository.redeemUserKey(userKey);
    if (result.isFailed) return Result.toGenericResult(result);

    const token = result.value;
    if (token == null) return Result.Error();

    this.tokenService.saveToken(token);

    return Result.Ok();
  }

  logout(): void {
    this.tokenService.clearToken();
  }

  getCurrentUser(): UserModel | null {
    const payload = this.tokenService.getTokenPayload();
    if (payload == null) return null;

    const id = parseInt(payload.sub);
    const email = payload.email;

    const user: UserModel = { id, email };

    return user;
  }
}
