import { AuthRepository, Result } from "../../../application";

import { RedeemUserKeyRequest } from "./redeem-user-key-request.model";
import { post } from "../../../infrastructure";

export class AuthRepositoryImpl implements AuthRepository {
  async requestUserKey(email: string): Promise<Result<void>> {
    const request = { email };

    return await post(`auth/request-user-key`, request);
  }

  async redeemUserKey(userKey: string): Promise<Result<string> | Result> {
    const requestModel = new RedeemUserKeyRequest(userKey);

    return await post("auth/redeem-user-key", requestModel);
  }
}
