import * as React from "react";

import {
  CommonButton,
  ContactImage,
  ContainerLarge,
  FeedbackImage,
  HeaderExtraSmall,
  HeaderLarge,
  HeaderMedium,
  HeaderNormal,
  SectionBlock,
  SectionBox,
} from "../../../presentation";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useHistory } from "react-router-dom";

export interface ContactPageProps {}

export const ContactPage: React.FC<ContactPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // HOOKS
  const { push, goBack } = useHistory();

  // METHODS
  const handleNavigation = () => {
    window.open("https://forms.gle/c4Y4XdVVphHACVjCA", "_blank");
  };

  return (
    <ContainerLarge>
      <SectionBox>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton size="medium" onClick={() => goBack()}>
            <ArrowBackIcon />
          </IconButton>
          <HeaderLarge title="Feedback en contact" style={{ margin: 0 }} />
        </div>
      </SectionBox>

      <SectionBlock>
        <SectionBox>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={6} className={classes.mobileSecondOrder}>
              <SectionBox>
                <div style={{ maxWidth: 400, height: 350, margin: "auto" }}>
                  <img src={FeedbackImage} className={classes.image} />
                </div>
              </SectionBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ maxWidth: 450, margin: "auto" }}>
                <SectionBox>
                  <HeaderNormal color="primary" title="Feedback" />
                </SectionBox>

                <SectionBox>
                  <HeaderExtraSmall title="We kijken uit naar jouw feedback, want samen tillen we Plokkie naar een nog hoger niveau!" />
                </SectionBox>

                <SectionBox>
                  <HeaderExtraSmall
                    title={
                      <>
                        <span>
                          Heb je suggesties voor verbetering, sprankelende
                          nieuwe ideeën, of ben je gewoon ontzettend
                          enthousiast? Deel het met ons via{" "}
                          <a href="mailto:info@plokkie.nl">e-mail</a> of vul ons
                          online feedbackformulier in. Jouw input maakt het
                          verschil!
                        </span>
                      </>
                    }
                  />
                </SectionBox>

                {/* <SectionBox>
                  <HeaderExtraSmall
                    title={
                      <span>
                        Heb je verbeterpunten, nieuwe ideeën of ben je juist
                        heel tevreden? Laat het ons weten door een{" "}
                        <a href="mailto:info@plokkie.nl">mail</a> te sturen of
                        door ons online feedback formulier in te vullen.
                      </span>
                    }
                  />
                </SectionBox> */}

                <SectionBox>
                  <CommonButton
                    color="primary"
                    label="Feedback formulier"
                    size="large"
                    variant="contained"
                    onClick={handleNavigation}
                  />
                </SectionBox>
              </div>
            </Grid>
          </Grid>
        </SectionBox>
      </SectionBlock>

      {/* <SectionBlock>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={6}>
            <div style={{ maxWidth: 400, margin: "auto" }}>
              <SectionBox>
                <HeaderNormal color="primary" title="Contact" />
              </SectionBox>

              <SectionBox>
                <HeaderExtraSmall
                  title={
                    <span>
                      Heb je vragen of opmerkingen? Stuur dan een mail naar{" "}
                      <a href="mailto:info@plokkie.nl">info@plokkie.nl</a>.
                    </span>
                  }
                />
              </SectionBox>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SectionBox>
              <div style={{ maxWidth: 400, height: 450, margin: "auto" }}>
                <img src={ContactImage} className={classes.image} />
              </div>
            </SectionBox>
          </Grid>
        </Grid>
      </SectionBlock> */}
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  sectionContent: {
    position: "relative",
    padding: "5rem 0",
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.4)",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  mobileSecondOrder: {
    [theme.breakpoints.only("xs")]: {
      order: 2,
    },
  },
}));

export default ContactPage;
