import * as React from "react";

import {
  GenericVoteType,
  QuestionModel,
  Result,
} from "../../../../../application";
import { Grid, makeStyles } from "@material-ui/core";

import { Question } from "../../../../../presentation";

export interface QuestionListProps {
  questions: QuestionModel[];
  totalUsers: number;
  onOpenRemarks(questionId: number): void;
  onOpenAnswerRemarks(answerId: number): void;
  onRemove(questionId: number): Promise<Result>;
  onEdit(questionId: number): void;
  onRemoveAnswer(answerId: number): Promise<Result>;
  onLike(answerId: number): Promise<Result>;
  onDislike(answerId: number): Promise<Result>;
  onVote(answerId: number, voteType: GenericVoteType): Promise<Result>;
  onChoose(answerId: number): Promise<Result>;
  onUnChoose(answerId: number): Promise<Result>;
  onSend(questionId: number, description: string): Promise<Result>;
}

export const QuestionList: React.FC<QuestionListProps> = ({
  questions,
  totalUsers,
  onOpenRemarks,
  onOpenAnswerRemarks,
  onRemove,
  onEdit,
  onRemoveAnswer,
  onLike,
  onDislike,
  onVote,
  onChoose,
  onUnChoose,
  onSend,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        {questions.map((question) => (
          <Grid
            key={question.id}
            item
            xs={12}
            sm="auto"
            classes={{
              root: classes.gridItem,
            }}
          >
            <Question
              question={question}
              totalUsers={totalUsers}
              onOpenRemarks={onOpenRemarks}
              onOpenAnswerRemarks={onOpenAnswerRemarks}
              onRemove={onRemove}
              onEdit={onEdit}
              onRemoveAnswer={onRemoveAnswer}
              onLike={onLike}
              onDislike={onDislike}
              onVote={onVote}
              onChoose={onChoose}
              onUnChoose={onUnChoose}
              onSend={onSend}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  gridItem: {
    marginRight: 30,
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
}));

export default QuestionList;
