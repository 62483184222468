import * as React from "react";

import { Backdrop, makeStyles } from "@material-ui/core";

export interface OverlayProps {
  show: boolean;
  className?: string;
}

export const Overlay: React.FC<OverlayProps> = ({ show, className }) => {
  const classes = useStyles();

  return (
    <Backdrop
      open={show}
      className={`${classes.backdrop} ${className ? className : ""}`}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: "absolute",
    zIndex: 9,
  },
}));

export default Overlay;
