import * as React from "react";

// MATERIAL-UI HOOKS
import { useMediaQuery } from "@material-ui/core";

interface DeviceInfo {
  isExtraSmallDevice: boolean;
  isSmallDevice: boolean;
  isMediumDevice: boolean;
}

export const useDeviceInfo = (): [deviceInfo: DeviceInfo] => {
  const isExtraSmallDevice = useMediaQuery("(max-width:600px)");
  const isSmallDevice = useMediaQuery("(max-width:768px)");
  const isMediumDevice = useMediaQuery("(max-width:960px)");

  return [{ isExtraSmallDevice, isSmallDevice, isMediumDevice }];
};
