import * as React from "react";

import { Grid, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { HeaderExtraSmall, ItemTypeImage } from "../../../..";

import { ItemType } from "../../../../../application";

interface BlocksInfoProps {}

export const BlocksInfo: React.FC<BlocksInfoProps> = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <ListItem>
          <ListItemIcon>
            <ItemTypeImage itemType={ItemType.Date} style={{ maxWidth: 40 }} />
          </ListItemIcon>
          <ListItemText
            primary={<HeaderExtraSmall title="Datum blokkie" />}
            secondary="Hiermee kan je verschillende datum voorstellen doen zodat de groep mee kan stemmen welke datum het beste uitkomt voor de activiteit."
          />
        </ListItem>
      </Grid>
      <Grid item xs={12} md={6}>
        <ListItem>
          <ListItemIcon>
            <ItemTypeImage
              itemType={ItemType.Location}
              style={{ maxWidth: 40 }}
            />
          </ListItemIcon>
          <ListItemText
            primary={<HeaderExtraSmall title="Locatie blokkie" />}
            secondary="Hier kan je meerdere locatie voorstellen toevoegen waar de groep op kan stemmen. Iedereen is vrij om een eigen locatie voor te stellen. Het zoeken naar vakantiehuisjes wordt dus nog makkelijker!"
          />
        </ListItem>
      </Grid>
      <Grid item xs={12} md={6}>
        <ListItem>
          <ListItemIcon>
            <ItemTypeImage itemType={ItemType.Tasks} style={{ maxWidth: 40 }} />
          </ListItemIcon>
          <ListItemText
            primary={<HeaderExtraSmall title="Taken blokkie" />}
            secondary="Om een groepsactiviteit te regelen zullen meerdere mensen ook meerdere taken moeten uitvoeren. Voer alle taken op die nog uitgevoerd moeten worden en maak iemand verantwoordelijk voor het uitvoeren ervan. Als de taken klaar zijn dan kan je deze afvinken."
          />
        </ListItem>
      </Grid>
      <Grid item xs={12} md={6}>
        <ListItem>
          <ListItemIcon>
            <ItemTypeImage itemType={ItemType.Costs} style={{ maxWidth: 40 }} />
          </ListItemIcon>
          <ListItemText
            primary={<HeaderExtraSmall title="Kosten blokkie" />}
            secondary="Bij het regelen maar ook tijdens het uitvoeren van de activiteit zullen er kosten gemaakt worden. Verzamel deze in het kosten blokje. Deze rekent precies uit hoeveel iedereen aan elkaar moet betalen."
          />
        </ListItem>
      </Grid>
      <Grid item xs={12} md={6}>
        <ListItem>
          <ListItemIcon>
            <ItemTypeImage
              itemType={ItemType.Questions}
              style={{ maxWidth: 40 }}
            />
          </ListItemIcon>
          <ListItemText
            primary={<HeaderExtraSmall title="Vragen blokkie" />}
            secondary="Gedurende het regelen van de activiteit ontstaan er altijd vragen. In dit blokje kunnen leden vragen stellen en antwoorden geven. Het is mogelijk om een eigen antwoord te geven op een vraag of mee te stemmen op een bestaand antwoord. Hiermee zie je dus snel hoe de groep over iets denkt."
          />
        </ListItem>
      </Grid>
    </Grid>
  );
};

export default BlocksInfo;
