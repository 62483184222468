import * as React from "react";

import {
  ButtonBar,
  CommonButton,
  ContainerLarge,
  GenericTextField,
  HeaderNormal,
  LoadingScreen,
  ReasonList,
  SectionBox,
  useLocationItemUsecases,
} from "../../../../presentation";
import { useHistory, useParams } from "react-router-dom";

import { LocationPropositionModel } from "../../../../application";
import { makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";

export interface UpdateLocationPropositionPageProps {}

interface RouteParams {
  topicId: string;
  locationItemId: string;
  propositionId: string;
}

export const UpdateLocationPropositionPage: React.FC<UpdateLocationPropositionPageProps> =
  () => {
    // STYLES
    const classes = useStyles();

    // ROUTER
    const params = useParams<RouteParams>();
    const { push } = useHistory();

    // USECASES
    const [
      { locationItemModel, loading, reasons },
      { getLocationItem, updateLocationProposition },
    ] = useLocationItemUsecases();

    // STATE
    const [propositionModel, setPropositionModel] =
      React.useState<LocationPropositionModel | null>(null);

    // VARIABLES
    const topicId = parseInt(params.topicId);
    const locationItemId = parseInt(params.locationItemId);
    const propositionId = parseInt(params.propositionId);
    const backUrl = `/topics/${topicId}/location-items/${locationItemId}`;
    const { register, handleSubmit, errors } = useForm();

    React.useEffect(() => {
      getLocationProposition();
    }, []);

    // METHODS
    const handleNavigateBack = () => {
      push(backUrl);
    };

    const getLocationProposition = async () => {
      if (!locationItemId) return;

      const result = await getLocationItem(locationItemId);

      if (result.isSuccess && result.value) {
        const proposition = result.value.propositions.find(
          (p) => p.id === propositionId
        );

        if (proposition) setPropositionModel(proposition);
      }
    };

    const handleActualSubmit = async (fieldValues: any) => {
      const result = await updateLocationProposition(
        propositionId,
        fieldValues.name,
        fieldValues.locationLink,
        fieldValues.streetName,
        fieldValues.zipCode,
        fieldValues.location
      );

      if (result.isSuccess) handleNavigateBack();
    };

    return (
      <ContainerLarge>
        <SectionBox>
          <HeaderNormal title="Locatie aanpassen" />
        </SectionBox>

        <SectionBox>
          {loading && !propositionModel && <LoadingScreen />}
          {propositionModel && (
            <div className={classes.formWrapper}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(handleActualSubmit)}
              >
                <div>
                  <GenericTextField
                    displayLabel="Naam *"
                    fieldName="name"
                    inputRef={register({ required: true, maxLength: 240 })}
                    defaultValue={propositionModel.name}
                  />
                </div>

                <div>
                  <GenericTextField
                    displayLabel="Straatnaam + huisnummer"
                    fieldName="streetName"
                    inputRef={register()}
                    defaultValue={propositionModel.streetName}
                  />
                </div>

                <div>
                  <GenericTextField
                    displayLabel="Plaats"
                    fieldName="location"
                    inputRef={register()}
                    defaultValue={propositionModel.location}
                  />
                </div>

                <div>
                  <GenericTextField
                    displayLabel="Postcode"
                    fieldName="zipCode"
                    inputRef={register()}
                    defaultValue={propositionModel.zipCode}
                  />
                </div>

                <div>
                  <GenericTextField
                    displayLabel="Hyperlink"
                    fieldName="locationLink"
                    type="url"
                    inputRef={register({ required: false, maxLength: 2048 })}
                    defaultValue={propositionModel.locationLink}
                  />
                </div>

                <SectionBox>
                  <ButtonBar>
                    <CommonButton
                      label="Annuleren"
                      onClick={handleNavigateBack}
                    />
                    <CommonButton
                      color="primary"
                      label="Opslaan"
                      loading={loading}
                      type="submit"
                      variant="contained"
                    />
                  </ButtonBar>
                </SectionBox>
              </form>
              {reasons && (
                <SectionBox>
                  <ReasonList reasons={reasons} />
                </SectionBox>
              )}
            </div>
          )}
        </SectionBox>
      </ContainerLarge>
    );
  };

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    maxWidth: 600,
  },
}));

export default UpdateLocationPropositionPage;
