import { Result } from "../../application";

export const splitTime = (time: string): Result<number[] | void> => {
  const splittedTime = time.split(":");
  if (splittedTime.length !== 2) return Result.Fail("Invalide tijd");

  const splittedTimeAsNumbers = splittedTime.map((t) => parseInt(t));

  return Result.WithValue(splittedTimeAsNumbers);
};
