import { injectable, inject } from "tsyringe";
import {
  INJECTION_TOKENS,
  AnswerVoteType,
  type QuestionsItemRepository,
  QuestionsItemModel,
  Result,
  RemarksModel,
  GenericVoteType,
  QuestionsItemService,
} from "../../../application";

@injectable()
export class QuestionsItemServiceImpl implements QuestionsItemService {
  constructor(
    @inject(INJECTION_TOKENS.QuestionsItemRepository)
    private _repo: QuestionsItemRepository
  ) {}

  async getQuestionsItem(
    itemId: number
  ): Promise<Result<QuestionsItemModel> | Result<void>> {
    return await this._repo.getQuestionsItem(itemId);
  }

  async addQuestion(
    itemId: number,
    description: string
  ): Promise<Result<void>> {
    return await this._repo.addQuestion(itemId, description);
  }

  async updateQuestion(
    questionId: number,
    description: string
  ): Promise<Result<void>> {
    return await this._repo.updateQuestion(questionId, description);
  }

  async removeQuestion(questionId: number): Promise<Result<void>> {
    return await this._repo.removeQuestion(questionId);
  }

  async getQuestionRemarks(
    questionId: number
  ): Promise<Result<RemarksModel> | Result<void>> {
    return await this._repo.getQuestionRemarks(questionId);
  }

  async addQuestionRemark(
    questionId: number,
    text: string
  ): Promise<Result<void>> {
    return await this._repo.addQuestionRemark(questionId, text);
  }

  async removeQuestionRemark(remarkId: number): Promise<Result<void>> {
    return await this._repo.removeQuestionRemark(remarkId);
  }

  async addAnswer(
    questionId: number,
    description: string
  ): Promise<Result<void>> {
    return await this._repo.addAnswer(questionId, description);
  }

  async updateAnswer(
    answerId: number,
    description: string
  ): Promise<Result<void>> {
    return await this._repo.updateAnswer(answerId, description);
  }

  async removeAnswer(answerId: number): Promise<Result<void>> {
    return await this._repo.removeAnswer(answerId);
  }

  async voteOnAnswer(
    answerId: number,
    voteType: GenericVoteType
  ): Promise<Result<void>> {
    return await this._repo.voteOnAnswer(answerId, voteType);
  }

  async chooseAnswer(answerId: number): Promise<Result<void>> {
    return await this._repo.chooseAnswer(answerId);
  }

  async unChooseAnswer(answerId: number): Promise<Result<void>> {
    return await this._repo.unChooseAnswer(answerId);
  }

  async getAnswerRemarks(
    answerId: number
  ): Promise<Result<RemarksModel> | Result<void>> {
    return await this._repo.getAnswerRemarks(answerId);
  }

  async addAnswerRemark(answerId: number, text: string): Promise<Result<void>> {
    return await this._repo.addAnswerRemark(answerId, text);
  }

  async removeAnswerRemark(remarkId: number): Promise<Result<void>> {
    return await this._repo.removeAnswerRemark(remarkId);
  }
}
