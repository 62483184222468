import {
  Aligner,
  CommonButton,
  HeaderSmall,
  SectionBox,
  Text,
  UserContext,
} from "../../presentation";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

import AboutUsIcon from "@material-ui/icons/BusinessCenter";
import ContactIcon from "@material-ui/icons/Feedback";
import HomeIcon from "@material-ui/icons/Home";
import HowToIcon from "@material-ui/icons/Help";
import MyTopicsIcon from "@material-ui/icons/CollectionsBookmark";
import NewTopicIcon from "@material-ui/icons/AddCircle";
import React from "react";
import RoadmapIcon from "@material-ui/icons/AccountTree";
import { useHistory } from "react-router-dom";

export interface SideMenuProps {
  open: boolean;
  variant: "temporary" | "permanent" | "persistent" | undefined;
  onClose(): void;
  onNavigate(url: string): void;
}

export const SideMenu: React.FC<SideMenuProps> = ({
  open,
  variant,
  onClose,
  onNavigate,
}) => {
  // STYLES
  const classes = useStyles();

  // ROUTER
  const { push } = useHistory();

  // CONTEXT
  const { user } = React.useContext(UserContext);

  // VARIABLES

  // METHODS

  return (
    <>
      <Drawer
        variant={variant}
        anchor="left"
        open={open}
        onClose={onClose}
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <Aligner horizontal>
          <HeaderSmall
            title={
              <>
                <span>Plokkie </span>
                <Text>(v1.11.0)</Text>
              </>
            }
          />
          <SectionBox>
            <CommonButton
              label="Nieuwe Plokkie"
              startIcon={<NewTopicIcon />}
              onClick={() => onNavigate("/topics/create")}
            />
          </SectionBox>
          <List classes={{ root: classes.list }}>
            {user && (
              <ListItem
                button
                onClick={() => onNavigate("/topics/my")}
                classes={{ root: classes.listItem }}
              >
                <ListItemIcon>
                  <MyTopicsIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Mijn Plokkies"
                  classes={{ root: classes.listItemText }}
                  disableTypography
                />
              </ListItem>
            )}

            <ListItem
              button
              onClick={() => onNavigate("/")}
              classes={{ root: classes.listItem }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Home"
                classes={{ root: classes.listItemText }}
                disableTypography
              />
            </ListItem>

            <ListItem
              button
              onClick={() => onNavigate("/how-to")}
              classes={{ root: classes.listItem }}
            >
              <ListItemIcon>
                <HowToIcon />
              </ListItemIcon>
              <ListItemText
                primary="Hoe werkt het"
                classes={{ root: classes.listItemText }}
                disableTypography
              />
            </ListItem>

            <ListItem
              button
              onClick={() => onNavigate("/about-us")}
              classes={{ root: classes.listItem }}
            >
              <ListItemIcon>
                <AboutUsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Over ons"
                classes={{ root: classes.listItemText }}
                disableTypography
              />
            </ListItem>

            <ListItem
              button
              onClick={() => onNavigate("/contact")}
              classes={{ root: classes.listItem }}
            >
              <ListItemIcon>
                <ContactIcon />
              </ListItemIcon>
              <ListItemText
                primary="Feedback en contact"
                classes={{ root: classes.listItemText }}
                disableTypography
              />
            </ListItem>

            <ListItem
              button
              onClick={() => onNavigate("/roadmap")}
              classes={{ root: classes.listItem }}
            >
              <ListItemIcon>
                <RoadmapIcon />
              </ListItemIcon>
              <ListItemText
                primary="Roadmap"
                classes={{ root: classes.listItemText }}
                disableTypography
              />
            </ListItem>
          </List>
        </Aligner>
      </Drawer>
    </>
  );
};

const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    zIndex: 1300,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: 8,
    backgroundColor: "#E8E8E8",
    color: "#fff",
    borderRight: 0,
  },
  list: {
    paddingLeft: 25,
  },
  listItem: {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    "&:hover": {
      backgroundColor: "#F8F8F8",
      "& .MuiListItemText-root": { color: theme.palette.primary.main },
      "& .MuiSvgIcon-root": { color: theme.palette.primary.main },
    },
  },
  listItemText: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
}));

export default SideMenu;
