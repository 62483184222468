import * as React from "react";

import Typography from "@material-ui/core/Typography";

export interface HeaderSmallProps {
  title: React.ReactNode;
  bold?: boolean;
  color?: "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
  style?: React.CSSProperties;
}

export const HeaderSmall: React.FC<HeaderSmallProps> = ({
  title,
  bold,
  color = "textPrimary",
  style,
}) => {
  const styles: React.CSSProperties = {};

  if (bold) styles.fontWeight = 500;
  if (style) Object.assign(styles, style);

  return (
    <Typography color={color} style={styles} variant="h5" gutterBottom>
      {title}
    </Typography>
  );
};

export default HeaderSmall;
