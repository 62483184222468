import * as React from "react";

import { INJECTION_TOKENS, LocationItemService } from "../../../application";

import { useDependencyInjectionService } from "../../../presentation";

export const useLocationItemService = (): [LocationItemService] => {
  const [containerSvc] = useDependencyInjectionService();
  const locationItemSvc = containerSvc.getService<LocationItemService>(
    INJECTION_TOKENS.LocationItemService
  );

  return [locationItemSvc];
};
