import { CreateTopicModel, Result } from "../../../../../application";
import React, { useState } from "react";

import { useTopicService } from "../../../../../presentation";

export interface CreateTopicContainerProps {
  children(api: Api): React.ReactNode;
}

interface Api {
  loading: boolean;
  reasons: string[] | null;
  createTopic(model: CreateTopicModel): Promise<Result<number> | Result>;
}

export const CreateTopicContainer: React.FC<CreateTopicContainerProps> = ({
  children,
}) => {
  const [topicSvc] = useTopicService();
  const [loading, setLoading] = useState(false);
  const [reasons, setReasons] = useState<string[] | null>(null);

  const createTopic = async (
    model: CreateTopicModel
  ): Promise<Result<number> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await topicSvc.createTopic(model);
    setLoading(false);

    if (result.isFailed) {
      setReasons(result.reasons);
    }

    return result;
  };

  const api: Api = {
    loading,
    reasons,
    createTopic,
  };

  return <div>{children(api)}</div>;
};

export default CreateTopicContainer;
