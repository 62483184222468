import {
  AddDatePropositionsRequest,
  AddRemarkRequest,
  ChangeTimeFromDatePropositionRequest,
  DateItemRepository,
  DatePropositionRemarksModel,
  DatePropositionTimesModel,
  RemarkModel,
  Result,
  VoteOnDatePropositionRequest,
} from "../../../application";
import { get, post, put, remove } from "../../../infrastructure";

export class DateItemRepositoryImpl implements DateItemRepository {
  async addDatePropositions(
    dateItemId: number,
    request: AddDatePropositionsRequest
  ): Promise<Result<void>> {
    return await post(
      `date-items/${dateItemId}/date-propositions/add`,
      request
    );
  }

  async removeDateProposition(
    datePropositionId: number
  ): Promise<Result<void>> {
    return await remove(
      `date-items/date-propositions/${datePropositionId}/remove`
    );
  }

  async voteOnDateProposition(
    datePropositionId: number,
    request: VoteOnDatePropositionRequest
  ): Promise<Result<void>> {
    return await post(
      `date-items/date-propositions/${datePropositionId}/vote`,
      request
    );
  }

  async getTimesFromDateProposition(
    datePropositionId: number
  ): Promise<Result<DatePropositionTimesModel> | Result<void>> {
    return await get<DatePropositionTimesModel>(
      `date-items/date-propositions/${datePropositionId}/time`
    );
  }

  async changeTimeFromDateProposition(
    datePropositionId: number,
    request: ChangeTimeFromDatePropositionRequest
  ): Promise<Result<void>> {
    return await put(
      `date-items/date-propositions/${datePropositionId}/change-time`,
      request
    );
  }

  async pinDate(datePropositionId: number): Promise<Result<void>> {
    return await put(`date-items/date-propositions/${datePropositionId}/pin`);
  }

  async getRemarks(
    datePropositionId: number
  ): Promise<Result<DatePropositionRemarksModel> | Result<void>> {
    return await get<DatePropositionRemarksModel>(
      `date-items/date-propositions/${datePropositionId}/remarks`
    );
  }
  async addRemark(
    datePropositionId: number,
    request: AddRemarkRequest
  ): Promise<Result<void>> {
    return await post(
      `date-items/date-propositions/${datePropositionId}/remarks/add`,
      request
    );
  }

  async removeRemark(remarkId: number): Promise<Result> {
    return await remove(
      `date-items/date-propositions/remarks/${remarkId}/remove`
    );
  }
}
