import * as React from "react";

import { SvgIcon } from "@material-ui/core";

export interface SmileLikeIconProps {
  fontSize?: "small" | "inherit" | "large" | "default";
}

export const SmileLikeIcon: React.FC<SmileLikeIconProps> = ({
  fontSize = "default",
}) => {
  return (
    <SvgIcon fontSize={fontSize} viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M15.2746538,14.2978292 L16.9105622,15.4483958 C15.7945475,17.0351773 13.9775544,18 12,18 C10.0224456,18 8.20545254,17.0351773 7.08943782,15.4483958 L8.72534624,14.2978292 C9.4707028,15.3575983 10.6804996,16 12,16 C13.3195004,16 14.5292972,15.3575983 15.2746538,14.2978292 Z M14,11 L14,9 L16,9 L16,11 L14,11 Z M8,11 L8,9 L10,9 L10,11 L8,11 Z"
      />
      {/* <g>
        <g>
          <g>
            <path
              d="M256,0C114.844,0,0,114.844,0,256s114.844,256,256,256s256-114.844,256-256S397.156,0,256,0z M256,490.667
				C126.604,490.667,21.333,385.396,21.333,256S126.604,21.333,256,21.333S490.667,126.604,490.667,256S385.396,490.667,256,490.667
				z"
            />
            <path
              d="M393.385,299.115c-5.781-1.385-11.49,2.177-12.844,7.906c-13.75,57.885-64.958,98.313-124.542,98.313
				c-59.656,0-110.885-40.49-124.573-98.448c-1.365-5.75-7.146-9.24-12.833-7.938c-5.74,1.354-9.292,7.104-7.938,12.833
				c15.979,67.646,75.75,114.885,145.344,114.885c69.51,0,129.26-47.167,145.292-114.708
				C402.656,306.219,399.115,300.469,393.385,299.115z"
            />
            <circle cx="170.667" cy="192" r="21.333" />
            <circle cx="341.333" cy="192" r="21.333" />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g> */}
    </SvgIcon>
  );
};

export default SmileLikeIcon;
