import * as React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import { Cost } from "../../../../../presentation";
import { CostModel } from "../../../../../application";

export interface CostListProps {
  costs: CostModel[];
  blocked: boolean;
  onOpenRemarks(costId: number): void;
  onEdit(costId: number): void;
  onRemove(costId: number): Promise<void>;
}

export const CostList: React.FC<CostListProps> = ({
  costs,
  blocked,
  onOpenRemarks,
  onEdit,
  onRemove,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        {costs.map((cost) => (
          <Grid
            key={cost.id}
            item
            xs={12}
            sm="auto"
            classes={{
              root: classes.gridItem,
            }}
          >
            <Cost
              cost={cost}
              blocked={blocked}
              onOpenRemarks={onOpenRemarks}
              onEdit={onEdit}
              onRemove={onRemove}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  gridItem: {
    marginRight: 30,
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
}));

export default CostList;
