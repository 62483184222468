import * as React from "react";

import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";

import SelectedIcon from "@material-ui/icons/Check";
import { useMenu } from "../../../../presentation";

export interface CommonMenuProps {
  children(api: Api): ChildrenData;
}

interface Api {
  open(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

interface ChildrenData {
  button: React.ReactNode;
  items: Item[];
}

interface Item {
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  handleClick?(): void;
}

export const CommonMenu: React.FC<CommonMenuProps> = ({ children }) => {
  const [{ isOpen, anchor }, { open, close }] = useMenu();

  const menuContent = children({ open });

  // VARIABLES
  const isSelected = menuContent.items.some((i) => i.selected === true);

  // METHODS
  const handleClickWrapper = (handleClick: () => void) => {
    close();
    handleClick();
  };

  return (
    <>
      {menuContent.button}
      <Menu
        open={isOpen}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        onClose={close}
      >
        {menuContent.items.map(
          ({ label, icon, disabled, selected, handleClick = () => {} }) => (
            <MenuItem
              disabled={disabled}
              onClick={() => handleClickWrapper(handleClick)}
            >
              {icon && !isSelected && <ListItemIcon>{icon}</ListItemIcon>}
              {isSelected && (
                <ListItemIcon>
                  {selected && <SelectedIcon color="primary" />}
                </ListItemIcon>
              )}
              <ListItemText primary={label} />
            </MenuItem>
          )
        )}
      </Menu>
    </>
  );
};

export default CommonMenu;
