import * as React from "react";

import { CommonButton, MobileOnly } from "../../../../presentation";

import { makeStyles } from "@material-ui/core";

export interface MobileNavigationBarProps {
  children(): NavigationContent;
}

interface NavigationContent {
  buttons: ButtonItem[];
}

export interface ButtonItem {
  icon: React.ReactNode;
  disabled?: boolean;
  onClick(): void;
}

export const MobileNavigationBar: React.FC<MobileNavigationBarProps> = ({
  children,
}) => {
  // STYLES
  const classes = useStyles();

  // VARIABLES
  const navigationContent = children();
  const flexBasisPercentage = 100 / navigationContent.buttons.length;

  return (
    <MobileOnly>
      <div className={classes.bar}>
        <div style={{ display: "flex" }}>
          {navigationContent.buttons.map(({ icon, disabled, onClick }) => (
            <div style={{ flexBasis: `${flexBasisPercentage}%` }}>
              <CommonButton
                disabled={disabled}
                fullWidth
                icon={icon}
                iconColor="secondary"
                size="large"
                variant="text"
                className={classes.button}
                style={{ padding: "14px 11px" }}
                onClick={onClick}
              />
            </div>
          ))}
        </div>
      </div>
    </MobileOnly>
  );
};

const useStyles = makeStyles((theme) => ({
  bar: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#fff",
    boxShadow: "0px 3px 8px #000",
    zIndex: 10,
  },
  button: {
    "&.Mui-disabled .MuiSvgIcon-root": { color: theme.palette.action.disabled },
  },
}));

export default MobileNavigationBar;
