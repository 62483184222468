import * as React from "react";

import { CommonIconButton } from "../../../../../presentation";
import ThumbsDownIcon from "@material-ui/icons/ThumbDownAltOutlined";
import ThumbsDownSelectedIcon from "@material-ui/icons/ThumbDownAlt";
import { makeStyles } from "@material-ui/core";

export interface DislikeButtonProps {
  selected: boolean;
  totalDislikes: number;
  loading: boolean;
  disabled: boolean;
  onClick(): void;
}

export const DislikeButton: React.FC<DislikeButtonProps> = ({
  selected,
  totalDislikes,
  loading,
  disabled,
  onClick,
}) => {
  // STYLES
  const classes = useStyles();

  // METHODS
  const onClickWrapper = () => {
    if (!selected) {
      onClick();
    }
  };

  return (
    <CommonIconButton
      badgeContent={totalDislikes}
      badgeClass={classes.badge}
      className={classes.button}
      loading={loading}
      disabled={disabled}
      icon={
        selected ? (
          <ThumbsDownSelectedIcon className={classes.iconSelected} />
        ) : (
          <ThumbsDownIcon />
        )
      }
      onClick={onClickWrapper}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  iconSelected: { color: theme.palette.error.main },
  badge: {
    "& .MuiBadge-badge": {
      color: theme.palette.text.primary,
      backgroundColor: "#e8e8e8",
    },
  },
  button: { padding: 5 },
}));

export default DislikeButton;
