import { injectable, inject } from "tsyringe";
import {
  INJECTION_TOKENS,
  Result,
  RemarksModel,
  TasksItemModel,
  type TasksItemRepository,
  TasksItemService,
} from "../../../application";

@injectable()
export class TasksItemServiceImpl implements TasksItemService {
  constructor(
    @inject(INJECTION_TOKENS.TasksItemRepository)
    private tasksItemRepo: TasksItemRepository
  ) {}

  async getTasksItem(
    itemId: number
  ): Promise<Result<TasksItemModel> | Result<void>> {
    return await this.tasksItemRepo.getTasksItem(itemId);
  }

  async addTask(
    itemId: number,
    description: string,
    assignedTo: number[]
  ): Promise<Result<void>> {
    return await this.tasksItemRepo.addTask(itemId, description, assignedTo);
  }

  async updateTask(
    taskId: number,
    description: string,
    assignedTo: number[]
  ): Promise<Result<void>> {
    return await this.tasksItemRepo.updateTask(taskId, description, assignedTo);
  }

  async removeTask(taskId: number): Promise<Result<void>> {
    return await this.tasksItemRepo.removeTask(taskId);
  }

  async getTaskRemarks(
    taskId: number
  ): Promise<Result<RemarksModel> | Result<void>> {
    return await this.tasksItemRepo.getTaskRemarks(taskId);
  }

  async addTaskRemark(taskId: number, text: string): Promise<Result<void>> {
    return await this.tasksItemRepo.addTaskRemark(taskId, text);
  }

  async removeTaskRemark(remarkId: number): Promise<Result<void>> {
    return await this.tasksItemRepo.removeTaskRemark(remarkId);
  }

  async updateTaskStatus(
    taskId: number,
    completed: boolean
  ): Promise<Result<void>> {
    return await this.tasksItemRepo.updateTaskStatus(taskId, completed);
  }
}
