import * as React from "react";

import {
  AddDatePropositionsModel,
  ChangeTimeFromDatePropositionModel,
  DatePropositionRemarksModel,
  DatePropositionTimesModel,
  GenericVoteType,
  Result,
} from "../../../application";

import { useDateItemService } from "../../../presentation";

interface HookState {
  remarksModel: DatePropositionRemarksModel | null;
  loading: boolean;
  loadingUpdate: boolean;
  reasons: string[] | null;
}

interface HookMethods {
  addDatePropositions(
    dateItemId: number,
    model: AddDatePropositionsModel
  ): Promise<Result>;
  removeDateProposition(datePropositionId: number): Promise<Result>;
  voteOnDateProposition(
    datePropositionId: number,
    voteType: GenericVoteType
  ): Promise<Result>;
  getTimesFromDateProposition(
    datePropositionId: number
  ): Promise<Result<DatePropositionTimesModel> | Result>;
  changeTimeFromDateProposition(
    datePropositionId: number,
    model: ChangeTimeFromDatePropositionModel
  ): Promise<Result>;
  pinDate(datePropositionId: number): Promise<Result>;
  getRemarks(
    datePropositionId: number
  ): Promise<Result<DatePropositionRemarksModel> | Result>;
  addRemark(datePropositionId: number, remark: string): Promise<Result>;
  removeRemark(remarkId: number): Promise<Result>;
}

export const useDateItemUsecases = (): [HookState, HookMethods] => {
  const [dateItemSvc] = useDateItemService();
  const [remarksModel, setRemarksModel] =
    React.useState<DatePropositionRemarksModel | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [reasons, setReasons] = React.useState<string[] | null>(null);

  const addDatePropositions = async (
    dateItemId: number,
    model: AddDatePropositionsModel
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await dateItemSvc.addDatePropositions(dateItemId, model);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const removeDateProposition = async (
    datePropositionId: number
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await dateItemSvc.removeDateProposition(datePropositionId);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const voteOnDateProposition = async (
    datePropositionId: number,
    voteType: GenericVoteType
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await dateItemSvc.voteOnDateProposition(
      datePropositionId,
      voteType
    );
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const getTimesFromDateProposition = async (
    datePropositonId: number
  ): Promise<Result<DatePropositionTimesModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await dateItemSvc.getTimesFromDateProposition(
      datePropositonId
    );
    setLoading(false);

    return result;
  };

  const changeTimeFromDateProposition = async (
    datePropositionId: number,
    model: ChangeTimeFromDatePropositionModel
  ): Promise<Result> => {
    setReasons(null);
    setLoadingUpdate(true);
    const result = await dateItemSvc.changeTimeFromDateProposition(
      datePropositionId,
      model
    );
    setLoadingUpdate(false);

    return result;
  };

  const pinDate = async (datePropositionId: number): Promise<Result> => {
    setReasons(null);
    setLoadingUpdate(true);
    const result = await dateItemSvc.pinDate(datePropositionId);
    setLoadingUpdate(false);

    return result;
  };

  const getRemarks = async (
    datePropositionId: number
  ): Promise<Result<DatePropositionRemarksModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await dateItemSvc.getRemarks(datePropositionId);
    setLoading(false);

    if (result.isSuccess && result.value) setRemarksModel(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const addRemark = async (
    datePropositionId: number,
    remark: string
  ): Promise<Result> => {
    setReasons(null);
    setLoadingUpdate(true);
    const result = await dateItemSvc.addRemark(datePropositionId, remark);
    setLoadingUpdate(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const removeRemark = async (remarkId: number): Promise<Result> => {
    setReasons(null);
    const result = await dateItemSvc.removeRemark(remarkId);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  return [
    { remarksModel, loading, loadingUpdate, reasons },
    {
      addDatePropositions,
      removeDateProposition,
      voteOnDateProposition,
      getTimesFromDateProposition,
      changeTimeFromDateProposition,
      pinDate,
      getRemarks,
      addRemark,
      removeRemark,
    },
  ];
};
