import * as React from "react";

import {
  InformationDialog,
  Paragraph,
  RegistrationLink,
  SectionSmall,
} from "../../../../../presentation";

export interface InvitationDialogProps {
  registrationUrl?: string;
  children(api: ChildrenApi): React.ReactNode;
}

interface ChildrenApi {
  open(): void;
}

export const InvitationDialog: React.FC<InvitationDialogProps> = ({
  registrationUrl,
  children,
}) => {
  return (
    <InformationDialog
      title="Gebruikers uitnodigen"
      content={
        <div>
          <Paragraph>
            Nodig gebruikers uit door onderstaande link te kopieren en te delen
            via jouw favoriete sociale media (denk aan Whatsapp, email,
            Instagram, etc.).
          </Paragraph>

          <SectionSmall>
            <RegistrationLink registrationUrl={registrationUrl} />
          </SectionSmall>
        </div>
      }
    >
      {children}
    </InformationDialog>
  );
};

export default InvitationDialog;
