import * as React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export interface LongArrowRightIconProps {}

export const LongArrowRightIcon: React.FC<LongArrowRightIconProps> = () => {
  return (
    <SvgIcon viewBox="0 0 512 512">
      <polygon
        points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5 
	405.606,308.713 476.213,238.106 "
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </SvgIcon>
  );
};

export default LongArrowRightIcon;
