import * as React from "react";

import { Box, makeStyles } from "@material-ui/core";
import { CommonButton, GenericTextField } from "../../../../../presentation";

import CopyIcon from "@material-ui/icons/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

export interface RegistrationLinkProps {
  registrationUrl?: string;
}

export const RegistrationLink: React.FC<RegistrationLinkProps> = ({
  registrationUrl,
}) => {
  // STYLES
  const classes = useStyles();

  const [isCopied, setIsCopied] = React.useState(false);

  if (!registrationUrl) return null;

  const styles: React.CSSProperties = {
    wordBreak: "break-all",
  };
  if (isCopied) styles.fontWeight = "bold";

  return (
    <>
      <Box display="flex">
        <GenericTextField
          disabled={true}
          displayLabel="Deelbare link"
          fieldName="registrationUrl"
          value={registrationUrl}
          className={`${classes.input} ${isCopied ? classes.copied : ""}`}
          style={{
            margin: 0,
          }}
        />

        <CopyToClipboard
          text={registrationUrl}
          onCopy={() => setIsCopied(true)}
        >
          <CommonButton
            label="Kopieer"
            startIcon={<CopyIcon />}
            style={{
              height: 56,
              left: -1,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />
        </CopyToClipboard>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiOutlinedInput-root": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  copied: {
    "& .MuiOutlinedInput-root": {
      fontWeight: "bold",
      color: theme.palette.success.main,
    },
  },
}));
export default RegistrationLink;
