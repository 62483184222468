import * as React from "react";

import { LoadingBackdrop, Overlay } from "../../../../../../presentation";
import { Card as MuiCard, makeStyles } from "@material-ui/core";

export interface Card {
  loading: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  isPinned?: boolean;
}

export const Card: React.FC<Card> = ({
  loading,
  disabled = false,
  fullWidth = false,
  isPinned = false,
  children,
}) => {
  // STYLES
  const classes = useStyles({ fullWidth });

  return (
    <div className={classes.cardWrapper}>
      <LoadingBackdrop show={loading} />

      <Overlay show={disabled} className={classes.overlay} />

      <MuiCard elevation={isPinned ? 24 : 3} className={classes.card}>
        <div className={classes.cardBody}>{children}</div>
      </MuiCard>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    position: "relative",
  },
  card: {
    borderRadius: 10,
    width: (props: any) => (props.fullWidth ? "auto" : 300),
    [theme.breakpoints.only("xs")]: {
      width: "auto !important",
    },
  },
  cardBody: {},
  overlay: {
    backgroundColor: "rgba(245,245,245,0.6)",
    borderRadius: 10,
  },
}));

export default Card;
