import * as React from "react";

import NewRemarkIcon from "@material-ui/icons/FiberNew";
import { Remark } from "./remark";
import { RemarkModel } from "../../../../../../application";
import { makeStyles } from "@material-ui/core";

export interface RemarkListProps {
  remarks: RemarkModel[];
  currentUserId?: number;
  onDelete(remarkId: number): Promise<void>;
}

export const RemarkList: React.FC<RemarkListProps> = ({
  remarks,
  currentUserId,
  onDelete,
}) => {
  // STYLES
  const classes = useStyles();

  // REFS
  const lastElementRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    scrollToBottom();
  }, [remarks]);

  const scrollToBottom = () => {
    lastElementRef.current?.scrollIntoView();
  };

  if (remarks.length === 0) return null;

  return (
    <div className={classes.remarkList}>
      {remarks.map(({ id, userId, username, text, createdOn, notSeen }) => (
        <div
          className={`
                ${classes.remarkWrapper}
                ${
                  userId === currentUserId
                    ? classes.remarkWrapperCurrentUser
                    : ""
                }
            `}
        >
          {notSeen && <NewRemarkIcon color="error" fontSize="large" />}
          <Remark
            remarkId={id}
            username={username}
            message={text}
            createdOn={createdOn}
            notSeen={notSeen}
            highlight={userId === currentUserId}
            onDelete={onDelete}
          />
        </div>
      ))}
      <div ref={lastElementRef}></div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  remarkList: {
    padding: 25,
    [theme.breakpoints.only("xs")]: {
      padding: 10,
    },
  },
  remarkWrapper: {
    marginBottom: 15,
  },
  remarkWrapperCurrentUser: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default RemarkList;
