import * as React from "react";

import { makeStyles } from "@material-ui/core";

export interface CardFooterProps {}

export const CardFooter: React.FC<CardFooterProps> = ({ children }) => {
  // STYLES
  const classes = useStyles();

  return (
    <div className={classes.footerWrapper}>
      <div className={classes.footer}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    position: "relative",
    zIndex: 10,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #eee",
    marginLeft: -16,
    marginRight: -16,
    marginBottom: -5,
  },
}));

export default CardFooter;
