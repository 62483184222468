import {
  ErrorScreen,
  HeaderNormal,
  HeaderSmall,
  LoadingSpinner,
  SectionSmall,
  UserContext,
} from "../../../presentation";
import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Box } from "@material-ui/core";

export interface RedeemUserKeyPageProps {}

export const RedeemUserKeyPage: React.FC<RedeemUserKeyPageProps> = ({}) => {
  // CONTEXTS
  const { login: _login } = useContext(UserContext);

  // ROUTER
  const params = useParams<RouteParams>();
  const { replace } = useHistory();

  // STATE
  const [reasons, setReasons] = React.useState<string[] | null>(null);

  // VARIABLES
  const topicId = parseInt(params.topicId);
  const redirectUrl = isNaN(topicId) ? `/` : `/topics/${topicId}`;

  useEffect(() => {
    login(params.userKey);
  }, []);

  const login = async (userKey: string) => {
    const result = await _login(userKey);

    if (result.isSuccess) replace(redirectUrl);
    if (result.isFailed) setReasons(result.reasons);
  };

  if (reasons) {
    return <ErrorScreen reasons={reasons} />;
  }

  return (
    <SectionSmall>
      <Box textAlign="center">
        <HeaderNormal title="Je toegangscode wordt gecontroleerd." />
        <HeaderSmall title="Even geduld alsjeblieft." />
        <LoadingSpinner size={100} />
      </Box>
    </SectionSmall>
  );
};

export default RedeemUserKeyPage;

interface RouteParams {
  userKey: string;
  topicId: string;
}
