import { injectable, inject } from "tsyringe";

import {
  INJECTION_TOKENS,
  DateItemModel,
  DatePropositionModel,
  GenericVoteType,
  type ItemRepository,
  Result,
} from "../../../application";
import { ItemService } from "../../interfaces/item/item.service";

@injectable()
export class ItemServiceImpl implements ItemService {
  constructor(
    @inject(INJECTION_TOKENS.ItemRepository)
    private itemRepo: ItemRepository
  ) {}

  async getDateItem(id: number): Promise<Result<DateItemModel> | Result<void>> {
    return await this.itemRepo.getDateItem(id);
  }
}
