import * as React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  AnswerList,
  Card,
  CardContent,
  CardHeader,
  CommonIconButton,
  CommonMenu,
  ConfirmationDialog,
  Paragraph,
  RemarksIconButton,
} from "../../../../../presentation";
import {
  GenericVoteType,
  QuestionModel,
  Result,
} from "../../../../../application";

import ArrowDownwardIcon from "@material-ui/icons/ExpandMoreOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import DoneIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/EditOutlined";
import MoreOptionsIcon from "@material-ui/icons/MoreVert";

export interface QuestionProps {
  question: QuestionModel;
  totalUsers: number;
  onOpenRemarks(questionId: number): void;
  onOpenAnswerRemarks(answerId: number): void;
  onRemove(questionId: number): Promise<Result>;
  onEdit(questionId: number): void;
  onRemoveAnswer(answerId: number): Promise<Result>;
  onLike(answerId: number): Promise<Result>;
  onDislike(answerId: number): Promise<Result>;
  onVote(answerId: number, voteType: GenericVoteType): Promise<Result>;
  onChoose(answerId: number): Promise<Result>;
  onUnChoose(answerId: number): Promise<Result>;
  onSend(questionId: number, description: string): Promise<Result>;
}

export const Question: React.FC<QuestionProps> = ({
  question,
  totalUsers,
  onOpenRemarks,
  onOpenAnswerRemarks,
  onRemove,
  onEdit,
  onRemoveAnswer,
  onLike,
  onDislike,
  onVote,
  onChoose,
  onUnChoose,
  onSend,
}) => {
  // STYLES
  const classes = useStyles();
  const theme = useTheme();
  const errorPalette = theme.palette.error;

  // STATE
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [selectionMode, setSelectionMode] = React.useState(false);

  // VARIABLES
  const { id, description, totalRemarks, totalUnSeenRemarks, done, answers } =
    question;
  const unSeenRemarksInAnswers = answers.some((a) => a.totalUnSeenRemarks > 0);

  // METHODS
  const onRemoveWrapper = async (questionId: number) => {
    setLoading(true);
    await onRemove(questionId);
    setLoading(false);
  };

  const onChooseWrapper = async (answerId: number) => {
    setSelectionMode(false);
    return await onChoose(answerId);
  };

  const onUnChooseWrapper = async (answerId: number) => {
    setSelectionMode(false);
    return await onUnChoose(answerId);
  };

  const handleSelectionModeOn = () => {
    setSelectionMode(true);
    setExpanded(true);
  };

  return (
    <Card isPinned={false} loading={loading}>
      <CardHeader confirmed={false}>
        <RemarksIconButton
          totalRemarks={totalRemarks}
          totalUnSeenRemarks={totalUnSeenRemarks}
          onClick={() => onOpenRemarks(id)}
        />
        {!done && !selectionMode && (
          <Chip
            color="default"
            icon={<DoneIcon />}
            label="Afronden"
            size="small"
            variant="outlined"
            onClick={handleSelectionModeOn}
          />
        )}
        {!done && selectionMode && (
          <Chip
            color="default"
            label="Annuleer"
            size="small"
            variant="default"
            style={{
              backgroundColor: errorPalette.main,
              color: errorPalette.contrastText,
            }}
            onClick={() => setSelectionMode(false)}
          />
        )}
        {done && !selectionMode && <DoneIcon className={classes.doneIcon} />}
        <ConfirmationDialog
          title="Verwijderen"
          confirmButtonWarningColor
          onConfirm={() => onRemoveWrapper(id)}
        >
          {({ open: openDialog }) => (
            <CommonMenu>
              {({ open: openMenu }) => ({
                button: (
                  <CommonIconButton
                    disabled={done}
                    icon={<MoreOptionsIcon />}
                    size="small"
                    onClick={openMenu}
                  />
                ),
                items: [
                  {
                    label: "Vraag aanpassen",
                    icon: <EditIcon />,
                    handleClick: () => onEdit(id),
                  },
                  {
                    label: "Verwijderen",
                    icon: <DeleteIcon />,
                    handleClick: openDialog,
                  },
                ],
              })}
            </CommonMenu>
          )}
        </ConfirmationDialog>
      </CardHeader>
      <CardContent>
        <Paragraph style={{ padding: "8px 0" }}>{description}</Paragraph>
        <Accordion
          expanded={expanded}
          onChange={(event, isExpanded) => setExpanded(isExpanded)}
          classes={{ root: classes.accordion }}
        >
          <AccordionSummary
            expandIcon={
              <ArrowDownwardIcon
                color={unSeenRemarksInAnswers ? "error" : undefined}
              />
            }
            classes={{
              root: classes.accordionHeader,
              content: classes.accordionHeaderContent,
              expandIcon: classes.expandIcon,
            }}
          />
          <AccordionDetails classes={{ root: classes.accordionDetails }}>
            <div className={classes.answerListWrapper}>
              <AnswerList
                answers={answers}
                totalUsers={totalUsers}
                selectionMode={selectionMode}
                blocked={done}
                onOpenAnswerRemarks={onOpenAnswerRemarks}
                onRemove={onRemoveAnswer}
                onLike={onLike}
                onDislike={onDislike}
                onVote={onVote}
                onChoose={onChooseWrapper}
                onUnChoose={onUnChooseWrapper}
                onSend={(description) => onSend(id, description)}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  accordion: {
    display: "flex",
    flexDirection: "column",
    marginLeft: -16,
    marginRight: -16,
    marginBottom: -5,
    "&.Mui-expanded": {
      marginLeft: -16,
      marginRight: -16,
      marginTop: 0,
      "&:last-child": {
        marginBottom: -5,
      },
    },
  },
  accordionHeader: { order: 2, "&.Mui-expanded": { minHeight: 48 } },
  accordionHeaderContent: { flexGrow: 0 },
  expandIcon: { marginRight: 0, padding: 8 },
  accordionDetails: { backgroundColor: "#eee", padding: 16 },
  answerListWrapper: { flexGrow: 1 },
  doneIcon: {
    color: theme.palette.success.main,
  },
}));

export default Question;
