export const INJECTION_TOKENS = {
  // Common
  WebStorage: "WebStorage",

  // Auth
  AuthenticationService: "AuthenticationService",
  TokenService: "TokenService",
  AuthRepository: "AuthRepository",

  // Item
  CostsItemService: "CostsItemService",
  CostsItemRepository: "CostsItemRepository",
  DateItemService: "DateItemService",
  DateItemRepository: "DateItemRepository",
  ItemService: "ItemService",
  ItemRepository: "ItemRepository",
  LocationItemService: "LocationItemService",
  LocationItemRepository: "LocationItemRepository",
  QuestionsItemService: "QuestionsItemService",
  QuestionsItemRepository: "QuestionsItemRepository",
  TasksItemService: "TasksItemService",
  TasksItemRepository: "TasksItemRepository",

  // Topic
  TopicService: "TopicService",
  TopicRepository: "TopicRepository",

  // User
  UserService: "UserService",
  UserRepository: "UserRepository",
};
