import * as React from "react";

import { Answer, AnswerInput } from "../../../../../presentation";
import {
  AnswerModel,
  GenericVoteType,
  Result,
} from "../../../../../application";

import { makeStyles } from "@material-ui/core";

export interface AnswerListProps {
  answers: AnswerModel[];
  totalUsers: number;
  selectionMode: boolean;
  blocked: boolean;
  onOpenAnswerRemarks(answerId: number): void;
  onRemove(answerId: number): Promise<Result>;
  onLike(answerId: number): Promise<Result>;
  onDislike(answerId: number): Promise<Result>;
  onVote(answerId: number, voteType: GenericVoteType): Promise<Result>;
  onChoose(answerId: number): Promise<Result>;
  onUnChoose(answerId: number): Promise<Result>;
  onSend(description: string): Promise<Result>;
}

export const AnswerList: React.FC<AnswerListProps> = ({
  answers,
  totalUsers,
  selectionMode,
  blocked,
  onOpenAnswerRemarks,
  onRemove,
  onLike,
  onDislike,
  onVote,
  onChoose,
  onUnChoose,
  onSend,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div>
      {answers.map((answer) => (
        <div key={answer.id} className={classes.answer}>
          <Answer
            answer={answer}
            totalUsers={totalUsers}
            selectionMode={selectionMode}
            blocked={blocked}
            onOpenAnswerRemarks={onOpenAnswerRemarks}
            onRemove={onRemove}
            onLike={onLike}
            onDislike={onDislike}
            onVote={onVote}
            onChoose={onChoose}
            onUnChoose={onUnChoose}
          />
        </div>
      ))}
      {!blocked && <AnswerInput onSend={onSend} />}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  answer: { marginBottom: 24, "&:last-child": { marginBottom: 0 } },
}));

export default AnswerList;
