import * as React from "react";

import { CommonIconButton } from "../../../../../presentation";
import ThumbsUpIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ThumbsUpSelectedIcon from "@material-ui/icons/ThumbUpAlt";
import { makeStyles } from "@material-ui/core";

export interface LikeButtonProps {
  selected: boolean;
  totalLikes: number;
  loading: boolean;
  disabled: boolean;
  onClick(): void;
}

export const LikeButton: React.FC<LikeButtonProps> = ({
  selected,
  totalLikes,
  loading,
  disabled,
  onClick,
}) => {
  // STYLES
  const classes = useStyles();

  // METHODS
  const onClickWrapper = () => {
    if (!selected) {
      onClick();
    }
  };

  return (
    <CommonIconButton
      badgeContent={totalLikes}
      badgeClass={classes.badge}
      className={classes.button}
      loading={loading}
      disabled={disabled}
      icon={
        selected ? (
          <ThumbsUpSelectedIcon className={classes.iconSelected} />
        ) : (
          <ThumbsUpIcon />
        )
      }
      onClick={onClickWrapper}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  iconSelected: { color: theme.palette.success.main },
  badge: {
    "& .MuiBadge-badge": {
      color: theme.palette.text.primary,
      backgroundColor: "#e8e8e8",
    },
  },
  button: { padding: 5 },
}));

export default LikeButton;
