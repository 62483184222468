import { inject, injectable } from "tsyringe";
import {
  INJECTION_TOKENS,
  CostForUpdateViewModel,
  CostsItemModel,
  type CostItemRepository,
  RemarksModel,
  Result,
  UpdateCostDivision,
  CostAndCalculationSummaryModel,
  CostsItemStatus,
  CostsItemService,
} from "../../../application";

@injectable()
export class CostsItemServiceImpl implements CostsItemService {
  constructor(
    @inject(INJECTION_TOKENS.CostsItemRepository)
    private _repo: CostItemRepository
  ) {}

  async getCostsItem(
    itemId: number
  ): Promise<Result<CostsItemModel> | Result<void>> {
    return await this._repo.getCostsItem(itemId);
  }

  async addCost(
    itemId: number,
    amount: number,
    description: string,
    divisions: UpdateCostDivision[],
    paidById: number
  ): Promise<Result<void>> {
    return await this._repo.addCost(
      itemId,
      amount,
      description,
      divisions,
      paidById
    );
  }

  async getCost(
    costId: number
  ): Promise<Result<void> | Result<CostForUpdateViewModel>> {
    return await this._repo.getCost(costId);
  }

  async updateCost(
    costId: number,
    amount: number,
    description: string,
    divisions: UpdateCostDivision[],
    paidById: number
  ): Promise<Result<void>> {
    return await this._repo.updateCost(
      costId,
      amount,
      description,
      divisions,
      paidById
    );
  }

  async removeCost(costId: number): Promise<Result<void>> {
    return await this._repo.removeCost(costId);
  }

  async getRemarks(
    costId: number
  ): Promise<Result<void> | Result<RemarksModel>> {
    return await this._repo.getRemarks(costId);
  }

  async addRemark(costId: number, text: string): Promise<Result<void>> {
    return await this._repo.addRemark(costId, text);
  }

  async removeRemark(remarkId: number): Promise<Result<void>> {
    return await this._repo.removeRemark(remarkId);
  }

  async updateStatus(
    itemId: number,
    itemStatus: CostsItemStatus
  ): Promise<Result<void>> {
    return await this._repo.updateStatus(itemId, itemStatus);
  }

  async getCostAndCalculationSummary(
    itemId: number
  ): Promise<Result<CostAndCalculationSummaryModel> | Result<void>> {
    return await this._repo.getCostAndCalculationSummary(itemId);
  }
}
