import * as React from "react";

import {
  ButtonBar,
  CommonButton,
  CommonSelect,
  ContainerLarge,
  CostDivisionList,
  GenericTextField,
  HeaderExtraSmall,
  HeaderNormal,
  ReasonList,
  SectionBox,
  useCostsItemUsecases,
  useTopicUsecases,
} from "../../../../presentation";
import { CostDivisionModel, UpdateCostDivision } from "../../../../application";
import { MenuItem, makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import { useForm } from "react-hook-form";

export interface NewCostPageProps {}

interface RouteParams {
  topicId: string;
  costsItemId: string;
}

export const NewCostPage: React.FC<NewCostPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // ROUTER
  const params = useParams<RouteParams>();
  const { push } = useHistory();

  // USECASES
  const [{ members }, { getMembers }] = useTopicUsecases();
  const [{ loading, reasons }, { addCost }] = useCostsItemUsecases();

  // STATE
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [costDivisions, setCostDivisions] = React.useState<CostDivisionModel[]>(
    []
  );

  // VARIABLES
  const topicId = parseInt(params.topicId);
  const costsItemId = parseInt(params.costsItemId);
  const url_Back = `/topics/${topicId}/costs-items/${costsItemId}`;
  const { register, handleSubmit, control, errors } = useForm();

  React.useEffect(() => {
    loadMembers();
  }, []);

  React.useEffect(() => {
    if (!members || members.length === 0) return;

    const newCostDivisions = members.map((m) => {
      const cd = new CostDivisionModel();

      cd.userId = m.id;
      cd.username = m.username;
      cd.share = 0;
      cd.amount = 0;

      return cd;
    });

    setCostDivisions(newCostDivisions);
  }, [members]);

  React.useEffect(() => {
    const newCostDivisions = [...costDivisions];
    const recalculatedCostDivisions =
      recalculateDivisionAmounts(newCostDivisions);
    setCostDivisions(recalculatedCostDivisions);
  }, [totalAmount]);

  // METHODS
  const loadMembers = async () => {
    await getMembers(topicId);
  };

  const handleNavigationBack = () => {
    push(url_Back);
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTotalAmount = Number(event.target.value);
    setTotalAmount(newTotalAmount);
  };

  const handleShareClick = () => {
    const newCostDivisions = costDivisions.map((cd) => {
      const newCostDivision = { ...cd };

      newCostDivision.share = 1;

      return newCostDivision;
    });

    const recalculatedCostDivisions =
      recalculateDivisionAmounts(newCostDivisions);
    setCostDivisions(recalculatedCostDivisions);
  };

  const handleDivisionChange = (userId: number, share: number) => {
    const newCostDivisions = [...costDivisions];
    const costDivision = newCostDivisions.find((cd) => cd.userId === userId);
    if (!costDivision) return;

    const index = newCostDivisions.indexOf(costDivision);
    const newCostDivision = { ...costDivision };
    newCostDivision.share = share;
    newCostDivisions.splice(index, 1, newCostDivision);

    const recalculatedCostDivisions =
      recalculateDivisionAmounts(newCostDivisions);
    setCostDivisions(recalculatedCostDivisions);
  };

  const recalculateDivisionAmounts = (
    newCostDivisions: CostDivisionModel[]
  ) => {
    const totalShare = newCostDivisions.reduce((prevValue, curValue) => {
      return prevValue + curValue.share;
    }, 0);

    return newCostDivisions.map((cd) => {
      const newCostDivision: CostDivisionModel = { ...cd };

      const amount = totalAmount * (newCostDivision.share / totalShare);
      newCostDivision.amount = isNaN(amount) ? 0 : amount;

      return newCostDivision;
    });
  };

  const handleActualSubmit = async (fieldValues: any) => {
    const { amount, description, paidById } = fieldValues;

    const updateCostDivisionModels = costDivisions.map((cd) => {
      const updateDto = new UpdateCostDivision();

      updateDto.mustBePaidById = cd.userId;
      updateDto.share = cd.share;

      return updateDto;
    });

    const result = await addCost(
      costsItemId,
      parseFloat(amount),
      description,
      updateCostDivisionModels,
      paidById
    );

    if (result.isSuccess) handleNavigationBack();
  };

  return (
    <ContainerLarge>
      <SectionBox>
        <HeaderNormal title="Kosten toevoegen" />
      </SectionBox>

      <SectionBox>
        <div className={classes.formWrapper}>
          <form autoComplete="off" onSubmit={handleSubmit(handleActualSubmit)}>
            <div>
              <GenericTextField
                displayLabel="Bedrag *"
                fieldName="amount"
                inputRef={register({
                  required: true,
                })}
                inputProps={{ step: ".01" }}
                onChange={handleAmountChange}
                type="number"
                fullWidth={false}
                style={{ width: 120, marginRight: 16 }}
              />

              <CommonSelect
                label="Betaald door *"
                name="paidById"
                disabled={!members}
                control={control}
                rules={{ required: true }}
                style={{ width: 160 }}
              >
                <MenuItem value="">(Leeg)</MenuItem>
                {members &&
                  members.map((m) => (
                    <MenuItem key={m.id} value={m.id}>
                      {m.username}
                    </MenuItem>
                  ))}
              </CommonSelect>
            </div>

            <div>
              <GenericTextField
                displayLabel="Omschrijving *"
                fieldName="description"
                inputRef={register({
                  required: true,
                })}
              />
            </div>

            <div
              style={{ display: "flex", alignItems: "center", marginTop: 16 }}
            >
              <div>
                <HeaderExtraSmall title="Verdeling" />
              </div>
              <div style={{ marginLeft: 16 }}>
                <CommonButton
                  label="Iedereen x 1"
                  size="small"
                  variant="contained"
                  className={classes.shareButton}
                  onClick={handleShareClick}
                />
              </div>
            </div>

            <CostDivisionList
              costDivisions={costDivisions}
              onChange={handleDivisionChange}
            />

            <SectionBox>
              <ButtonBar>
                <CommonButton
                  label="Annuleren"
                  onClick={handleNavigationBack}
                />
                <CommonButton
                  label="Toevoegen"
                  loading={loading}
                  type="submit"
                  color="primary"
                  variant="contained"
                />
              </ButtonBar>
            </SectionBox>
          </form>
          {reasons && (
            <SectionBox>
              <ReasonList reasons={reasons} />
            </SectionBox>
          )}
        </div>
      </SectionBox>
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    maxWidth: 600,
  },
  shareButton: {
    backgroundColor: "#FF5F58",
    color: "#fff",
    "&:hover": {
      backgroundColor: "rgb(255,57,49)",
    },
  },
}));

export default NewCostPage;
