import * as React from "react";

import { Grid, makeStyles } from "@material-ui/core";
import { Result, TaskModel } from "../../../../../application";

import { Task } from "../../../../../presentation";

export interface TaskListProps {
  tasks: TaskModel[];
  onOpenRemarks(taskId: number): void;
  onEdit(taskId: number): void;
  onRemove(taskId: number): Promise<Result>;
  onStatusChange(taskId: number, completed: boolean): Promise<Result>;
}

export const TaskList: React.FC<TaskListProps> = ({
  tasks,
  onOpenRemarks,
  onEdit,
  onRemove,
  onStatusChange,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        {tasks.map((t) => (
          <Grid
            key={t.id}
            item
            xs={12}
            sm="auto"
            classes={{
              root: classes.gridItem,
            }}
          >
            <Task
              task={t}
              onOpenRemarks={onOpenRemarks}
              onEdit={onEdit}
              onRemove={onRemove}
              onStatusChange={onStatusChange}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  gridItem: {
    marginRight: 30,
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
}));

export default TaskList;
