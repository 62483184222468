import * as React from "react";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CommonIconButton,
  CommonMenu,
  ConfirmationDialog,
  DoneIconComponent,
  HeaderExtraSmall,
  RemarksIconButton,
  SelectChipButton,
  TotalUsersInvolved,
  Voting,
} from "../../../../../../presentation";
import {
  GenericVoteType,
  LocationPropositionModel,
} from "../../../../../../application";

import DeleteIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/EditOutlined";
import GoogleMapsIcon from "@material-ui/icons/Room";
import LocationLinkIcon from "@material-ui/icons/Link";
import MoreOptionsIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core";

export interface LocationPropositionProps {
  model: LocationPropositionModel;
  selectionMode: boolean;
  locked?: boolean;
  onOpenRemarks(propositionId: number): void;
  onConfirm(propositionId: number): Promise<void>;
  onEdit(propositionId: number): Promise<void>;
  onRemove(propositionId: number): Promise<void>;
  onVote(propositionId: number, voteType: GenericVoteType): Promise<void>;
}

export const LocationProposition: React.FC<LocationPropositionProps> = ({
  model,
  selectionMode,
  locked = false,
  onOpenRemarks,
  onConfirm,
  onEdit,
  onRemove,
  onVote,
}) => {
  // STYLES
  const classes = useStyles();

  // PROPS
  const {
    id,
    name,
    locationLink,
    streetName,
    zipCode,
    location,
    pinned,
    totalRemarks,
    totalUnSeenRemarks,
    votes,
    memberVotes,
    contextData,
  } = model;

  // STATE
  const [loading, setLoading] = React.useState(false);

  // VARIABLES
  const voteAmountYes = `${
    votes.filter((v) => v.voteType === GenericVoteType.Yes).length
  }`;
  const voteAmountMaybe = `${
    votes.filter((v) => v.voteType === GenericVoteType.Maybe).length
  }`;
  const voteAmountNo = `${
    votes.filter((v) => v.voteType === GenericVoteType.No).length
  }`;

  // METHODS
  const onConfirmWrapper = async (propositionId: number) => {
    setLoading(true);
    await onConfirm(propositionId);
    setLoading(false);
  };

  const onRemoveWrapper = async (propositionId: number) => {
    setLoading(true);
    await onRemove(propositionId);
    setLoading(false);
  };

  const onVoteWrapper = async (voteType: GenericVoteType) => {
    setLoading(true);
    await onVote(id, voteType);
    setLoading(false);
  };

  const handleNavigationToGoogleMaps = () => {
    const addressInfo: string[] = [];

    if (streetName) addressInfo.push(streetName);
    if (zipCode) addressInfo.push(zipCode);
    if (location) addressInfo.push(location);

    window.open(
      `https://www.google.com/maps/place/${addressInfo.join(",")}`,
      "_blank"
    );
  };

  const validGoogleMapsAddress = (
    streetName: string,
    zipCode: string,
    location: string
  ) => {
    if (streetName && (zipCode || location)) return true;
    if (!streetName && zipCode && location) return true;

    return false;
  };

  const validHyperlink = (hyperlink: string) => {
    if (hyperlink) return true;

    return false;
  };

  const handleNavigationToHyperlink = () => {
    window.open(locationLink, "_blank");
  };

  return (
    <Card
      loading={loading}
      isPinned={pinned}
      disabled={!pinned && (locked || selectionMode)}
    >
      <CardHeader>
        <RemarksIconButton
          totalRemarks={totalRemarks}
          totalUnSeenRemarks={totalUnSeenRemarks}
          onClick={() => onOpenRemarks(id)}
        />

        {selectionMode && !pinned && (
          <SelectChipButton
            style={{ zIndex: 10 }}
            onClick={() => onConfirmWrapper(id)}
          />
        )}
        {pinned && <DoneIconComponent />}

        <ConfirmationDialog
          title="Verwijderen"
          confirmButtonWarningColor
          onConfirm={() => onRemoveWrapper(id)}
        >
          {({ open: openDialog }) => (
            <CommonMenu>
              {({ open: openMenu }) => ({
                button: (
                  <CommonIconButton
                    icon={<MoreOptionsIcon />}
                    size="small"
                    onClick={openMenu}
                    style={{ zIndex: 10 }}
                  />
                ),
                items: [
                  {
                    label: "Open in Google Maps",
                    icon: <GoogleMapsIcon />,
                    disabled: !validGoogleMapsAddress(
                      streetName,
                      zipCode,
                      location
                    ),
                    handleClick: handleNavigationToGoogleMaps,
                  },
                  {
                    label: "Open hyperlink",
                    icon: <LocationLinkIcon />,
                    disabled: !validHyperlink(locationLink),
                    handleClick: handleNavigationToHyperlink,
                  },
                  {
                    label: "Locatie aanpassen",
                    icon: <EditIcon />,
                    disabled: locked,
                    handleClick: () => onEdit(id),
                  },
                  {
                    label: "Verwijderen",
                    icon: <DeleteIcon />,
                    disabled: locked,
                    handleClick: openDialog,
                  },
                ],
              })}
            </CommonMenu>
          )}
        </ConfirmationDialog>
      </CardHeader>
      <CardContent>
        <div className={classes.nameWrapper}>
          <HeaderExtraSmall
            title={name}
            className={selectionMode ? classes.textSelectionMode : ""}
          />
        </div>
        <Voting
          voteAmountYes={voteAmountYes}
          voteAmountNo={voteAmountNo}
          voteAmountMaybe={voteAmountMaybe}
          userVoteType={contextData.userVoteType}
          locked={locked}
          selectionMode={selectionMode}
          onVote={onVoteWrapper}
        />

        <CardFooter>
          <TotalUsersInvolved memberVotes={memberVotes} />
        </CardFooter>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  nameWrapper: {
    textAlign: "center",
    padding: "8px 0",
  },
  textSelectionMode: {
    position: "relative",
    zIndex: 10,
  },
}));

export default LocationProposition;
