import * as React from "react";

export interface SectionBoxProps {
  className?: string;
  style?: React.CSSProperties;
}

export const SectionBox: React.FC<SectionBoxProps> = ({
  className,
  style,
  children,
}) => {
  const styles: React.CSSProperties = {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  };

  if (style) Object.assign(styles, style);

  return (
    <section className={className} style={styles}>
      {children}
    </section>
  );
};

export default SectionBox;
