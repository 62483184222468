import * as React from "react";

import {
  DatePropositionModel,
  GenericVoteType,
} from "../../../../../../application";
import { Grid, makeStyles } from "@material-ui/core";

import { DateProposition } from "../../../../../../presentation";

export interface DatePropositionListProps {
  datePropositions: DatePropositionModel[];
  selectionMode: boolean;
  done: boolean;
  onEditTime(datePropositionId: number): Promise<void>;
  onDelete(datePropositionId: number): Promise<void>;
  onVote(datePropositionId: number, voteType: GenericVoteType): Promise<void>;
  onPinDate(datePropositionId: number): Promise<void>;
  onOpenRemarks(datePropositionId: number): Promise<void>;
}

export const DatePropositionList: React.FC<DatePropositionListProps> = ({
  datePropositions,
  selectionMode,
  done,
  onEditTime,
  onDelete,
  onVote,
  onPinDate,
  onOpenRemarks,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        {datePropositions.map((dp) => (
          <Grid
            key={dp.id}
            item
            xs={12}
            sm="auto"
            classes={{
              root: classes.gridItem,
            }}
          >
            <DateProposition
              dateProposition={dp}
              selectionMode={selectionMode}
              locked={done}
              onEditTime={onEditTime}
              onDelete={onDelete}
              onVote={onVote}
              onPinDate={onPinDate}
              onOpenRemarks={onOpenRemarks}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  gridItem: {
    marginRight: 30,
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
}));

export default DatePropositionList;
