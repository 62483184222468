import { AppBar, Box, Toolbar, makeStyles } from "@material-ui/core";
import {
  CommonButton,
  LoginIcon,
  UserContext,
  UserInfoView,
} from "../../presentation";
import React, { useContext } from "react";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#F8F8F8",
    color: theme.palette.primary.main,
    boxShadow: "none",
  },
  toolbar: {
    paddingLeft: 3,
  },
}));

export interface NavigationBarViewProps {}

export const NavigationBarView: React.FC<NavigationBarViewProps> = () => {
  // STYLES
  const classes = useStyles();

  // CONTEXT
  const { user, logout } = useContext(UserContext);

  // ROUTER
  const { push } = useHistory();

  return (
    <AppBar classes={{ root: classes.appBar }}>
      <Toolbar classes={{ root: classes.toolbar }}>
        <Box style={{ flexGrow: 1 }}></Box>
        {/* {!user?.email && (
          <CommonButton
            label="Inloggen"
            startIcon={<LoginIcon />}
            onClick={() => push("/request-user-key")}
          />
        )} */}
        {user?.email && (
          <UserInfoView
            email={user.email}
            onOpenSettings={() => push("/user/preferences")}
            onLogout={logout}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBarView;
