interface IOptions {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

class Options implements IOptions {
  minimumFractionDigits: number = 0;
  maximumFractionDigits: number = 2;
}

const format = (number: number, options?: IOptions) => {
  const _options = Object.assign(new Options(), options);

  return Number(number).toLocaleString(undefined, {
    minimumFractionDigits: _options.minimumFractionDigits,
    maximumFractionDigits: _options.maximumFractionDigits,
  });
};

export { format };
