import * as React from "react";

export interface HookState {
  isOpen: boolean;
  anchor: any;
}

interface HookMethods {
  open(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  close(): void;
}

export const useMenu = (): [HookState, HookMethods] => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchor, setAnchor] = React.useState<any | null>(null);

  const open = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchor(event.currentTarget);
    setIsOpen(true);
  };

  const close = () => {
    setAnchor(null);
    setIsOpen(false);
  };

  return [
    { isOpen, anchor },
    { open, close },
  ];
};
