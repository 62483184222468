import { AuthenticationService } from "../../../application";
import { INJECTION_TOKENS } from "../../../application";
import React from "react";
import { useDependencyInjectionService } from "../../../presentation";

export const useAuthenticationService = (): [AuthenticationService] => {
  const [dependencyInjectionSvc] = useDependencyInjectionService();
  const authenticationSvc =
    dependencyInjectionSvc.getService<AuthenticationService>(
      INJECTION_TOKENS.AuthenticationService
    );

  return [authenticationSvc];
};
