import * as React from "react";

import {
  ButtonBar,
  CommonButton,
  ContainerLarge,
  GenericTextField,
  HeaderNormal,
  ReasonList,
  SectionBox,
  useQuestionsItemUsecases,
} from "../../../../presentation";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";

export interface NewQuestionPageProps {}

interface RouteParams {
  topicId: string;
  questionsItemId: string;
}

export const NewQuestionPage: React.FC<NewQuestionPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // ROUTER
  const params = useParams<RouteParams>();
  const { push } = useHistory();

  // USECASES
  const [{ loading, reasons }, { addQuestion }] = useQuestionsItemUsecases();

  // STATE

  // VARIABLES
  const topicId = parseInt(params.topicId);
  const questionsItemId = parseInt(params.questionsItemId);
  const url_Back = `/topics/${topicId}/questions-items/${questionsItemId}`;
  const { register, handleSubmit, errors } = useForm();

  // METHODS
  const handleNavigationBack = () => {
    push(url_Back);
  };

  const handleActualSubmit = async (fieldValues: any) => {
    const result = await addQuestion(questionsItemId, fieldValues.description);

    if (result.isSuccess) handleNavigationBack();
  };

  return (
    <ContainerLarge>
      <SectionBox>
        <HeaderNormal title="Vraag toevoegen" />
      </SectionBox>

      <SectionBox>
        <div className={classes.formWrapper}>
          <form autoComplete="off" onSubmit={handleSubmit(handleActualSubmit)}>
            <div>
              <GenericTextField
                displayLabel="Omschrijving *"
                fieldName="description"
                inputRef={register({
                  required: true,
                })}
              />
            </div>

            <SectionBox>
              <ButtonBar>
                <CommonButton
                  label="Annuleren"
                  onClick={handleNavigationBack}
                />
                <CommonButton
                  color="primary"
                  label="Toevoegen"
                  loading={loading}
                  type="submit"
                  variant="contained"
                />
              </ButtonBar>
            </SectionBox>
          </form>
          {reasons && (
            <SectionBox>
              <ReasonList reasons={reasons} />
            </SectionBox>
          )}
        </div>
      </SectionBox>
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    maxWidth: 600,
  },
}));

export default NewQuestionPage;
