import * as React from "react";

import {
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

import { Paragraph } from "../../../../presentation";

export interface ReasonListProps {
  centerText?: boolean;
  reasons: string[] | null;
  style?: React.CSSProperties;
}

export const ReasonList: React.FC<ReasonListProps> = ({
  centerText,
  reasons,
  style,
}) => {
  const classes = useStyles();
  if (reasons == null) return null;

  return (
    <Box color="error.main" className={classes.reasonList} style={style}>
      <List>
        {reasons.map((reason, index) => (
          <ListItem key={index} button>
            <ListItemText
              primary={
                <Paragraph style={{ color: "inherit" }}>{reason}</Paragraph>
              }
              style={{ textAlign: "center" }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  reasonList: {},
}));

export default ReasonList;
