import * as React from "react";

import {
  Card,
  CardContent,
  CardHeader,
  CommonIconButton,
  CommonMenu,
  ConfirmationDialog,
  DoneIconComponent,
  RemarksIconButton,
  Text,
} from "../../../../../presentation";
import { Result, TaskModel } from "../../../../../application";

import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/EditOutlined";
import MoreOptionsIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core";

export interface TaskProps {
  task: TaskModel;
  onOpenRemarks(taskId: number): void;
  onEdit(taskId: number): void;
  onRemove(taskId: number): Promise<Result>;
  onStatusChange(taskId: number, completed: boolean): Promise<Result>;
}

export const Task: React.FC<TaskProps> = ({
  task,
  onOpenRemarks,
  onEdit,
  onRemove,
  onStatusChange,
}) => {
  // STYLES
  const classes = useStyles();

  // STATE
  const [loading, setLoading] = React.useState(false);

  // VARIABLES
  const {
    id,
    description,
    assignedToUsername,
    completed,
    totalRemarks,
    totalUnSeenRemarks,
  } = task;

  // METHODS
  const onRemoveWrapper = async (taskId: number) => {
    setLoading(true);
    await onRemove(taskId);
    setLoading(false);
  };

  const onStatusChangeWrapper = async (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setLoading(true);
    await onStatusChange(id, checked);
    setLoading(false);
  };

  return (
    <div className={classes.cardWrapper}>
      <div className={classes.checkboxColumn}>
        <Checkbox
          color="primary"
          checked={completed}
          onChange={onStatusChangeWrapper}
        />
      </div>
      <div className={classes.cardColumn}>
        <Card isPinned={false} loading={loading}>
          <CardHeader confirmed={false}>
            <RemarksIconButton
              totalRemarks={totalRemarks}
              totalUnSeenRemarks={totalUnSeenRemarks}
              onClick={() => onOpenRemarks(id)}
            />
            <div>
              {completed ? (
                <DoneIconComponent />
              ) : (
                <Text>{assignedToUsername || "-"}</Text>
              )}
            </div>
            <ConfirmationDialog
              title="Verwijderen"
              confirmButtonWarningColor
              onConfirm={() => onRemoveWrapper(id)}
            >
              {({ open: openDialog }) => (
                <CommonMenu>
                  {({ open: openMenu }) => ({
                    button: (
                      <CommonIconButton
                        disabled={completed}
                        icon={<MoreOptionsIcon />}
                        size="small"
                        onClick={openMenu}
                      />
                    ),
                    items: [
                      {
                        label: "Taak aanpassen",
                        icon: <EditIcon />,
                        handleClick: () => onEdit(id),
                      },
                      {
                        label: "Verwijderen",
                        icon: <DeleteIcon />,
                        handleClick: openDialog,
                      },
                    ],
                  })}
                </CommonMenu>
              )}
            </ConfirmationDialog>
          </CardHeader>
          <CardContent>
            <div
              className={`
                    ${classes.taskDescription} 
                    ${completed ? classes.taskDescriptionCompleted : ""}
                `}
            >
              <Text>{description}</Text>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    alignItems: "center",
  },
  checkboxColumn: {},
  cardColumn: {
    [theme.breakpoints.only("xs")]: {
      flexGrow: 1,
    },
  },
  taskDescription: {
    padding: "10px 5px",
  },
  taskDescriptionCompleted: {
    textDecoration: "line-through",
  },
}));

export default Task;
