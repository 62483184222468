import * as React from "react";

import { ButtonBar, CommonButton, Text } from "../../../../../../presentation";
import { makeStyles, useTheme } from "@material-ui/core";

import { GenericVoteType } from "../../../../../../application";
import VoteMaybeIcon from "@material-ui/icons/Help";
import VoteNoIcon from "@material-ui/icons/Clear";
import VoteYesIcon from "@material-ui/icons/Check";

export interface DateVotingProps {
  voteAmountYes: string;
  voteAmountNo: string;
  voteAmountMaybe: string;
  locked: boolean;
  userVoteType: GenericVoteType | null;
  selectionMode?: boolean;
  onVote(voteType: GenericVoteType): Promise<void>;
}

export const DateVoting: React.FC<DateVotingProps> = ({
  voteAmountYes,
  voteAmountNo,
  voteAmountMaybe,
  locked,
  userVoteType,
  selectionMode = false,
  onVote,
}) => {
  // STYLES
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.votingWrapper}>
      <ButtonBar justifyContent="space-around">
        <div>
          <CommonButton
            disabled={locked || userVoteType === GenericVoteType.Yes}
            icon={<VoteYesIcon />}
            iconColor="default"
            hoverColor={theme.palette.success.main}
            className={`${classes.votingButton} ${
              userVoteType === GenericVoteType.Yes && "selected"
            }`}
            onClick={() => onVote(GenericVoteType.Yes)}
          />
          <Text
            className={`${classes.votingAmount} ${
              selectionMode ? classes.textSelectionMode : ""
            }`}
          >
            {voteAmountYes}
          </Text>
        </div>
        <div>
          <CommonButton
            disabled={locked || userVoteType === GenericVoteType.Maybe}
            icon={<VoteMaybeIcon />}
            iconColor="default"
            hoverColor={theme.palette.warning.main}
            className={`${classes.votingButton} ${
              userVoteType === GenericVoteType.Maybe && "selected"
            }`}
            onClick={() => onVote(GenericVoteType.Maybe)}
          />
          <Text
            className={`${classes.votingAmount} ${
              selectionMode ? classes.textSelectionMode : ""
            }`}
          >
            {voteAmountMaybe}
          </Text>
        </div>
        <div>
          <CommonButton
            disabled={locked || userVoteType === GenericVoteType.No}
            icon={<VoteNoIcon />}
            iconColor="default"
            hoverColor={theme.palette.error.main}
            className={`${classes.votingButton} ${
              userVoteType === GenericVoteType.No && "selected"
            }`}
            onClick={() => onVote(GenericVoteType.No)}
          />
          <Text
            className={`${classes.votingAmount} ${
              selectionMode ? classes.textSelectionMode : ""
            }`}
          >
            {voteAmountNo}
          </Text>
        </div>
      </ButtonBar>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  votingWrapper: {
    padding: "10px 0",
  },
  votingAmount: {
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 5,
    marginLeft: 4,
  },
  votingButton: {
    transition: "background-color 1000ms",
  },
  textSelectionMode: {
    position: "relative",
    zIndex: 10,
  },
}));

export default DateVoting;
