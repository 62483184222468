import * as React from "react";

import { CircularProgress } from "@material-ui/core";

export interface LoadingSpinnerProps {
  size?: number;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 50,
}) => {
  return <CircularProgress color="primary" size={size} />;
};

export default LoadingSpinner;
