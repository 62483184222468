import * as React from "react";

import { INJECTION_TOKENS, TasksItemService } from "../../../application";

import { useDependencyInjectionService } from "../../../presentation";

export const useTasksItemService = (): [TasksItemService] => {
  const [containerSvc] = useDependencyInjectionService();
  const tasksItemSvc = containerSvc.getService<TasksItemService>(
    INJECTION_TOKENS.TasksItemService
  );

  return [tasksItemSvc];
};
