import * as React from "react";

import { INJECTION_TOKENS } from "../../../application";
import { TopicService } from "../../../application";
import { useDependencyInjectionService } from "../../../presentation";

export const useTopicService = (): [TopicService] => {
  const [dependencyInjectionSvc] = useDependencyInjectionService();
  const topicService = dependencyInjectionSvc.getService<TopicService>(
    INJECTION_TOKENS.TopicService
  );

  return [topicService];
};
