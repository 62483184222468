import { Chip, makeStyles } from "@material-ui/core";
import {
  CommonInfoDialog,
  ContainerLarge,
  UserContext,
} from "../../../../../../presentation";
import { RemarkModel, Result } from "../../../../../../application";

import React from "react";
import RemarkScreen from "./remark-screen";
import { SubjectSharp } from "@material-ui/icons";

interface RemarksDialogProps {
  open: boolean;
  subject: string | null | undefined;
  remarks: RemarkModel[] | null | undefined;
  onClose(): void;
  onSend(message: string): Promise<Result>;
  onDelete(remarkId: number): Promise<void>;
}

export const RemarksDialog: React.FC<RemarksDialogProps> = ({
  open,
  subject,
  remarks,
  onClose,
  onSend,
  onDelete,
}) => {
  const classes = useStyles();
  const { user } = React.useContext(UserContext);

  return (
    <CommonInfoDialog
      open={open}
      title={
        <div>
          <span>Opmerkingen </span>
          <Chip color="primary" label={subject} className={classes.subject} />
        </div>
      }
      content={
        <div className={classes.remarkScreenWrapper}>
          <ContainerLarge className={classes.remarkScreenContainer}>
            <RemarkScreen
              currentUserId={user?.id}
              remarks={remarks || []}
              onSend={onSend}
              onDelete={onDelete}
            />
          </ContainerLarge>
        </div>
      }
      fullScreen={true}
      onClose={onClose}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  subject: {
    [theme.breakpoints.only("xs")]: {
      maxWidth: 200,
    },
  },
  remarkScreenWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  remarkScreenContainer: {
    height: "100%",
    paddingBottom: 0,
  },
}));

export default RemarksDialog;
