import { ItemTypeHelper } from "../../../application";

export class AddItemsModel {
  DateItem: boolean = false;
  LocationItem: boolean = false;
  TasksItem: boolean = false;
  QuestionsItem: boolean = false;
  CostsItem: boolean = false;

  public static fromItemTypes(itemTypes: number[]): AddItemsModel {
    let model = new AddItemsModel();

    if (ItemTypeHelper.containsDateItem(itemTypes)) {
      model.DateItem = true;
    }

    if (ItemTypeHelper.containsLocationItem(itemTypes)) {
      model.LocationItem = true;
    }

    if (ItemTypeHelper.containsTodosItem(itemTypes)) {
      model.TasksItem = true;
    }

    if (ItemTypeHelper.containsQuestionsItem(itemTypes)) {
      model.QuestionsItem = true;
    }

    if (ItemTypeHelper.containsCostsItem(itemTypes)) {
      model.CostsItem = true;
    }

    return model;
  }
}
