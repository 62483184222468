import {} from "../../../../components";

import * as React from "react";

import {
  Aligner,
  HeaderSmall,
  ItemImageContainer,
  OpenActionsIcon,
  Text,
  UnSeenRemarksNotification,
} from "../../../../../presentation";
import { Box, Card, CardContent, makeStyles } from "@material-ui/core";

import ActionFinishedIcon from "@material-ui/icons/CheckCircleOutlined";
import { ItemType } from "../../../../../application";

export interface ItemCardProps {
  itemId: number;
  itemType: ItemType;
  label: string;
  summaryText: string;
  done: boolean;
  hasOpenActions: boolean;
  totalUnSeenRemarks: number;
  onClick(itemId: number, itemType: ItemType): void;
}

export const ItemCard: React.FC<ItemCardProps> = ({
  itemId,
  itemType,
  label,
  summaryText,
  done,
  hasOpenActions,
  totalUnSeenRemarks,
  onClick,
}) => {
  const classes = useStyles();

  const borderColorClass = done
    ? classes.borderColorDone
    : classes.borderColorBusy;

  return (
    <Card
      classes={{ root: classes.root }}
      elevation={3}
      onClick={() => onClick(itemId, itemType)}
    >
      <Box
        className={`${classes.header} ${done && classes.headerDone}`}
        height={75}
        position="relative"
      >
        <Box display="flex">
          <div style={{ paddingTop: 12, paddingLeft: 6 }}>
            <UnSeenRemarksNotification
              totalUnSeenRemarks={totalUnSeenRemarks}
            />
          </div>
        </Box>
        <ItemImageContainer
          itemType={itemType}
          style={{
            position: "absolute",
            left: "50%",
            bottom: "-50%",
            transform: "translate(-50%, 15%)",
          }}
        />
      </Box>
      <CardContent classes={{ root: classes.content }}>
        <Box height={70}>
          <Aligner horizontal>
            <HeaderSmall title={label} />
            <Text>{summaryText}</Text>
          </Aligner>
        </Box>
        <div className={classes.statusContainer}>
          <div>
            {hasOpenActions ? (
              <OpenActionsIcon />
            ) : (
              <ActionFinishedIcon
                classes={{ root: classes.actionFinishedIcon }}
              />
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    borderRadius: 10,
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      margin: "auto",
    },
  },
  icon: {
    color: "#fff",
  },
  imageContainer: {
    position: "absolute",
    width: 100,
    height: 100,
    bottom: -50,
    marginLeft: 75,
    borderRadius: 100,
    border: "1px solid #ddd",
    backgroundColor: "#fff",
  },
  borderColorBusy: {
    borderColor: `${theme.palette.warning.main} !important`,
  },
  borderColorDone: {
    borderColor: `${theme.palette.success.main} !important`,
  },
  header: {
    // borderBottom: "2px solid #ddd",
    backgroundColor: theme.palette.warning.main,
  },
  headerDone: {
    backgroundColor: theme.palette.success.main,
  },
  content: {
    paddingTop: 50,
    paddingBottom: "14px !important",
  },
  statusContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px -8px -12px",
  },

  actionFinishedIcon: {
    color: theme.palette.success.main,
  },
}));

export default ItemCard;
