import * as React from "react";

import {
  CostAndCalculationSummaryModel,
  CostForUpdateViewModel,
  CostsItemModel,
  CostsItemService,
  CostsItemStatus,
  RemarksModel,
  Result,
  UpdateCostDivision,
} from "../../../application";

import { useCostsItemService } from "../../../presentation";

interface HookState {
  costsItem: CostsItemModel | null;
  costForUpdate: CostForUpdateViewModel | null;
  remarks: RemarksModel | null;
  summary: CostAndCalculationSummaryModel | null;
  loading: boolean;
  reasons: string[] | null;
}

interface HookMethods extends CostsItemService {}

export const useCostsItemUsecases = (): [HookState, HookMethods] => {
  const [costsItemSvc] = useCostsItemService();
  const [costsItem, setCostsItem] = React.useState<CostsItemModel | null>(null);
  const [costForUpdate, setCostForUpdate] =
    React.useState<CostForUpdateViewModel | null>(null);
  const [remarks, setRemarks] = React.useState<RemarksModel | null>(null);
  const [summary, setSummary] =
    React.useState<CostAndCalculationSummaryModel | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [reasons, setReasons] = React.useState<string[] | null>(null);

  const getCostsItem = async (
    itemId: number
  ): Promise<Result<CostsItemModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await costsItemSvc.getCostsItem(itemId);
    setLoading(false);

    if (result.isSuccess && result.value) setCostsItem(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const addCost = async (
    itemId: number,
    amount: number,
    description: string,
    divisions: UpdateCostDivision[],
    paidById: number
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await costsItemSvc.addCost(
      itemId,
      amount,
      description,
      divisions,
      paidById
    );
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const getCost = async (
    costId: number
  ): Promise<Result<CostForUpdateViewModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await costsItemSvc.getCost(costId);
    setLoading(false);

    if (result.isSuccess && result.value) setCostForUpdate(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const updateCost = async (
    costId: number,
    amount: number,
    description: string,
    divisions: UpdateCostDivision[],
    paidById: number
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await costsItemSvc.updateCost(
      costId,
      amount,
      description,
      divisions,
      paidById
    );
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const removeCost = async (costId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await costsItemSvc.removeCost(costId);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const getRemarks = async (
    costId: number
  ): Promise<Result<RemarksModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await costsItemSvc.getRemarks(costId);
    setLoading(false);

    if (result.isSuccess && result.value) setRemarks(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const addRemark = async (costId: number, text: string): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await costsItemSvc.addRemark(costId, text);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const removeRemark = async (remarkId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await costsItemSvc.removeRemark(remarkId);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const getCostAndCalculationSummary = async (
    itemId: number
  ): Promise<Result<CostAndCalculationSummaryModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await costsItemSvc.getCostAndCalculationSummary(itemId);
    setLoading(false);

    if (result.isSuccess && result.value) setSummary(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const updateStatus = async (
    itemId: number,
    itemStatus: CostsItemStatus
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await costsItemSvc.updateStatus(itemId, itemStatus);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  return [
    {
      costsItem,
      costForUpdate,
      remarks,
      summary,
      loading,
      reasons,
    },
    {
      getCostsItem,
      addCost,
      getCost,
      updateCost,
      removeCost,
      getRemarks,
      addRemark,
      removeRemark,
      updateStatus,
      getCostAndCalculationSummary,
    },
  ];
};
