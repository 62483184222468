import * as React from "react";

import { Box } from "@material-ui/core";

export interface HideOnMobileProps {}

export const HideOnMobile: React.FC<HideOnMobileProps> = ({ children }) => {
  return <Box display={{ xs: "none", sm: "block" }}>{children}</Box>;
};

export default HideOnMobile;
