import * as React from "react";

import { Alert, Color } from "@material-ui/lab";
import { IconButton, Snackbar } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { CommonIconButton } from "../../../../presentation";

export interface CommonSnackbarProps {
  isOpen: boolean;
  title: string;
  severity?: Color;
  onClose(): void;
}

export const CommonSnackbar: React.FC<CommonSnackbarProps> = ({
  isOpen,
  title,
  severity = "success",
  onClose,
}) => {
  return (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      ClickAwayListenerProps={{ onClickAway: () => {} }}
      open={isOpen}
      autoHideDuration={4000}
      onClose={onClose}
    >
      <Alert
        action={
          <IconButton color="inherit" size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        severity={severity}
        variant="filled"
      >
        {title}
      </Alert>
    </Snackbar>
  );
};

export default CommonSnackbar;
