import * as React from "react";

import {
  CommonButton,
  HeaderNormal,
  Paragraph,
  useDialog,
} from "../../../../presentation";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  confirmButtonWarning: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      opacity: "0.5",
    },
  },
}));

export interface ConfirmationDialogProps {
  title: string;
  description?: string;
  confirmButtonWarningColor?: boolean;
  children(api: Api): React.ReactNode;
  onConfirm?(): void;
  onCancel?(): void;
}

interface Api {
  open(): void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  description = "Weet je het zeker dat je het definitief wilt verwijderen?",
  confirmButtonWarningColor = false,
  children,
  onConfirm = () => {},
  onCancel = () => {},
}) => {
  const classes = useStyles();
  const [{ isOpen }, { open, close }] = useDialog();

  const onConfirmWrapper = () => {
    close();
    onConfirm();
  };

  const onCancelWrapper = () => {
    close();
    onCancel();
  };

  return (
    <div>
      {children({ open })}
      <Dialog open={isOpen} onClose={close} maxWidth="sm" fullWidth>
        <DialogTitle>
          <HeaderNormal title={title} disableGutter />
        </DialogTitle>
        <DialogContent dividers>
          <Paragraph>{description}</Paragraph>
        </DialogContent>
        <DialogActions>
          <CommonButton label="Nee" onClick={onCancelWrapper} />
          <CommonButton
            label="Ja"
            variant="contained"
            onClick={onConfirmWrapper}
            className={`${
              confirmButtonWarningColor ? classes.confirmButtonWarning : ""
            }`}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
