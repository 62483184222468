import * as React from "react";

import { Button, CircularProgress, makeStyles } from "@material-ui/core";

export interface CommonButtonProps {
  label?: string | React.ReactNode;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  color?: "inherit" | "primary" | "secondary" | "default";
  variant?: "contained" | "outlined" | "text";
  fullWidth?: boolean;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  icon?: React.ReactNode;
  iconColor?: "default" | "primary" | "secondary";
  hoverColor?: string;
  borderWidth?: number;
  size?: "small" | "medium" | "large" | undefined;
  className?: string;
  style?: React.CSSProperties;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

export const CommonButton: React.FC<CommonButtonProps> = ({
  label,
  loading = false,
  color = "default",
  type = "button",
  variant = "outlined",
  fullWidth = false,
  disabled = false,
  startIcon = null,
  endIcon = null,
  icon = null,
  iconColor = "primary",
  hoverColor = null,
  borderWidth = null,
  size,
  className = "",
  style,
  onClick,
}) => {
  const classes = useStyles({ fullWidth, iconColor, hoverColor, borderWidth });

  return (
    <div className={classes.wrapper}>
      <Button
        type={type}
        color={color}
        variant={variant}
        fullWidth={fullWidth}
        disabled={disabled || loading}
        startIcon={icon || startIcon}
        endIcon={endIcon}
        size={size}
        onClick={onClick}
        className={`
            ${className} 
            ${hoverColor ? classes.buttonHover : ""} 
            ${borderWidth ? classes.buttonBorderWidth : ""}
        `}
        classes={{
          root: classes.button,
          startIcon: icon ? classes.iconOnly : "",
        }}
        style={style}
      >
        {label}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonLoading} />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    display: (props: any) => (props.fullWidth ? "block" : "inline-block"),
  },
  buttonLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    "& .MuiSvgIcon-root": {
      color: (props: any) => {
        switch (props.iconColor) {
          case "primary":
            return theme.palette.primary.main;
          case "secondary":
            return theme.palette.secondary.main;
          default:
            return theme.palette.action.active;
        }
      },
    },
  },
  buttonBorderWidth: {
    borderWidth: (props: any) => props.borderWidth,
  },
  buttonHover: {
    transition: "border 0s",
    "&:hover": {
      borderColor: (props: any) => props.hoverColor,
    },
    "&.selected": {
      backgroundColor: (props: any) => props.hoverColor,
      borderColor: (props: any) => props.hoverColor,
    },
    "&:hover .MuiSvgIcon-root": {
      color: (props: any) => props.hoverColor,
    },
    "&.selected .MuiSvgIcon-root": {
      color: "#fff !important",
    },
    "&:hover.Mui-disabled": {
      backgroundColor: (props: any) => `${props.hoverColor} !important`,
    },
  },
  iconOnly: {
    marginLeft: 0,
    marginRight: 0,
  },
}));

export default CommonButton;
