import * as React from "react";

import {
  Aligner,
  ButtonBar,
  CommonButton,
  ContainerLarge,
  CostCalculationLines,
  CostLines,
  CostList,
  HeaderNormal,
  HideOnMobile,
  ItemStatusBar,
  LoadingScreen,
  MobileNavigationBar,
  MobileOnly,
  Paragraph,
  RemarksDialog,
  SectionBox,
  useCostsItemUsecases,
  useDialog,
} from "../../../../presentation";
import { CostsItemStatus, ItemType, Result } from "../../../../application";
import { Paper, Tab, Tabs, makeStyles } from "@material-ui/core";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import AddIcon from "@material-ui/icons/AddCircleOutline";
import AddMobileIcon from "@material-ui/icons/AddCircleOutline";
import BackIcon from "@material-ui/icons/ArrowBack";
import LockedIcon from "@material-ui/icons/LockOutlined";
import NotPaidIcon from "@material-ui/icons/Done";
import PaidIcon from "@material-ui/icons/DoneAll";
import UnlockedIcon from "@material-ui/icons/LockOpenOutlined";

export interface CostsItemPageProps {}

interface RouterParams {
  topicId: string;
  costsItemId: string;
}

export const CostsItemPage: React.FC<CostsItemPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // ROUTER
  const params = useParams<RouterParams>();
  const { push } = useHistory();
  const { url } = useRouteMatch();

  // USECASES
  const [
    { costsItem, summary, loading, reasons, remarks },
    {
      getCostsItem,
      removeCost,
      updateStatus,
      getCostAndCalculationSummary,
      getRemarks,
      addRemark,
      removeRemark,
    },
  ] = useCostsItemUsecases();

  // STATE
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [remarksParentId, setRemarksParentId] = React.useState<number>(0);

  // DIALOGS
  const [
    { isOpen: isRemarksDialogOpen },
    { open: openRemarksDialog, close: closeRemarksDialog },
  ] = useDialog();

  // VARIABLES
  const topicId = parseInt(params.topicId);
  const costsItemId = parseInt(params.costsItemId);
  const url_Back = `/topics/${topicId}`;
  const url_NewCostPage = `${url}/costs/new`;
  const url_UpdateCostPage = (costId: number) =>
    `${url}/costs/${costId}/update`;
  const url_RemarksPage = (costId: number) => `${url}/costs/${costId}/remarks`;

  React.useEffect(() => {
    loadItem();
  }, []);

  React.useEffect(() => {
    if (selectedTab === 1) {
      loadSummary();
    }
  }, [selectedTab]);

  // METHODS
  const loadItem = async () => {
    if (!costsItemId) return;

    await getCostsItem(costsItemId);
  };

  const loadSummary = async () => {
    await getCostAndCalculationSummary(costsItemId);
  };

  const handleNavigationBack = () => {
    push(url_Back);
  };

  const handleNavigationToNewCostPage = () => {
    push(url_NewCostPage);
  };

  const handleNavigationToUpdateCostPage = (costId: number) => {
    const url = url_UpdateCostPage(costId);

    push(url);
  };

  const handleOpenRemarks = async (id: number) => {
    await getRemarks(id);
    setRemarksParentId(id);
    openRemarksDialog();
  };

  const handleRemarksSend = async (message: string): Promise<Result> => {
    const result = await addRemark(remarksParentId, message);

    if (result.isSuccess) await getRemarks(remarksParentId);

    return result;
  };

  const handleRemarksDelete = async (remarkId: number) => {
    const result = await removeRemark(remarkId);

    if (result.isSuccess) await getRemarks(remarksParentId);
  };

  const handleRemarksClose = () => {
    loadItem();
    closeRemarksDialog();
  };

  const handleTabSelect = (event: any, newTabIndex: number) => {
    setSelectedTab(newTabIndex);
  };

  const handleRemove = async (costId: number) => {
    const result = await removeCost(costId);

    if (result.isSuccess) loadItem();
  };

  const handleChangeStatus = async (itemStatus: CostsItemStatus) => {
    const result = await updateStatus(costsItemId, itemStatus);

    if (result.isSuccess) loadItem();
  };

  // UI RENDER METHODS
  const renderStatusDefinitiveButton = (itemStatus: CostsItemStatus) => {
    switch (itemStatus) {
      case CostsItemStatus.Open:
        return (
          <CommonButton
            label="Maak definitief"
            loading={loading}
            startIcon={<UnlockedIcon />}
            onClick={() => handleChangeStatus(CostsItemStatus.Definitive)}
          />
        );
      case CostsItemStatus.Definitive:
        return (
          <CommonButton
            label="Definitief"
            loading={loading}
            color="primary"
            startIcon={<LockedIcon />}
            onClick={() => handleChangeStatus(CostsItemStatus.Open)}
          />
        );
      case CostsItemStatus.AllPaid:
        return null;
    }
  };

  const renderStatusPaidButton = (itemStatus: CostsItemStatus) => {
    switch (itemStatus) {
      case CostsItemStatus.Definitive:
        return (
          <CommonButton
            label="Afronden"
            startIcon={<NotPaidIcon />}
            onClick={() => handleChangeStatus(CostsItemStatus.AllPaid)}
          />
        );
      case CostsItemStatus.AllPaid:
        return (
          <CommonButton
            label="Afgerond"
            color="primary"
            startIcon={<PaidIcon />}
            onClick={() => handleChangeStatus(CostsItemStatus.Definitive)}
          />
        );
      case CostsItemStatus.Open:
        return null;
    }
  };

  return (
    <ContainerLarge>
      {costsItem && (
        <ItemStatusBar itemType={ItemType.Costs} done={costsItem.done} />
      )}

      <MobileOnly>
        <HeaderNormal
          title="Kosten"
          disableGutter
          style={{ margin: "8px 0" }}
        />
      </MobileOnly>

      <HideOnMobile>
        <SectionBox>
          <CommonButton
            label="Naar overzicht"
            startIcon={<BackIcon />}
            variant="text"
            onClick={handleNavigationBack}
          />
        </SectionBox>
      </HideOnMobile>

      <Paper elevation={3}>
        <Tabs value={selectedTab} onChange={handleTabSelect}>
          <Tab label="Kosten opvoeren" />
          <Tab label="Verrekenoverzicht" />
        </Tabs>

        {selectedTab === 0 && (
          <div className={classes.tabContent}>
            <HideOnMobile>
              <SectionBox>
                <ButtonBar>
                  <CommonButton
                    label="Toevoegen"
                    disabled={
                      !costsItem ||
                      (costsItem &&
                        costsItem.itemStatus != CostsItemStatus.Open)
                    }
                    startIcon={<AddIcon />}
                    onClick={handleNavigationToNewCostPage}
                  />

                  {costsItem &&
                    costsItem.costs.length > 0 &&
                    renderStatusDefinitiveButton(costsItem.itemStatus)}
                </ButtonBar>
              </SectionBox>
            </HideOnMobile>

            <MobileOnly>
              <SectionBox>
                <Aligner horizontal>
                  {costsItem &&
                    renderStatusDefinitiveButton(costsItem.itemStatus)}
                </Aligner>
              </SectionBox>
            </MobileOnly>

            <SectionBox>
              {loading && !costsItem && <LoadingScreen />}
              {costsItem && (
                <CostList
                  costs={costsItem.costs}
                  blocked={costsItem.itemStatus != CostsItemStatus.Open}
                  onOpenRemarks={handleOpenRemarks}
                  onEdit={handleNavigationToUpdateCostPage}
                  onRemove={handleRemove}
                />
              )}

              {costsItem && costsItem.costs.length === 0 && (
                <div style={{ textAlign: "center" }}>
                  <Paragraph>Er zijn nog geen kosten opgevoerd.</Paragraph>
                </div>
              )}
            </SectionBox>

            <MobileNavigationBar>
              {() => ({
                buttons: [
                  {
                    icon: <AddMobileIcon />,
                    disabled:
                      !costsItem ||
                      (costsItem &&
                        costsItem.itemStatus != CostsItemStatus.Open),
                    onClick: handleNavigationToNewCostPage,
                  },
                  { icon: <BackIcon />, onClick: handleNavigationBack },
                ],
              })}
            </MobileNavigationBar>
          </div>
        )}
        {selectedTab === 1 && (
          <div className={classes.tabContent}>
            <div>
              {summary && (
                <>
                  <CostLines costLines={summary.costSummary.costLines} />
                  <CostCalculationLines
                    calculationLines={
                      summary.calculationSummary.calculationLines
                    }
                  />
                </>
              )}
            </div>
          </div>
        )}
      </Paper>

      <RemarksDialog
        open={isRemarksDialogOpen}
        subject={remarks?.subject}
        remarks={remarks?.remarks}
        onClose={handleRemarksClose}
        onSend={handleRemarksSend}
        onDelete={handleRemarksDelete}
      />
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  tabContent: { padding: "0 16px" },
}));

export default CostsItemPage;
