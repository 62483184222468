import * as React from "react";

import {
  ButtonBar,
  CommonButton,
  CommonPaper,
  ContainerLarge,
  FixedBottomElement,
  HeaderNormal,
  HideOnMobile,
  ItemStatusBar,
  LoadingScreen,
  MobileNavigationBar,
  MobileOnly,
  OpenActionsReasonsBox,
  Paragraph,
  RemarksDialog,
  SectionBox,
  TaskList,
  TitleWithCloseButton,
  useDialog,
  useTasksItemUsecases,
} from "../../../../presentation";
import { ItemType, Result } from "../../../../application";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import AddIcon from "@material-ui/icons/AddCircle";
import AddMobileIcon from "@material-ui/icons/AddCircleOutline";
import BackIcon from "@material-ui/icons/ArrowBack";

export interface TasksItemPageProps {}

interface RouterParams {
  topicId: string;
  tasksItemId: string;
}

export const TasksItemPage: React.FC<TasksItemPageProps> = () => {
  // STATE
  const [remarksParentId, setRemarksParentId] = React.useState<number>(0);

  // ROUTER
  const params = useParams<RouterParams>();
  const { push } = useHistory();
  const { url } = useRouteMatch();

  // USECASES
  const [
    { tasksItem, loading, reasons, remarks },
    {
      getTasksItem,
      removeTask,
      updateTaskStatus,
      getTaskRemarks,
      addTaskRemark,
      removeTaskRemark,
    },
  ] = useTasksItemUsecases();

  // DIALOGS
  const [
    { isOpen: isRemarksDialogOpen },
    { open: openRemarksDialog, close: closeRemarksDialog },
  ] = useDialog();

  // VARIABLES
  const topicId = parseInt(params.topicId);
  const tasksItemId = parseInt(params.tasksItemId);
  const url_Back = `/topics/${topicId}`;
  const url_NewTaskPage = `${url}/tasks/new`;
  const url_UpdateTaskPage = (taskId: number) =>
    `${url}/tasks/${taskId}/update`;
  const url_RemarksPage = (taskId: number) => `${url}/tasks/${taskId}/remarks`;

  React.useEffect(() => {
    loadItem();
  }, []);

  // METHODS
  const loadItem = async () => {
    if (!tasksItemId) return;

    getTasksItem(tasksItemId);
  };

  const handleNavigationBack = () => {
    push(url_Back);
  };

  const handleNavigationToNewTaskPage = () => {
    push(url_NewTaskPage);
  };

  const handleNavigationToUpdateTaskPage = (taskId: number) => {
    const url = url_UpdateTaskPage(taskId);

    push(url);
  };

  const handleOpenRemarks = async (id: number) => {
    await getTaskRemarks(id);
    setRemarksParentId(id);
    openRemarksDialog();
  };

  const handleRemarksSend = async (message: string): Promise<Result> => {
    const result = await addTaskRemark(remarksParentId, message);

    if (result.isSuccess) await getTaskRemarks(remarksParentId);

    return result;
  };

  const handleRemarksDelete = async (remarkId: number) => {
    const result = await removeTaskRemark(remarkId);

    if (result.isSuccess) await getTaskRemarks(remarksParentId);
  };

  const handleRemarksClose = () => {
    loadItem();
    closeRemarksDialog();
  };

  const handleRemove = async (taskId: number): Promise<Result> => {
    const result = await removeTask(taskId);

    if (result.isSuccess) loadItem();

    return result;
  };

  const handleStatusChange = async (
    taskId: number,
    completed: boolean
  ): Promise<Result> => {
    const result = await updateTaskStatus(taskId, completed);

    if (result.isSuccess) loadItem();

    return result;
  };

  return (
    <ContainerLarge>
      {tasksItem && (
        <ItemStatusBar itemType={ItemType.Tasks} done={tasksItem.done} />
      )}

      <MobileOnly>
        <HeaderNormal title="Taken" disableGutter style={{ margin: "8px 0" }} />
      </MobileOnly>

      <HideOnMobile>
        <SectionBox>
          <CommonButton
            label="Naar overzicht"
            startIcon={<BackIcon />}
            variant="text"
            onClick={handleNavigationBack}
          />
        </SectionBox>

        <SectionBox>
          <HeaderNormal title="Taken" />
          <Paragraph>
            Moet er werk worden verzet? Voeg hier taken toe en koppel deze aan
            de juiste gebruikers.
          </Paragraph>
        </SectionBox>
      </HideOnMobile>

      {tasksItem && tasksItem.hasOpenActions && (
        <SectionBox>
          <OpenActionsReasonsBox
            reasons={[
              "Er zijn nog onafgeronde taken op jouw naam",
              "Er zijn nog niet toegewezen taken",
            ]}
          />
        </SectionBox>
      )}

      <CommonPaper>
        <HideOnMobile>
          <SectionBox>
            <ButtonBar>
              <CommonButton
                label="Toevoegen"
                startIcon={<AddIcon />}
                onClick={handleNavigationToNewTaskPage}
              />
            </ButtonBar>
          </SectionBox>
        </HideOnMobile>

        {loading && !tasksItem && <LoadingScreen />}
        {tasksItem && tasksItem.tasks.length > 0 && (
          <SectionBox>
            <TaskList
              tasks={tasksItem.tasks}
              onOpenRemarks={handleOpenRemarks}
              onEdit={handleNavigationToUpdateTaskPage}
              onRemove={handleRemove}
              onStatusChange={handleStatusChange}
            />
          </SectionBox>
        )}

        {tasksItem && tasksItem.tasks.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <Paragraph>Er zijn nog geen taken toegevoegd.</Paragraph>
          </div>
        )}
      </CommonPaper>

      <MobileNavigationBar>
        {() => ({
          buttons: [
            {
              icon: <AddMobileIcon />,
              onClick: handleNavigationToNewTaskPage,
            },
            { icon: <BackIcon />, onClick: handleNavigationBack },
          ],
        })}
      </MobileNavigationBar>

      <RemarksDialog
        open={isRemarksDialogOpen}
        subject={remarks?.subject}
        remarks={remarks?.remarks}
        onClose={handleRemarksClose}
        onSend={handleRemarksSend}
        onDelete={handleRemarksDelete}
      />
    </ContainerLarge>
  );
};

export default TasksItemPage;
