import * as React from "react";

import {
  ButtonBar,
  CommonButton,
  ContainerLarge,
  GenericTextField,
  HeaderNormal,
  LoadingScreen,
  ReasonList,
  SectionBox,
  useQuestionsItemUsecases,
} from "../../../../presentation";
import { useHistory, useParams } from "react-router-dom";

import { QuestionModel } from "../../../../application";
import { makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";

export interface UpdateQuestionPageProps {}

interface RouteParams {
  topicId: string;
  questionsItemId: string;
  questionId: string;
}

export const UpdateQuestionPage: React.FC<UpdateQuestionPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // ROUTER
  const params = useParams<RouteParams>();
  const { push } = useHistory();

  // USECASES
  const [
    { questionsItem, loading, reasons },
    { getQuestionsItem, updateQuestion },
  ] = useQuestionsItemUsecases();

  // STATE
  const [question, setQuestion] = React.useState<QuestionModel | null>(null);

  // VARIABLES
  const topicId = parseInt(params.topicId);
  const questionsItemId = parseInt(params.questionsItemId);
  const questionId = parseInt(params.questionId);
  const url_Back = `/topics/${topicId}/questions-items/${questionsItemId}`;
  const { register, handleSubmit, errors } = useForm();

  React.useEffect(() => {
    loadQuestion();
  }, []);

  // METHODS
  const loadQuestion = async () => {
    const result = await getQuestionsItem(questionsItemId);

    if (result.isSuccess && result.value) {
      const question = result.value.questions.find((q) => q.id === questionId);

      if (question) setQuestion(question);
    }
  };

  const handleNavigationBack = () => {
    push(url_Back);
  };

  const handleActualSubmit = async (fieldValues: any) => {
    const result = await updateQuestion(questionId, fieldValues.description);

    if (result.isSuccess) handleNavigationBack();
  };

  return (
    <ContainerLarge>
      <SectionBox>
        <HeaderNormal title="Vraag aanpassen" />
      </SectionBox>

      {loading && !questionsItem && <LoadingScreen />}
      {questionsItem && (
        <SectionBox>
          <div className={classes.formWrapper}>
            {question && (
              <form
                autoComplete="off"
                onSubmit={handleSubmit(handleActualSubmit)}
              >
                <div>
                  <GenericTextField
                    displayLabel="Omschrijving *"
                    fieldName="description"
                    inputRef={register({
                      required: true,
                    })}
                    defaultValue={question.description}
                  />
                </div>

                <SectionBox>
                  <ButtonBar>
                    <CommonButton
                      label="Annuleren"
                      onClick={handleNavigationBack}
                    />
                    <CommonButton
                      color="primary"
                      label="Opslaan"
                      loading={loading}
                      type="submit"
                      variant="contained"
                    />
                  </ButtonBar>
                </SectionBox>
              </form>
            )}

            {reasons && (
              <SectionBox>
                <ReasonList reasons={reasons} />
              </SectionBox>
            )}
          </div>
        </SectionBox>
      )}
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    maxWidth: 600,
  },
}));

export default UpdateQuestionPage;
