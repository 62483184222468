import * as React from "react";

import { Typography, makeStyles } from "@material-ui/core";

export interface HeaderExtraSmallProps {
  title: string | React.ReactNode;
  color?: "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
  className?: string;
  style?: React.CSSProperties;
}

export const HeaderExtraSmall: React.FC<HeaderExtraSmallProps> = ({
  title,
  color = "textPrimary",
  className,
  style,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <Typography
      color={color}
      variant="h6"
      className={`${className} ${classes.title}`}
      style={style}
    >
      {title}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 400,
  },
}));

export default HeaderExtraSmall;
