import * as React from "react";

import { Typography, makeStyles } from "@material-ui/core";

export interface TextProps {
  color?: "primary" | "secondary" | "textPrimary" | "textSecondary";
  style?: React.CSSProperties;
  className?: string;
}

export const Text: React.FC<TextProps> = ({
  children,
  color = "textPrimary",
  style,
  className,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <Typography
      color={color}
      style={style}
      variant="body2"
      className={`${classes.text} ${className}`}
    >
      {children}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  text: {
    wordBreak: "break-word",
  },
}));

export default Text;
