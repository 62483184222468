import {
  ContainerLarge,
  HeaderExtraSmall,
  HeaderLarge,
  HeaderMedium,
  Paragraph,
  SectionBlock,
  SectionBox,
} from "../components";
import { Grid, makeStyles } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";

import ImageAppStore from "../assets/images/app-store-badge.svg";
import ImageGooglePlay from "../assets/images/google-play-badge.png";
import React from "react";

export const RedirectPage = () => {
  const styles = useStyles();
  const { search } = useLocation();

  React.useEffect(() => {
    if (search) {
      const queryParams = new URLSearchParams(search);
      const url = queryParams.get("url");

      if (url) {
        window.location.replace(url);
      }
    }
  }, []);

  return (
    <div className={styles.overlay}>
      <ContainerLarge>
        <SectionBlock>
          <SectionBox>
            <div style={{ textAlign: "center" }}>
              <SectionBox>
                <HeaderMedium title="Download de Plokkie App" />
              </SectionBox>
            </div>
            <Grid container justify="center" alignItems="center">
              {/* <Grid item xs={12} md={6}> */}
              {/* <div style={{ textAlign: "center" }}>
                  <SectionBox>
                    <HeaderMedium title="Download de Plokkie App" />
                  </SectionBox>
                </div> */}
              {/* </Grid> */}
              <Grid item xs={12} md={6}>
                <div style={{ textAlign: "center" }}>
                  <SectionBox>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.spijls.plokkie"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", maxWidth: 200 }}
                        src={ImageGooglePlay}
                      />
                    </a>
                  </SectionBox>
                  <SectionBox>
                    <HeaderExtraSmall title="of" />
                  </SectionBox>
                  <SectionBox>
                    <a
                      href="https://apps.apple.com/us/app/plokkie/id6463428580"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", maxWidth: 200 }}
                        src={ImageAppStore}
                      />
                    </a>
                  </SectionBox>
                </div>
              </Grid>
            </Grid>
          </SectionBox>

          <SectionBox>
            <div style={{ textAlign: "center" }}>
              <HeaderExtraSmall title="Tip!" />
              <Paragraph>
                Heb je een uitnodigingslink gekregen, open deze dan opnieuw
                nadat je de App hebt geïnstalleerd.
              </Paragraph>
            </div>
          </SectionBox>
        </SectionBlock>
      </ContainerLarge>
    </div>
  );
};

const useStyles = makeStyles({
  overlay: {
    position: "fixed",
    zIndex: 1500,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
  },
});

interface RouteParams {
  redirectUrl: string;
}
