import * as React from "react";

import {
  CommonIconButton,
  GenericTextField,
} from "../../../../../presentation";
import { makeStyles, useTheme } from "@material-ui/core";

import { Result } from "../../../../../application";
import SendIcon from "@material-ui/icons/Send";

export interface AnswerInputProps {
  onSend(description: string): Promise<Result>;
}

export const AnswerInput: React.FC<AnswerInputProps> = ({ onSend }) => {
  // STYLES
  const classes = useStyles();
  const theme = useTheme();

  // STATE
  const [description, setDescription] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);

  // METHODS
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const message = description;
    if (!message) return;

    setLoading(true);
    const result = await onSend(message);
    setLoading(false);

    if (result.isSuccess) setDescription("");
  };
  return (
    <div className={classes.inputWrapper}>
      <form
        autoComplete="off"
        onSubmit={(e) => handleSubmit(e)}
        className={classes.form}
      >
        <div className={classes.inputContainer}>
          <GenericTextField
            displayLabel="Antwoord *"
            fieldName="description"
            errorMessage={null}
            value={description}
            onChange={handleChange}
          />
        </div>
        <div className={classes.buttonContainer}>
          <CommonIconButton
            disabled={!description}
            icon={<SendIcon />}
            loading={loading}
            type="submit"
            className={classes.button}
          />
        </div>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: "flex",
  },
  form: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  inputContainer: {
    flexGrow: 1,
  },
  buttonContainer: {},
  button: {
    color: theme.palette.primary.main,
    top: 3,
    "& .MuiSvgIcon-root": {
      fontSize: "2.5rem",
    },
  },
}));

export default AnswerInput;
