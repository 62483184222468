import {
  AddLocationPropositionRequest,
  AddRemarkRequest,
  LocationItemModel,
  LocationItemRepository,
  LocationPropositionRemarksModel,
  Result,
  UpdateLocationPropositionRequest,
  VoteOnLocationPropositionRequest,
} from "../../../application";
import { get, post, put, remove } from "../../../infrastructure";

export class LocationItemRepositoryImpl implements LocationItemRepository {
  async getLocationItem(
    itemId: number
  ): Promise<Result<LocationItemModel> | Result<void>> {
    return await get<LocationItemModel>(`items/location/${itemId}`);
  }

  async addlocationProposition(
    locationItemId: number,
    request: AddLocationPropositionRequest
  ): Promise<Result<void>> {
    return await post(
      `location-items/${locationItemId}/propositions/add`,
      request
    );
  }
  async removeLocationProposition(
    propositionId: number
  ): Promise<Result<void>> {
    return await remove(`location-items/propositions/${propositionId}/remove`);
  }

  async updateLocationProposition(
    propositionId: number,
    request: UpdateLocationPropositionRequest
  ): Promise<Result<void>> {
    return await put(
      `location-items/propositions/${propositionId}/update`,
      request
    );
  }

  async vote(
    propositionId: number,
    request: VoteOnLocationPropositionRequest
  ): Promise<Result<void>> {
    return await put(
      `location-items/propositions/${propositionId}/vote`,
      request
    );
  }

  async getRemarks(
    propositionId: number
  ): Promise<Result<LocationPropositionRemarksModel> | Result<void>> {
    return await get<LocationPropositionRemarksModel>(
      `location-items/propositions/${propositionId}/remarks`
    );
  }

  async addRemark(
    propositionId: number,
    request: AddRemarkRequest
  ): Promise<Result<void>> {
    return await post(
      `location-items/propositions/${propositionId}/remarks/add`,
      request
    );
  }

  async removeRemark(remarkId: number): Promise<Result<void>> {
    return await remove(
      `location-items/propositions/remarks/${remarkId}/remove`
    );
  }

  async confirm(propositionId: number): Promise<Result<void>> {
    return await put(`location-items/propositions/${propositionId}/confirm`);
  }
}
