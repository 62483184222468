import * as React from "react";

import { ItemType } from "../../../../../application";
import { ItemTypeImage } from "../../../../../presentation";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  image: {
    position: "absolute",
    width: "65%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export interface ItemImageProps {
  itemType: ItemType;
}

export const ItemImage: React.FC<ItemImageProps> = ({ itemType }) => {
  const classes = useStyles();

  return <ItemTypeImage itemType={itemType} className={classes.image} />;
};

export default ItemImage;
