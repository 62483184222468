import * as React from "react";

import { INJECTION_TOKENS, QuestionsItemService } from "../../../application";

import { useDependencyInjectionService } from "../../../presentation";

export const useQuestionsItemService = (): [QuestionsItemService] => {
  const [containerSvc] = useDependencyInjectionService();
  const questionsItemSvc = containerSvc.getService<QuestionsItemService>(
    INJECTION_TOKENS.QuestionsItemService
  );

  return [questionsItemSvc];
};
