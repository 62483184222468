import * as React from "react";

import {
  ButtonBar,
  CommonButton,
  CommonSelect,
  ContainerLarge,
  GenericTextField,
  HeaderNormal,
  LoadingScreen,
  LoadingSpinner,
  ReasonList,
  SectionBox,
  useTasksItemUsecases,
  useTopicUsecases,
} from "../../../../presentation";
import { MenuItem, makeStyles } from "@material-ui/core";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { TaskModel } from "../../../../application";
import { useForm } from "react-hook-form";

export interface UpdateTaskPageProps {}

interface RouteParams {
  topicId: string;
  tasksItemId: string;
  taskId: string;
}

export const UpdateTaskPage: React.FC<UpdateTaskPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // ROUTER
  const params = useParams<RouteParams>();
  const { push } = useHistory();

  // USECASES
  const [{ members, loading: membersLoading }, { getMembers }] =
    useTopicUsecases();
  const [{ tasksItem, loading, reasons }, { getTasksItem, updateTask }] =
    useTasksItemUsecases();

  // STATE
  const [task, setTask] = React.useState<TaskModel | null>(null);
  const [selectedMember, setSelectedMember] = React.useState<number | string>(
    ""
  );

  // VARIABLES
  const topicId = parseInt(params.topicId);
  const tasksItemId = parseInt(params.tasksItemId);
  const taskId = parseInt(params.taskId);
  const url_Back = `/topics/${topicId}/tasks-items/${tasksItemId}`;
  const { register, handleSubmit, control, errors } = useForm();

  React.useEffect(() => {
    loadTask();
    loadMembers();
  }, []);

  React.useEffect(() => {
    if (!tasksItem) return;

    const task = tasksItem.tasks.find((t) => t.id === taskId);
    if (task) {
      setTask(task);
      setSelectedMember(task.assignedToMemberId || "");
    }
  }, [tasksItem]);

  // METHODS
  const loadTask = async () => {
    if (!tasksItemId || !taskId) return;
    await getTasksItem(tasksItemId);
  };

  const loadMembers = async () => {
    if (!topicId) return;
    await getMembers(topicId);
  };

  const handleNavigationBack = () => {
    push(url_Back);
  };

  const handleActualSubmit = async (fieldValues: any) => {
    const assignedTo = [];

    if (fieldValues.assignedTo) assignedTo.push(fieldValues.assignedTo);

    const result = await updateTask(
      taskId,
      fieldValues.description,
      assignedTo
    );

    if (result.isSuccess) handleNavigationBack();
  };

  return (
    <ContainerLarge>
      <SectionBox>
        <HeaderNormal title="Taak aanpassen" />
      </SectionBox>

      {loading && !task && <LoadingScreen />}
      {task && (
        <SectionBox>
          <div className={classes.formWrapper}>
            <form
              autoComplete="off"
              onSubmit={handleSubmit(handleActualSubmit)}
            >
              <div>
                <GenericTextField
                  displayLabel="Omschrijving *"
                  fieldName="description"
                  inputRef={register({
                    required: true,
                    minLength: 3,
                    maxLength: 240,
                  })}
                  defaultValue={task.description}
                />
              </div>
              <div>
                <CommonSelect
                  label="Toewijzen aan"
                  name="assignedTo"
                  defaultValue={task.assignedToMemberId}
                  control={control}
                >
                  <MenuItem value="">(Leeg)</MenuItem>
                  {members &&
                    members.map((m) => (
                      <MenuItem key={m.id} value={m.id}>
                        {m.username}
                      </MenuItem>
                    ))}
                </CommonSelect>
              </div>

              <SectionBox>
                <ButtonBar>
                  <CommonButton
                    label="Annuleren"
                    onClick={handleNavigationBack}
                  />
                  <CommonButton
                    color="primary"
                    label="Opslaan"
                    loading={loading}
                    type="submit"
                    variant="contained"
                  />
                </ButtonBar>
              </SectionBox>
            </form>
            {reasons && (
              <SectionBox>
                <ReasonList reasons={reasons} />
              </SectionBox>
            )}
          </div>
        </SectionBox>
      )}
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    maxWidth: 600,
  },
}));

export default UpdateTaskPage;
