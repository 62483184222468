import * as React from "react";

interface HookState {
  isOpen: boolean;
}

interface HookMethods {
  open(): void;
  close(): void;
}

export const useDialog = (): [HookState, HookMethods] => {
  const [isOpen, setIsOpen] = React.useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return [{ isOpen }, { open, close }];
};
