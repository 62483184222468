import * as React from "react";

import {
  CommonIconButton,
  CommonMenu,
  ConfirmationDialog,
  LoadingBackdrop,
  Paragraph,
  Text,
} from "../../../../../../presentation";

import { DateUtils } from "../../../../../../application";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import MoreOptionsIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core";

export interface RemarkProps {
  remarkId: number;
  username: string;
  message: string;
  createdOn: string;
  notSeen: boolean;
  highlight?: boolean;
  onDelete(remarkId: number): Promise<void>;
}

export const Remark: React.FC<RemarkProps> = ({
  remarkId,
  username,
  message,
  createdOn,
  notSeen,
  highlight = false,
  onDelete,
}) => {
  // STYLES
  const classes = useStyles();

  // STATE
  const [loading, setLoading] = React.useState(false);

  // METHODS
  const onDeleteWrapper = async (remarkId: number) => {
    setLoading(true);
    await onDelete(remarkId);
    setLoading(false);
  };

  return (
    <div
      className={`
            ${classes.remark}
            ${highlight ? classes.remarkHighlight : ""}
        `}
    >
      <LoadingBackdrop show={loading} size={25} />
      <div className={classes.remarkContentWrapper}>
        <div className={classes.remarkContent}>
          <div className={classes.remarkContentHeader}>
            <div className={classes.remarkContentHeaderUsername}>
              <Paragraph style={{ fontWeight: "bold" }}>{username}</Paragraph>
            </div>
            <div className={classes.remarkContentHeaderDate}>
              <Text>{DateUtils.getShortDateTimeString(createdOn)}</Text>
            </div>
          </div>
          <div className={classes.remarkContentMessage}>
            <Text>{message}</Text>
          </div>
        </div>
        <div className={classes.remarkButtonWrapper}>
          <ConfirmationDialog
            title="Verwijderen"
            confirmButtonWarningColor
            onConfirm={() => onDeleteWrapper(remarkId)}
          >
            {({ open: openDialog }) => (
              <CommonMenu>
                {({ open }) => ({
                  button: (
                    <CommonIconButton
                      icon={<MoreOptionsIcon />}
                      onClick={open}
                      className={classes.remarkButton}
                    />
                  ),
                  items: [
                    {
                      label: "Verwijderen",
                      icon: <DeleteIcon />,
                      handleClick: openDialog,
                    },
                  ],
                })}
              </CommonMenu>
            )}
          </ConfirmationDialog>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  remark: {
    position: "relative",
    backgroundColor: "#fff",
    boxShadow: "5px 5px 10px -3px grey",
    padding: 10,
    paddingBottom: 2,
    borderRadius: 5,
    display: "inline-block",
    minWidth: 230,
    maxWidth: "75%",
  },
  remarkHighlight: {
    backgroundColor: theme.palette.primary.main,
    "& .MuiTypography-colorTextPrimary": {
      color: "#fff",
    },
  },
  remarkContentWrapper: {
    display: "flex",
  },
  remarkContent: {
    flexGrow: 1,
  },
  remarkContentHeader: {
    display: "flex",
    alignItems: "center",
  },
  remarkContentHeaderUsername: {
    flexGrow: 1,
    marginRight: 8,
  },
  remarkContentHeaderDate: {
    textAlign: "right",
  },
  remarkContentMessage: {},
  remarkButtonWrapper: {},
  remarkButton: {
    top: -10,
    right: -10,
  },
}));

export default Remark;
