import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

export const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#2AC940",
        contrastText: "#fff",
      },
      secondary: {
        main: "#016770",
      },
      text: {
        primary: "#34515e",
      },
    },
  })
);

// ORIGINAL: #2AC940
// #2AC93E
// #008F12
