import "./new-date-propositions.page.css";

import * as React from "react";

import {
  AddDatePropositionModel,
  AddDatePropositionsModel,
} from "../../../../../application";
import {
  ButtonBar,
  CommonButton,
  ContainerLarge,
  HeaderNormal,
  ReasonList,
  SectionBox,
  useDateItemUsecases,
} from "../../../../../presentation";
import { Calendar, DateObject } from "react-multi-date-picker";
import { TextField, makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  timeField: { width: 150 },
  calender: {
    [theme.breakpoints.only("xs")]: {
      width: "auto",
    },
  },
}));

export interface NewDatePropositionPageProps {}

interface RouteParams {
  topicId: string;
  dateItemId: string;
}

export const NewDatePropositionPage: React.FC<NewDatePropositionPageProps> =
  () => {
    // STYLING
    const classes = useStyles();

    // ROUTER
    const { push, replace } = useHistory();
    const routeParams = useParams<RouteParams>();

    // APPLICATION-LAYER
    const [state, methods] = useDateItemUsecases();
    const { loading, reasons } = state;
    const { addDatePropositions } = methods;

    // STATE
    const [selectedDates, setSelectedDates] = React.useState<Date[]>([]);
    const [startTime, setStartTime] = React.useState<string | null>(null);
    const [endTime, setEndTime] = React.useState<string | null>(null);

    // VARIABLES
    const backUrl = `/topics/${routeParams.topicId}/date-items/${routeParams.dateItemId}`;

    // METHODS
    const navigateBack = () => {
      push(backUrl);
    };

    const handleChange = (selectedDates: DateObject[]) => {
      setSelectedDates(selectedDates.map((sd) => sd.toDate()));
    };

    const handleChangeStartTime = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setStartTime(event.target.value);
    };

    const handleChangeEndTime = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setEndTime(event.target.value);
    };

    const handleSave = async () => {
      const dateItemId = parseInt(routeParams.dateItemId);
      const model = new AddDatePropositionsModel();

      // SET TIME
      model.startTime = startTime;
      model.endTime = endTime;

      // TRANSFORM SELECTED-DATES AND POSSIBLY TIME TO MODEL
      selectedDates.forEach((sd) => {
        const datePropositionModel = new AddDatePropositionModel(sd);
        model.datePropositions.push(datePropositionModel);
      });

      const result = await addDatePropositions(dateItemId, model);

      if (result.isSuccess) {
        // NAVIGATE BACK TO DATE-ITEM
        replace(backUrl);
      }
    };

    return (
      <ContainerLarge>
        <SectionBox>
          <HeaderNormal title="Datum(s) toevoegen" />
        </SectionBox>

        <SectionBox>
          <Calendar
            multiple
            value={selectedDates}
            weekDays={daysOfWeek}
            months={months}
            onChange={handleChange}
            className={classes.calender}
          />
        </SectionBox>

        <SectionBox>
          <TextField
            label="Starttijd (optioneel)"
            InputLabelProps={{ shrink: true }}
            type="time"
            variant="outlined"
            className={classes.timeField}
            style={{ marginRight: 10, marginBottom: 20 }}
            onChange={handleChangeStartTime}
          />
          <TextField
            label="Eindtijd (optioneel)"
            InputLabelProps={{ shrink: true }}
            type="time"
            variant="outlined"
            className={classes.timeField}
            onChange={handleChangeEndTime}
          />
        </SectionBox>

        <SectionBox>
          <ButtonBar>
            <CommonButton label="Annuleren" onClick={navigateBack} />
            <CommonButton
              label="Opslaan"
              color="primary"
              disabled={!selectedDates.length}
              loading={loading}
              variant="contained"
              onClick={handleSave}
            />
          </ButtonBar>
        </SectionBox>

        {reasons && (
          <SectionBox>
            <ReasonList reasons={reasons} />
          </SectionBox>
        )}
      </ContainerLarge>
    );
  };

const daysOfWeek = ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"];
const months = [
  "Januari",
  "Februari",
  "Maart",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Augustus",
  "September",
  "Oktober",
  "November",
  "December",
];

export default NewDatePropositionPage;
