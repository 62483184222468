import {
  RemarksModel,
  Result,
  TasksItemModel,
  TasksItemRepository,
} from "../../../application";
import { get, post, put, remove } from "../../../infrastructure";

export class TasksItemRepositoryImpl implements TasksItemRepository {
  async getTasksItem(
    itemId: number
  ): Promise<Result<TasksItemModel> | Result<void>> {
    return await get<TasksItemModel>(`items/tasks/${itemId}`);
  }

  async addTask(
    itemId: number,
    description: string,
    assignedTo: number[]
  ): Promise<Result<void>> {
    const request = { description, assignedTo };

    return await post(`tasks-items/${itemId}/tasks/add`, request);
  }

  async updateTask(
    taskId: number,
    description: string,
    assignedTo: number[]
  ): Promise<Result<void>> {
    const request = { description, assignedTo };

    return await put(`tasks-items/tasks/${taskId}/update`, request);
  }

  async removeTask(taskId: number): Promise<Result<void>> {
    return await remove(`tasks-items/tasks/${taskId}/remove`);
  }

  async getTaskRemarks(
    taskId: number
  ): Promise<Result<RemarksModel> | Result<void>> {
    return await get<RemarksModel>(`tasks-items/tasks/${taskId}/remarks`);
  }

  async addTaskRemark(taskId: number, text: string): Promise<Result<void>> {
    const request = { text };

    return await post(`tasks-items/tasks/${taskId}/remarks/add`, request);
  }

  async removeTaskRemark(remarkId: number): Promise<Result<void>> {
    return await remove(`tasks-items/tasks/remarks/${remarkId}/remove`);
  }

  async updateTaskStatus(
    taskId: number,
    completed: boolean
  ): Promise<Result<void>> {
    const request = { completed };

    return await put(`tasks-items/tasks/${taskId}/update-status`, request);
  }
}
