import * as React from "react";

import {
  ButtonBar,
  CommonButton,
  CommonPaper,
  ContainerLarge,
  HeaderNormal,
  HideOnMobile,
  ItemStatusBar,
  LoadingScreen,
  MobileNavigationBar,
  MobileOnly,
  OpenActionsReasonsBox,
  Paragraph,
  QuestionList,
  RemarksDialog,
  SectionBox,
  useDialog,
  useQuestionsItemUsecases,
  useTopicUsecases,
} from "../../../../presentation";
import { GenericVoteType, ItemType, Result } from "../../../../application";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import AddIcon from "@material-ui/icons/AddCircle";
import AddMobileIcon from "@material-ui/icons/AddCircleOutline";
import BackIcon from "@material-ui/icons/ArrowBack";

export interface QuestionsItemPageProps {}

interface RouterParams {
  topicId: string;
  questionsItemId: string;
}

export const QuestionsItemPage: React.FC<QuestionsItemPageProps> = () => {
  // ROUTER
  const params = useParams<RouterParams>();
  const { push } = useHistory();
  const { url } = useRouteMatch();

  // USECASES
  const [{ members }, { getMembers }] = useTopicUsecases();
  const [
    { questionsItem, loading, reasons, remarks },
    {
      getQuestionsItem,
      removeQuestion,
      voteOnAnswer,
      chooseAnswer,
      unChooseAnswer,
      addAnswer,
      removeAnswer,
      getQuestionRemarks,
      getAnswerRemarks,
      addQuestionRemark,
      addAnswerRemark,
      removeQuestionRemark,
      removeAnswerRemark,
    },
  ] = useQuestionsItemUsecases();

  // STATE
  const [totalUsers, setTotalUsers] = React.useState(0);
  const [remarksParentId, setRemarksParentId] = React.useState<number>(0);

  // DIALOGS
  const [
    { isOpen: isQuestionRemarksDialogOpen },
    { open: openQuestionRemarksDialog, close: closeQuestionRemarksDialog },
  ] = useDialog();
  const [
    { isOpen: isAnswerRemarksDialogOpen },
    { open: openAnswerRemarksDialog, close: closeAnswerRemarksDialog },
  ] = useDialog();

  // VARIABLES
  const topicId = parseInt(params.topicId);
  const questionsItemId = parseInt(params.questionsItemId);
  const url_Back = `/topics/${topicId}`;
  const url_NewQuestionPage = `${url}/questions/new`;
  const url_UpdateQuestionPage = (questionId: number) =>
    `${url}/questions/${questionId}/update`;
  const url_RemarksPage = (questionId: number) =>
    `${url}/questions/${questionId}/remarks`;
  const url_AnswerRemarksPage = (answerId: number) =>
    `${url}/questions/answers/${answerId}/remarks`;

  React.useEffect(() => {
    loadMembers();
    loadItem();
  }, []);

  React.useEffect(() => {
    if (!members) return;

    setTotalUsers(members.length);
  }, [members]);

  // METHODS
  const loadItem = async () => {
    if (!questionsItemId) return;

    await getQuestionsItem(questionsItemId);
  };

  const loadMembers = async () => {
    await getMembers(topicId);
  };

  const handleNavigationBack = () => {
    push(url_Back);
  };

  const handleNavigationToNewQuestionPage = () => {
    push(url_NewQuestionPage);
  };

  const handleNavigationToUpdateQuestionPage = (questionId: number) => {
    const url = url_UpdateQuestionPage(questionId);

    push(url);
  };

  const handleOpenQuestionRemarks = async (id: number) => {
    await getQuestionRemarks(id);
    setRemarksParentId(id);
    openQuestionRemarksDialog();
  };

  const handleQuestionRemarksSend = async (
    message: string
  ): Promise<Result> => {
    const result = await addQuestionRemark(remarksParentId, message);

    if (result.isSuccess) await getQuestionRemarks(remarksParentId);

    return result;
  };

  const handleQuestionRemarksDelete = async (remarkId: number) => {
    const result = await removeQuestionRemark(remarkId);

    if (result.isSuccess) await getQuestionRemarks(remarksParentId);
  };

  const handleQuestionRemarksClose = () => {
    loadItem();
    closeQuestionRemarksDialog();
  };

  const handleOpenAnswerRemarks = async (id: number) => {
    await getAnswerRemarks(id);
    setRemarksParentId(id);
    openAnswerRemarksDialog();
  };

  const handleAnswerRemarksSend = async (message: string): Promise<Result> => {
    const result = await addAnswerRemark(remarksParentId, message);

    if (result.isSuccess) await getAnswerRemarks(remarksParentId);

    return result;
  };

  const handleAnswerRemarksDelete = async (remarkId: number) => {
    const result = await removeAnswerRemark(remarkId);

    if (result.isSuccess) await getAnswerRemarks(remarksParentId);
  };

  const handleAnswerRemarksClose = () => {
    loadItem();
    closeAnswerRemarksDialog();
  };

  const handleRemove = async (questionId: number): Promise<Result> => {
    const result = await removeQuestion(questionId);

    if (result.isSuccess) loadItem();

    return result;
  };

  const handleLike = async (answerId: number): Promise<Result> => {
    const result = await voteOnAnswer(answerId, GenericVoteType.Yes);

    if (result.isSuccess) loadItem();

    return result;
  };

  const handleDislike = async (answerId: number): Promise<Result> => {
    const result = await voteOnAnswer(answerId, GenericVoteType.Maybe);

    if (result.isSuccess) loadItem();

    return result;
  };

  const handleVote = async (
    answerId: number,
    voteType: GenericVoteType
  ): Promise<Result> => {
    const result = await voteOnAnswer(answerId, voteType);

    if (result.isSuccess) loadItem();

    return result;
  };

  const handleChoose = async (answerId: number): Promise<Result> => {
    const result = await chooseAnswer(answerId);

    if (result.isSuccess) loadItem();

    return result;
  };

  const handleUnChoose = async (answerId: number): Promise<Result> => {
    const result = await unChooseAnswer(answerId);

    if (result.isSuccess) loadItem();

    return result;
  };

  const handleSend = async (questionId: number, description: string) => {
    const result = await addAnswer(questionId, description);

    if (result.isSuccess) loadItem();

    return result;
  };

  const handleRemoveAnswer = async (answerId: number) => {
    const result = await removeAnswer(answerId);

    if (result.isSuccess) loadItem();

    return result;
  };

  return (
    <ContainerLarge>
      {questionsItem && (
        <ItemStatusBar
          itemType={ItemType.Questions}
          done={questionsItem.done}
        />
      )}

      <MobileOnly>
        <HeaderNormal
          title="Vragen"
          disableGutter
          style={{ margin: "8px 0" }}
        />
      </MobileOnly>

      <HideOnMobile>
        <SectionBox>
          <CommonButton
            label="Naar overzicht"
            startIcon={<BackIcon />}
            variant="text"
            onClick={handleNavigationBack}
          />
        </SectionBox>

        <SectionBox>
          <HeaderNormal title="Vragen" />
          <Paragraph>
            Heb je een vraag richting de groep, stel deze dan hier.
          </Paragraph>
        </SectionBox>
      </HideOnMobile>

      {questionsItem && questionsItem.hasOpenActions && (
        <SectionBox>
          <OpenActionsReasonsBox
            reasons={["Er zijn antwoorden waar jij niet op hebt gestemd"]}
          />
        </SectionBox>
      )}

      <CommonPaper>
        <HideOnMobile>
          <SectionBox>
            <ButtonBar>
              <CommonButton
                label="Toevoegen"
                startIcon={<AddIcon />}
                onClick={handleNavigationToNewQuestionPage}
              />
            </ButtonBar>
          </SectionBox>
        </HideOnMobile>

        {loading && !questionsItem && <LoadingScreen />}
        {questionsItem && questionsItem.questions.length > 0 && (
          <SectionBox>
            <QuestionList
              questions={questionsItem.questions}
              totalUsers={totalUsers}
              onOpenRemarks={handleOpenQuestionRemarks}
              onOpenAnswerRemarks={handleOpenAnswerRemarks}
              onRemove={handleRemove}
              onEdit={handleNavigationToUpdateQuestionPage}
              onRemoveAnswer={handleRemoveAnswer}
              onLike={handleLike}
              onDislike={handleDislike}
              onVote={handleVote}
              onChoose={handleChoose}
              onUnChoose={handleUnChoose}
              onSend={handleSend}
            />
          </SectionBox>
        )}

        {questionsItem && questionsItem.questions.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <Paragraph>Er zijn nog geen vragen toegevoegd.</Paragraph>
          </div>
        )}
      </CommonPaper>

      <MobileNavigationBar>
        {() => ({
          buttons: [
            {
              icon: <AddMobileIcon />,
              onClick: handleNavigationToNewQuestionPage,
            },
            { icon: <BackIcon />, onClick: handleNavigationBack },
          ],
        })}
      </MobileNavigationBar>

      <RemarksDialog
        open={isQuestionRemarksDialogOpen}
        subject={remarks?.subject}
        remarks={remarks?.remarks}
        onClose={handleQuestionRemarksClose}
        onSend={handleQuestionRemarksSend}
        onDelete={handleQuestionRemarksDelete}
      />

      <RemarksDialog
        open={isAnswerRemarksDialogOpen}
        subject={remarks?.subject}
        remarks={remarks?.remarks}
        onClose={handleAnswerRemarksClose}
        onSend={handleAnswerRemarksSend}
        onDelete={handleAnswerRemarksDelete}
      />
    </ContainerLarge>
  );
};

export default QuestionsItemPage;
