import * as React from "react";

export interface AlignerProps {
  horizontal?: boolean;
}

export const Aligner: React.FC<AlignerProps> = ({ children, horizontal }) => {
  const styles: React.CSSProperties = {};

  if (horizontal) styles.textAlign = "center";

  return <div style={styles}>{children}</div>;
};

export default Aligner;
