import * as React from "react";

import { Remark, RemarkInput, RemarkList } from "../../";
import { RemarkModel, Result } from "../../../../../../application";

import { makeStyles } from "@material-ui/core";

// MATERIAL-UI HOOKS

// APPLICATION

// FEATURE COMPONENTS

export interface RemarkScreenProps {
  currentUserId?: number;
  remarks: RemarkModel[];
  onSend(message: string): Promise<Result>;
  onDelete(remarkId: number): Promise<void>;
}

export const RemarkScreen: React.FC<RemarkScreenProps> = ({
  currentUserId,
  remarks,
  onSend,
  onDelete,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div className={classes.remarkScreen}>
      <div className={classes.remarkBackground}></div>
      <div className={classes.remarkScreenList}>
        <RemarkList
          remarks={remarks}
          currentUserId={currentUserId}
          onDelete={onDelete}
        />
      </div>
      <div className={classes.remarkScreenInput}>
        <RemarkInput onSend={onSend} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  remarkScreen: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.only("xs")]: {
      marginLeft: -16,
      marginRight: -16,
    },
  },
  remarkScreenList: {
    flexGrow: 1,
    overflowY: "auto",
    zIndex: 0,
  },
  remarkScreenInput: {
    [theme.breakpoints.only("xs")]: {
      padding: "0 10px",
    },
  },
  remarkBackground: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "#E8E8E8",
  },
}));

export default RemarkScreen;
