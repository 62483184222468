import * as React from "react";

import {
  CommonButton,
  CommonSnackbar,
  ContainerLarge,
  HeaderLarge,
  HeaderSmall,
  LoadingScreen,
  SectionBox,
  SectionSmall,
  UserContext,
  useOpenClose,
  useUserService,
} from "../../../presentation";
import { FormControlLabel, Switch, makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import { UserPreferencesModel } from "../../../application";

export interface UserSettingsPageProps {}

interface RouteParams {
  userIdParam: string;
}

export const UserSettingsPage: React.FC<UserSettingsPageProps> = () => {
  // SERVICE
  const [userService] = useUserService();

  // CONTEXT
  const { user } = React.useContext(UserContext);

  // HOOKS
  const [{ isOpen }, { open, close }] = useOpenClose();

  // STATE
  const [loading, setLoading] = React.useState(false);
  const [userPreferences, setUserPreferences] =
    React.useState<UserPreferencesModel | null>(null);

  // STYLES
  const classes = useStyles();

  // ROUTER
  const { push } = useHistory();

  // VARIABLES

  // EFFECTS
  React.useEffect(() => {
    if (!user) return;

    loadUserPreferences(user.id);
  }, []);

  // METHODS
  const loadUserPreferences = async (userId: number) => {
    setLoading(true);
    const result = await userService.getPreferences(userId);
    setLoading(false);

    if (result.isSuccess && result.value) setUserPreferences(result.value);
  };

  const saveUserPreferences = async () => {
    if (!userPreferences) return;

    setLoading(true);
    const result = await userService.updatePreferences(userPreferences);
    setLoading(false);

    if (result.isSuccess) open();
  };

  const handleNotifyAboutItemChangesToggle = () => {
    if (!userPreferences) return;

    const newUserPreferences = { ...userPreferences };
    newUserPreferences.notifyAboutItemChanges =
      !newUserPreferences.notifyAboutItemChanges;

    setUserPreferences(newUserPreferences);
  };

  const handleNotifyAboutRemarksToggle = () => {
    if (!userPreferences) return;

    const newUserPreferences = { ...userPreferences };
    newUserPreferences.notifyAboutRemarks =
      !newUserPreferences.notifyAboutRemarks;

    setUserPreferences(newUserPreferences);
  };

  return (
    <ContainerLarge>
      <SectionBox>
        <HeaderLarge title="Instellingen" />
      </SectionBox>

      {loading && !userPreferences && <LoadingScreen />}
      {userPreferences && (
        <>
          <SectionSmall>
            <HeaderSmall title="E-mail notificatie instellingen" />

            <SectionBox>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={userPreferences.notifyAboutItemChanges}
                    onChange={handleNotifyAboutItemChangesToggle}
                  />
                }
                label="Notificeren over algemene wijzigen binnen de Blokkies (nieuw datum voorstel, datum geprikt, etc.)"
              />
            </SectionBox>
            {/* <SectionBox>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={userPreferences.notifyAboutRemarks}
                    onChange={handleNotifyAboutRemarksToggle}
                  />
                }
                label="Notificeren over opmerkingen"
              />
            </SectionBox> */}

            <SectionBox>
              <CommonButton
                color="primary"
                label="Opslaan"
                loading={loading}
                variant="contained"
                onClick={saveUserPreferences}
              />
            </SectionBox>
          </SectionSmall>
        </>
      )}

      <CommonSnackbar
        isOpen={isOpen}
        title="Instellingen zijn succesvol opgeslagen"
        onClose={close}
      />
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({}));

export default UserSettingsPage;
