import * as React from "react";

import { Typography, makeStyles } from "@material-ui/core";

export interface HeaderNormalProps {
  title: string | React.ReactNode;
  bold?: boolean;
  color?: "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
  disableGutter?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const HeaderNormal: React.FC<HeaderNormalProps> = ({
  title,
  bold = false,
  color = "textPrimary",
  disableGutter = false,
  className,
  style,
}) => {
  // STYLES
  const classes = useStyles();
  const styles: React.CSSProperties = {};

  if (bold) styles.fontWeight = 500;
  if (style) Object.assign(styles, style);

  return (
    <Typography
      color={color}
      variant="h4"
      gutterBottom={!disableGutter}
      className={`${classes.title} ${className}`}
      style={styles}
    >
      {title}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 400,
  },
}));

export default HeaderNormal;
