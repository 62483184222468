import * as React from "react";

import {
  Badge,
  CircularProgress,
  IconButton,
  makeStyles,
} from "@material-ui/core";

export interface CommonIconButtonProps {
  icon: React.ReactNode;
  badgeContent?: React.ReactNode;
  badgeColor?: "default" | "primary" | "secondary" | "error";
  badgeStyle?: React.CSSProperties;
  badgeClass?: string;
  disabled?: boolean;
  loading?: boolean;
  size?: "medium" | "small";
  type?: "button" | "submit" | "reset";
  className?: string;
  style?: React.CSSProperties;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

export const CommonIconButton: React.FC<CommonIconButtonProps> = ({
  icon,
  badgeContent,
  badgeColor,
  badgeStyle,
  badgeClass,
  disabled = false,
  loading = false,
  size = "medium",
  type = "button",
  className = "",
  style,
  onClick = () => {},
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div className={classes.iconButtonWrapper}>
      <IconButton
        disabled={disabled || loading}
        size={size}
        type={type}
        className={className}
        style={style}
        onClick={onClick}
      >
        <Badge
          badgeContent={badgeContent}
          showZero={false}
          color={badgeColor}
          style={badgeStyle}
          className={`${
            !badgeColor ? classes.defaultBadgeColor : ""
          } ${badgeClass}`}
        >
          {icon}
        </Badge>
      </IconButton>
      {loading && (
        <CircularProgress size={24} className={classes.loadingIcon} />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  defaultBadgeColor: {
    "& .MuiBadge-badge": {
      backgroundColor: "grey",
      color: "#fff",
    },
  },
  iconButtonWrapper: {
    position: "relative",
    display: "inline-block",
  },
  loadingIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default CommonIconButton;
