import * as React from "react";

import {
  ButtonBar,
  CommonButton,
  ContainerLarge,
  GenericTextField,
  HeaderNormal,
  ReasonList,
  SectionBox,
  useLocationItemUsecases,
} from "../../../../presentation";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";

export interface NewLocationPropositionPageProps {}

interface RouteParams {
  topicId: string;
  locationItemId: string;
}

export const NewLocationPropositionPage: React.FC<NewLocationPropositionPageProps> =
  () => {
    // STYLES
    const classes = useStyles();

    // ROUTER
    const params = useParams<RouteParams>();
    const { push } = useHistory();

    // USECASES
    const [{ loading, reasons }, { addlocationProposition }] =
      useLocationItemUsecases();

    // VARIABLES
    const topicId = parseInt(params.topicId);
    const locationItemId = parseInt(params.locationItemId);
    const backUrl = `/topics/${topicId}/location-items/${locationItemId}`;
    const { register, handleSubmit, errors } = useForm();

    // METHODS
    const handleNavigateBack = () => {
      push(backUrl);
    };

    const handleActualSubmit = async (fieldValues: any) => {
      const result = await addlocationProposition(
        locationItemId,
        fieldValues.name,
        fieldValues.locationLink,
        fieldValues.streetName,
        fieldValues.zipCode,
        fieldValues.location
      );

      if (result.isSuccess) handleNavigateBack();
    };

    return (
      <ContainerLarge>
        <SectionBox>
          <HeaderNormal title="Locatie toevoegen" />
        </SectionBox>

        <SectionBox>
          <div className={classes.formWrapper}>
            <form
              autoComplete="off"
              onSubmit={handleSubmit(handleActualSubmit)}
            >
              <div>
                <GenericTextField
                  displayLabel="Naam *"
                  fieldName="name"
                  inputRef={register({ required: true, maxLength: 240 })}
                />
              </div>

              <div>
                <GenericTextField
                  displayLabel="Straatnaam + huisnummer"
                  fieldName="streetName"
                  inputRef={register()}
                />
              </div>

              <div>
                <GenericTextField
                  displayLabel="Plaats"
                  fieldName="location"
                  inputRef={register()}
                />
              </div>

              <div>
                <GenericTextField
                  displayLabel="Postcode"
                  fieldName="zipCode"
                  inputRef={register()}
                />
              </div>

              <div>
                <GenericTextField
                  displayLabel="Hyperlink"
                  fieldName="locationLink"
                  type="url"
                  inputRef={register({ required: false, maxLength: 2048 })}
                />
              </div>

              <SectionBox></SectionBox>

              <SectionBox>
                <ButtonBar>
                  <CommonButton
                    label="Annuleren"
                    onClick={handleNavigateBack}
                  />
                  <CommonButton
                    color="primary"
                    label="Toevoegen"
                    loading={loading}
                    type="submit"
                    variant="contained"
                  />
                </ButtonBar>
              </SectionBox>
            </form>

            {reasons && (
              <SectionBox>
                <ReasonList reasons={reasons} />
              </SectionBox>
            )}
          </div>
        </SectionBox>
      </ContainerLarge>
    );
  };

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    maxWidth: 600,
  },
}));

export default NewLocationPropositionPage;
