import * as React from "react";

import {
  AboutUsPage,
  AnswerRemarksPage,
  ContactPage,
  CostRemarksPage,
  CostsItemPage,
  CreateTopicPage,
  DateItemPage,
  DatePropositionChangeTimePage,
  DatePropositionRemarksPage,
  HomePage,
  HowToPage,
  LocationItemPage,
  LocationPropositionRemarksPage,
  MyTopicsPage,
  NewCostPage,
  NewDatePropositionPage,
  NewLocationPropositionPage,
  NewQuestionPage,
  NewTaskPage,
  PrivateRoute,
  QuestionRemarksPage,
  QuestionsItemPage,
  RedeemUserKeyPage,
  RedirectPage,
  RequestUserKeyPage,
  RoadmapPage,
  SideMenu,
  SignupPage,
  SignupSuccessPage,
  TaskRemarksPage,
  TasksItemPage,
  TopicCreatedPage,
  TopicStartPage,
  UpdateCostPage,
  UpdateLocationPropositionPage,
  UpdateQuestionPage,
  UpdateTaskPage,
  UpdateTopicPage,
  UserSettingsPage,
  useDeviceInfo,
} from "../../presentation";
import { IconButton, makeStyles } from "@material-ui/core";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import MenuIcon from "@material-ui/icons/Menu";
import { usePageViews } from "../hooks";

export interface MainContentProps {}

export const MainContent: React.FC<MainContentProps> = () => {
  // HOOKS
  usePageViews();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [{ isSmallDevice }] = useDeviceInfo();

  // REFS
  const divContentElement = React.createRef<HTMLDivElement>();

  // STYLES
  const classes = useStyles({ isSmallDevice })();

  // STATE
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  // VARIABLES
  React.useEffect(() => {
    divContentElement.current?.scrollTo(0, 0);
  }, [pathname]);

  // METHODS
  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const handleNavigation = (url: string) => {
    push(url);
    setDrawerOpen(false);
  };

  return (
    <div>
      {/* <div
        style={{
          position: "fixed",
          zIndex: 1200,
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton color="secondary" onClick={handleDrawerToggle}>
          <MenuIcon fontSize="large" />
        </IconButton>
      </div>
      <SideMenu
        open={drawerOpen}
        variant="temporary"
        onClose={() => setDrawerOpen(false)}
        onNavigate={handleNavigation}
      /> */}

      <div className={classes.mainContent} ref={divContentElement}>
        <Switch>
          // ITEM
          <PrivateRoute
            path="/topics/:topicId/costs-items/:costsItemId/costs/:costId/remarks"
            component={CostRemarksPage}
          />
          <PrivateRoute
            path="/topics/:topicId/costs-items/:costsItemId/costs/:costId/update"
            component={UpdateCostPage}
          />
          <PrivateRoute
            path="/topics/:topicId/costs-items/:costsItemId/costs/new"
            component={NewCostPage}
          />
          <PrivateRoute
            path="/topics/:topicId/costs-items/:costsItemId"
            component={CostsItemPage}
          />
          <PrivateRoute
            path="/topics/:topicId/questions-items/:questionsItemId/questions/answers/:answerId/remarks"
            component={AnswerRemarksPage}
          />
          <PrivateRoute
            path="/topics/:topicId/questions-items/:questionsItemId/questions/:questionId/remarks"
            component={QuestionRemarksPage}
          />
          <PrivateRoute
            path="/topics/:topicId/questions-items/:questionsItemId/questions/:questionId/update"
            component={UpdateQuestionPage}
          />
          <PrivateRoute
            path="/topics/:topicId/questions-items/:questionsItemId/questions/new"
            component={NewQuestionPage}
          />
          <PrivateRoute
            path="/topics/:topicId/questions-items/:questionsItemId"
            component={QuestionsItemPage}
          />
          <PrivateRoute
            path="/topics/:topicId/tasks-items/:tasksItemId/tasks/:taskId/remarks"
            component={TaskRemarksPage}
          />
          <PrivateRoute
            path="/topics/:topicId/tasks-items/:tasksItemId/tasks/:taskId/update"
            component={UpdateTaskPage}
          />
          <PrivateRoute
            path="/topics/:topicId/tasks-items/:tasksItemId/tasks/new"
            component={NewTaskPage}
          />
          <PrivateRoute
            path="/topics/:topicId/tasks-items/:tasksItemId"
            component={TasksItemPage}
          />
          <PrivateRoute
            path="/topics/:topicId/location-items/:locationItemId/propositions/:propositionId/remarks"
            component={LocationPropositionRemarksPage}
          />
          <PrivateRoute
            path="/topics/:topicId/location-items/:locationItemId/propositions/:propositionId/update"
            component={UpdateLocationPropositionPage}
          />
          <PrivateRoute
            path="/topics/:topicId/location-items/:locationItemId/new-proposition"
            component={NewLocationPropositionPage}
          />
          <PrivateRoute
            path="/topics/:topicId/location-items/:locationItemId"
            component={LocationItemPage}
          />
          <PrivateRoute
            path="/topics/:topicId/date-items/:dateItemId/date-propositions/:datePropositionId/remarks"
            component={DatePropositionRemarksPage}
          />
          <PrivateRoute
            path="/topics/:topicId/date-items/:dateItemId/date-propositions/:datePropositionId/change-time"
            component={DatePropositionChangeTimePage}
          />
          <PrivateRoute
            path="/topics/:topicId/date-items/:dateItemId/new-proposition"
            component={NewDatePropositionPage}
          />
          <PrivateRoute
            path="/topics/:topicId/date-items/:dateItemId"
            component={DateItemPage}
          />
          // MEMBER
          <Route
            path="/members/redeem-code/:userKey/:topicId?"
            component={RedeemUserKeyPage}
          />
          <Route
            path="/members/sign-up/:topicId/:registrationKey"
            component={SignupPage}
          />
          <Route
            exact
            path="/members/sign-up-success"
            component={SignupSuccessPage}
          />
          <PrivateRoute
            exact
            path="/user/preferences"
            component={UserSettingsPage}
          />
          // TOPIC
          <PrivateRoute
            path="/topics/:topicId/update"
            component={UpdateTopicPage}
          />
          <PrivateRoute exact path="/topics/my" component={MyTopicsPage} />
          <Route exact path="/topics/created" component={TopicCreatedPage} />
          <Route exact path="/topics/create" component={CreateTopicPage} />
          <PrivateRoute path="/topics/:topicId" component={TopicStartPage} />
          <Route
            exact
            path="/request-user-key"
            component={RequestUserKeyPage}
          />
          <Route path="/roadmap" component={RoadmapPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/about-us" component={AboutUsPage} />
          <Route path="/how-to" component={HowToPage} />
          <Route path="/redirect" component={RedirectPage} />
          <Route exact path="/" component={HomePage} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
};

const useStyles = (props: any) =>
  makeStyles((theme) => ({
    mainContent: {
      position: "absolute",
      //   top: props.isSmallDevice ? 56 : 64,
      paddingTop: props.isSmallDevice ? 56 : 64,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: "#f8f8f8",
      overflow: "auto !important",
    },
  }));

export default MainContent;
