import * as React from "react";

import { CommonButton, HeaderNormal } from "../../../../presentation";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

export interface CommonDialogProps {
  title: string;
  content: React.ReactNode;
  open: boolean;
  loading?: boolean;
  confirmButtonLabel?: string;
  confirmButtonIcon?: React.ReactNode;

  onConfirm(): void;
  onClose(): void;
}

export const CommonDialog: React.FC<CommonDialogProps> = ({
  title,
  content,
  open,
  loading = false,
  confirmButtonLabel,
  confirmButtonIcon,

  onConfirm,
  onClose,
}) => {
  // STYLES
  const theme = useTheme();

  // VARIABLES
  const fullScreen = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle>
          <HeaderNormal title={title} disableGutter />
        </DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions>
          <CommonButton label="Annuleren" onClick={onClose} />
          <CommonButton
            label={confirmButtonLabel || "Opslaan"}
            startIcon={confirmButtonIcon}
            loading={loading}
            onClick={onConfirm}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({}));

export default CommonDialog;
