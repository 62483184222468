import { container } from "tsyringe";

export interface IDependencyInjectionService {
  getService<T>(identifier: string): T;
}

export class DependencyInjectionService implements IDependencyInjectionService {
  getService<T>(identifier: string): T {
    return container.resolve<T>(identifier);
  }
}
