import * as React from "react";

import { Redirect, Route } from "react-router-dom";

import { UserContext } from "../../presentation";

export interface PrivateRouteProps {
  component: React.ComponentType<any>;
  [x: string]: any;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  // CONTEXT
  const { user, isAuthenticated } = React.useContext(UserContext);

  if (isAuthenticated === null) return null;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
