import * as React from "react";

import {
  CommonButton,
  ContainerLarge,
  GenericTextField,
  HeaderLarge,
  HeaderNormal,
  Paragraph,
  ReasonList,
  SectionBox,
  Text,
  useAuthenticationService,
} from "../../../presentation";

import RequestIcon from "@material-ui/icons/VerifiedUserOutlined";
import { makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

export interface RequestUserKeyPageProps {}

export const RequestUserKeyPage: React.FC<RequestUserKeyPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // APPLICATION SERVICE
  const [authSvc] = useAuthenticationService();

  // ROUTER
  const { replace } = useHistory();

  // FORM
  const { register, handleSubmit, errors } = useForm();

  // STATE
  const [loading, setLoading] = React.useState(false);
  const [reasons, setReasons] = React.useState<string[] | null>(null);
  const [succeeded, setSucceeded] = React.useState(false);

  // METHODS
  const handleActualSubmit = async (fieldValues: any) => {
    setReasons(null);
    setLoading(true);
    const result = await authSvc.requestUserKey(fieldValues.email);
    setLoading(false);

    if (result.isSuccess) setSucceeded(true);
    if (result.isFailed) setReasons(result.reasons);
  };

  return (
    <ContainerLarge>
      <SectionBox>
        <HeaderLarge title="Inloggen met email" />
      </SectionBox>

      {!succeeded && (
        <>
          <SectionBox>
            <Paragraph>
              Vul je email in die gekoppeld is aan jouw account en dan sturen
              wij een toegangscode waarmee je veilig kunt inloggen.
            </Paragraph>

            <Text>
              (Heb je nog geen account, maak dan een Plokkie aan of accepteer
              een uitnodiging van een andere gebruiker)
            </Text>
          </SectionBox>

          <SectionBox>
            <div className={classes.formWrapper}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(handleActualSubmit)}
              >
                <div>
                  <GenericTextField
                    displayLabel="Email *"
                    fieldName="email"
                    type="email"
                    inputRef={register({ required: true })}
                    errorMessage={errors.email && "Geen geldig emailadres"}
                  />
                </div>

                <div>
                  <CommonButton
                    label="Aanvragen"
                    loading={loading}
                    size="large"
                    startIcon={<RequestIcon />}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </SectionBox>

          <SectionBox>{reasons && <ReasonList reasons={reasons} />}</SectionBox>
        </>
      )}

      {succeeded && (
        <>
          <SectionBox>
            <HeaderNormal
              color="primary"
              title="Aanvraag succesvol ingediend"
            />
          </SectionBox>

          <SectionBox>
            <Paragraph>
              We hebben jou een email gestuurd met een toegangscode, daarmee kun
              je inloggen op Plokkie.
            </Paragraph>
          </SectionBox>
        </>
      )}
    </ContainerLarge>
  );
};

const useStyles = makeStyles(() => ({
  formWrapper: {
    maxWidth: 600,
  },
}));

export default RequestUserKeyPage;
