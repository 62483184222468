import {
  AnswerVoteType,
  GenericVoteType,
  QuestionsItemModel,
  QuestionsItemRepository,
  RemarksModel,
  Result,
} from "../../../application";
import { get, post, put, remove } from "../../../infrastructure";

export class QuestionsItemRepositoryImpl implements QuestionsItemRepository {
  async getQuestionsItem(
    itemId: number
  ): Promise<Result<QuestionsItemModel> | Result<void>> {
    return await get<QuestionsItemModel>(`items/questions/${itemId}`);
  }

  async addQuestion(
    itemId: number,
    description: string
  ): Promise<Result<void>> {
    const request = { description };

    return await post(`questions-items/${itemId}/questions/add`, request);
  }

  async updateQuestion(
    questionId: number,
    description: string
  ): Promise<Result<void>> {
    const request = { description };

    return await put(`questions-items/questions/${questionId}/update`, request);
  }

  async removeQuestion(questionId: number): Promise<Result<void>> {
    return await remove(`questions-items/questions/${questionId}/remove`);
  }

  async getQuestionRemarks(
    questionId: number
  ): Promise<Result<RemarksModel> | Result<void>> {
    return await get<RemarksModel>(
      `questions-items/questions/${questionId}/remarks`
    );
  }

  async addQuestionRemark(
    questionId: number,
    text: string
  ): Promise<Result<void>> {
    const request = { text };

    return await post(
      `questions-items/questions/${questionId}/remarks/add`,
      request
    );
  }

  async removeQuestionRemark(remarkId: number): Promise<Result<void>> {
    return await remove(`questions-items/questions/remarks/${remarkId}/remove`);
  }

  async addAnswer(
    questionId: number,
    description: string
  ): Promise<Result<void>> {
    const request = { description };

    return await post(
      `questions-items/questions/${questionId}/answers/add`,
      request
    );
  }

  async updateAnswer(
    answerId: number,
    description: string
  ): Promise<Result<void>> {
    const request = { description };

    return await put(
      `questions-items/questions/answers/${answerId}/update`,
      request
    );
  }

  async removeAnswer(answerId: number): Promise<Result<void>> {
    return remove(`questions-items/questions/answers/${answerId}/remove`);
  }

  async voteOnAnswer(
    answerId: number,
    voteType: GenericVoteType
  ): Promise<Result<void>> {
    const request = { voteType };

    return await put(
      `questions-items/questions/answers/${answerId}/vote`,
      request
    );
  }

  async chooseAnswer(answerId: number): Promise<Result<void>> {
    return await put(`questions-items/questions/answers/${answerId}/choose`);
  }

  async unChooseAnswer(answerId: number): Promise<Result<void>> {
    return await put(`questions-items/questions/answers/${answerId}/un-choose`);
  }

  async getAnswerRemarks(
    answerId: number
  ): Promise<Result<void> | Result<RemarksModel>> {
    return await get(`questions-items/questions/answers/${answerId}/remarks`);
  }

  async addAnswerRemark(answerId: number, text: string): Promise<Result<void>> {
    const request = { text };

    return await post(
      `questions-items/questions/answers/${answerId}/remarks/add`,
      request
    );
  }

  async removeAnswerRemark(remarkId: number): Promise<Result<void>> {
    return await remove(
      `questions-items/questions/answers/remarks/${remarkId}/remove`
    );
  }
}
