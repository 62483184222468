import {
  CommonButton,
  GenericTextField,
  MultilineTextField,
} from "../../../../../presentation";
import { CreateTopicModel, Result } from "../../../../../application";
import { FieldValues, useForm } from "react-hook-form";
import React, { useState } from "react";

import CreateIcon from "@material-ui/icons/CheckCircle";

export interface CreateTopicFormViewProps {
  email?: string;
  onCreate(model: CreateTopicModel): Promise<Result<number> | Result>;
}

export const CreateTopicFormView: React.FC<CreateTopicFormViewProps> = ({
  email,
  onCreate,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { register, handleSubmit, errors } = useForm();

  const handleOnCreateWrapper = async (fieldValues: any) => {
    const model = mapToModel(fieldValues);

    setLoading(true);
    await onCreate(model);
    setLoading(false);
  };

  const mapToModel = (fieldValues: any): CreateTopicModel => {
    const model = new CreateTopicModel();

    model.title = fieldValues.title;
    model.description = fieldValues.description;
    model.email = fieldValues.email;
    model.username = fieldValues.username;

    return model;
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleOnCreateWrapper)}>
      <div>
        <GenericTextField
          displayLabel="Onderwerp *"
          fieldName="title"
          inputRef={register({
            required: true,
            minLength: 3,
            maxLength: 100,
          })}
          errorMessage={errors.title && "Minimaal 3, Maximaal 100"}
        />
      </div>
      <div>
        <MultilineTextField
          displayLabel="Omschrijving *"
          fieldName="description"
          rows={8}
          inputRef={register({ required: true, maxLength: 1000 })}
          errorMessage={errors.description && "Verplicht, Maximaal 1000"}
        />
      </div>
      <div>
        <GenericTextField
          defaultValue={email}
          displayLabel="Email *"
          fieldName="email"
          type="email"
          inputRef={register({ required: true })}
          errorMessage={errors.email && "Geen geldig emailadres"}
        />
      </div>
      <div>
        <GenericTextField
          displayLabel="Gebruikersnaam *"
          fieldName="username"
          inputRef={register({ required: true, minLength: 3, maxLength: 25 })}
          errorMessage={errors.username && "Verplicht, Minimaal 3, Maximaal 25"}
        />
      </div>
      <div>
        <CommonButton
          label="Aanmaken"
          loading={loading}
          size="large"
          startIcon={<CreateIcon />}
          type="submit"
        />
      </div>
    </form>
  );
};

export default CreateTopicFormView;
