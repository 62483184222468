import * as React from "react";

import {
  CommonDialog,
  MultilineTextField,
  Paragraph,
  ReasonList,
} from "../../../../../presentation";

import { Result } from "../../../../../application";
import SendIcon from "@material-ui/icons/Send";
import { useForm } from "react-hook-form";

export interface SendGeneralMessageDialogProps {
  isOpen: boolean;
  onClose(): void;
  onSend(message: string): Promise<Result>;
}

export const SendGeneralMessageDialog: React.FC<SendGeneralMessageDialogProps> =
  ({ isOpen, onClose, onSend }) => {
    // STYLES

    // STATE
    const [loading, setLoading] = React.useState(false);
    const [reasons, setReasons] = React.useState<string[] | null>(null);

    // FORM
    const { register, handleSubmit, errors } = useForm();

    // METHODS
    const handleSend = async (fieldValues: any) => {
      setReasons(null);
      setLoading(true);
      const result = await onSend(fieldValues.message);
      setLoading(false);

      if (result.isSuccess) handleClose();
      if (result.isFailed) setReasons(result.reasons);
    };

    const handleConfirm = handleSubmit(handleSend);

    const handleClose = () => {
      setReasons(null);
      onClose();
    };

    return (
      <>
        <CommonDialog
          title="Informeer gebruikers"
          content={
            <div>
              <div>
                <Paragraph>
                  Verstuur een bericht naar alle gebruikers.
                </Paragraph>
              </div>
              <div>
                <MultilineTextField
                  displayLabel="Bericht *"
                  fieldName="message"
                  rows={12}
                  inputRef={register({ required: true })}
                  errorMessage={errors.message && "Verplicht veld"}
                />
              </div>
              <div>
                <ReasonList reasons={reasons} />
              </div>
            </div>
          }
          open={isOpen}
          loading={loading}
          confirmButtonLabel="Verzenden"
          confirmButtonIcon={<SendIcon />}
          onConfirm={handleConfirm}
          onClose={handleClose}
        />
      </>
    );
  };

export default SendGeneralMessageDialog;
