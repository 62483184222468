import * as React from "react";

import { Chip, makeStyles } from "@material-ui/core";
import {
  CommonButton,
  ContainerLarge,
  HeaderNormal,
  HideOnMobile,
  LoadingScreen,
  LoadingSpinner,
  MobileOnly,
  Paragraph,
  RemarkScreen,
  SectionBox,
  TitleWithCloseButton,
  UserContext,
  useCostsItemUsecases,
} from "../../../../presentation";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import BackIcon from "@material-ui/icons/ArrowBack";
import { Result } from "../../../../application";

export interface CostRemarksPageProps {}

interface RouteParams {
  topicId: string;
  costsItemId: string;
  costId: string;
}

export const CostRemarksPage: React.FC<CostRemarksPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // ROUTER
  const routeParams = useParams<RouteParams>();
  const { push } = useHistory();
  const { url } = useRouteMatch();

  // CONTEXTS
  const { user } = React.useContext(UserContext);

  // APPLICATION
  const [
    { remarks, loading, reasons },
    { getRemarks, addRemark, removeRemark },
  ] = useCostsItemUsecases();

  // VARIABLES
  const topicId = parseInt(routeParams.topicId);
  const costsItemId = parseInt(routeParams.costsItemId);
  const costId = parseInt(routeParams.costId);
  const url_Back = `/topics/${topicId}/costs-items/${costsItemId}`;
  const subject = remarks?.subject;

  React.useEffect(() => {
    if (!costId) return;

    reload();
  }, []);

  // METHODS
  const handleNavigationBack = () => {
    push(url_Back);
  };

  const reload = async () => {
    getRemarks(costId);
  };

  const handleSend = async (message: string): Promise<Result> => {
    const result = await addRemark(costId, message);

    if (result.isSuccess) reload();

    return result;
  };

  const handleDelete = async (remarkId: number) => {
    const result = await removeRemark(remarkId);

    if (result.isSuccess) reload();
  };

  //   if (reasons) {
  //     return <ErrorScreen reasons={reasons} />;
  //   }

  return (
    <ContainerLarge>
      <SectionBox>
        <MobileOnly>
          <TitleWithCloseButton
            title="Opmerkingen"
            onClose={handleNavigationBack}
          />
        </MobileOnly>

        <HideOnMobile>
          <CommonButton
            label="Terug"
            startIcon={<BackIcon />}
            variant="text"
            onClick={handleNavigationBack}
          />
          <HeaderNormal title="Opmerkingen" />
        </HideOnMobile>

        <Paragraph>
          Onderwerp:{" "}
          {loading && !remarks ? (
            <LoadingSpinner size={20} />
          ) : (
            <Chip color="primary" label={subject} className={classes.subject} />
          )}
        </Paragraph>
      </SectionBox>

      <div className={classes.remarkScreenWrapper}>
        <ContainerLarge className={classes.remarkScreenContainer}>
          {loading && !remarks && <LoadingScreen />}
          {remarks && (
            <RemarkScreen
              currentUserId={user?.id}
              remarks={remarks?.remarks}
              onSend={handleSend}
              onDelete={handleDelete}
            />
          )}
        </ContainerLarge>
      </div>
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  subject: {
    [theme.breakpoints.only("xs")]: {
      maxWidth: 200,
    },
  },
  remarkScreenWrapper: {
    position: "absolute",
    top: 150,
    right: 0,
    bottom: 0,
    left: 0,
    [theme.breakpoints.only("xs")]: {
      top: 110,
    },
  },
  remarkScreenContainer: {
    height: "100%",
    [theme.breakpoints.only("xs")]: {
      paddingBottom: "0 !important",
    },
  },
}));

export default CostRemarksPage;
