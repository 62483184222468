import * as React from "react";

export interface SectionSmallProps {}

export const SectionSmall: React.FC<SectionSmallProps> = ({ children }) => {
  const styles: React.CSSProperties = {
    paddingTop: "3rem",
    paddingBottom: "3rem",
  };

  return <section style={styles}>{children}</section>;
};

export default SectionSmall;
