import * as React from "react";

import { makeStyles } from "@material-ui/core";

export interface FixedBottomElementProps {}

export const FixedBottomElement: React.FC<FixedBottomElementProps> = ({
  children,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div className={classes.fixedBottomElement}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  fixedBottomElement: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    padding: 10,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    boxShadow: "0px 5px 10px #000",
    zIndex: 20,
  },
  content: {
    width: "100%",
  },
}));

export default FixedBottomElement;
