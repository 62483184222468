import * as React from "react";

import { Container, makeStyles } from "@material-ui/core";

export interface ContainerLargeProps {
  className?: string;
}

export const ContainerLarge: React.FC<ContainerLargeProps> = ({
  className,
  children,
}) => {
  // STYLES
  const classes = useStyles();

  // VARIABLES
  children = children != null ? children : <></>;

  return (
    <Container maxWidth="lg" className={`${className} ${classes.container}`}>
      {children}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.only("xs")]: {
      paddingBottom: "2rem",
    },
  },
}));

export default ContainerLarge;
