import * as React from "react";

import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  CommonInfoDialog,
  MembersHelper,
  Paragraph,
  useTopicUsecases,
} from "../../../../../presentation";

import { DateUtils } from "../../../../../application";

interface ActivitiesDialogProps {
  isOpen: boolean;
  topicId: number;
  onClose(): void;
}

export const ActivitiesDialog: React.FC<ActivitiesDialogProps> = ({
  isOpen,
  topicId,
  onClose,
}) => {
  // STYLES
  const classes = useStyles();

  // APPLICATION
  const [{ activities }, { getActivities }] = useTopicUsecases();

  React.useEffect(() => {
    if (!isOpen) return;

    getActivities(topicId);
  }, [isOpen]);

  return (
    <CommonInfoDialog
      title="Activiteiten"
      content={
        <div style={{ marginLeft: -24, marginRight: -24, marginTop: -24 }}>
          {activities?.map((activity) => (
            <ListItem
              divider
              style={{ backgroundColor: activity.seen ? undefined : "#eee" }}
            >
              <ListItemAvatar>
                <Avatar style={{ textTransform: "uppercase" }}>
                  {MembersHelper.getInitials(activity.username)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div>
                    <Paragraph>
                      <strong>{activity.username}</strong>
                      {` ${activity.description} `}
                      <strong>
                        {activity.showDetails ? activity.details : ""}
                      </strong>
                    </Paragraph>
                  </div>
                }
                secondary={
                  <div style={{ textAlign: "right", paddingTop: 8 }}>
                    {DateUtils.getShortDateTimeString(activity.tookPlaceOn)}
                  </div>
                }
              />
            </ListItem>
          ))}
        </div>
      }
      open={isOpen}
      onClose={onClose}
    />
  );
};

const useStyles = makeStyles((theme) => ({}));

export default ActivitiesDialog;
