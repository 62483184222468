import * as React from "react";

import { FormControl, InputLabel, Select } from "@material-ui/core";

import { Controller } from "react-hook-form";

export interface CommonSelectProps {
  label: string;
  name: string;
  defaultValue?: any;
  disabled?: boolean;
  control?: any;
  rules?: any;
  style?: React.CSSProperties;
}

export const CommonSelect: React.FC<CommonSelectProps> = ({
  children,
  label,
  defaultValue,
  name,
  disabled,
  control,
  rules,
  style,
}) => {
  return (
    <FormControl
      disabled={disabled}
      style={Object.assign({ width: 180 }, style)}
      margin="normal"
      variant="outlined"
    >
      <InputLabel>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        as={
          <Select label={label} variant="outlined">
            {children}
          </Select>
        }
      />
    </FormControl>
  );
};

export default CommonSelect;
