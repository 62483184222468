import * as React from "react";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CommonIconButton,
  CommonMenu,
  ConfirmationDialog,
  DateVoting,
  DoneIconComponent,
  HeaderExtraSmall,
  RemarksIconButton,
  SelectChipButton,
  Text,
  TotalUsersInvolved,
  useMenu,
} from "../../../../../../presentation";
import {
  DatePropositionModel,
  GenericVoteType,
} from "../../../../../../application";
import { makeStyles, useTheme } from "@material-ui/core";

import { DateUtils } from "../../../../../../application";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/EditOutlined";
import MoreIcon from "@material-ui/icons/MoreVert";

export interface DatePropositionProps {
  dateProposition: DatePropositionModel;
  selectionMode: boolean;
  locked: boolean;
  onEditTime(datePropositionId: number): Promise<void>;
  onDelete(datePropositionId: number): Promise<void>;
  onVote(datePropositionId: number, voteType: GenericVoteType): Promise<void>;
  onPinDate(datePropositionId: number): Promise<void>;
  onOpenRemarks(datePropositionId: number): Promise<void>;
}

export const DateProposition: React.FC<DatePropositionProps> = ({
  dateProposition,
  selectionMode,
  locked,
  onEditTime,
  onDelete,
  onVote,
  onPinDate,
  onOpenRemarks,
}) => {
  // STYLES
  const theme = useTheme();
  const classes = useStyles();

  // PROPS
  const {
    id,
    startDateInfo,
    endDateInfo,
    isPinned,
    totalRemarks,
    totalUnSeenRemarks,
    votes,
    memberVotes,
    contextData,
  } = dateProposition;
  const { startDate, startTime } = startDateInfo;
  const { endDate, endTime } = endDateInfo;
  const { userVoteType } = contextData;

  // MENU HOOK
  const [{ isOpen }, { open, close }] = useMenu();

  // STATE
  const [loading, setLoading] = React.useState(false);

  // VARIABLES
  const formattedStartDate = DateUtils.getShortDateString(startDate, false);
  const formattedEndDate =
    endDate && DateUtils.getShortDateString(endDate, false);

  const voteAmountYes = `${
    votes.filter((v) => v.voteType === GenericVoteType.Yes).length
  }`;
  const voteAmountMaybe = `${
    votes.filter((v) => v.voteType === GenericVoteType.Maybe).length
  }`;
  const voteAmountNo = `${
    votes.filter((v) => v.voteType === GenericVoteType.No).length
  }`;

  // METHODS
  const onEditTimeWrapper = async (datePropositionId: number) => {
    setLoading(true);
    await onEditTime(datePropositionId);
    setLoading(false);
  };

  const onDeleteWrapper = async () => {
    setLoading(true);
    await onDelete(id);
    setLoading(false);
  };

  const onVoteWrapper = async (
    datePropositionId: number,
    voteType: GenericVoteType
  ) => {
    setLoading(true);
    await onVote(datePropositionId, voteType);
    setLoading(false);
  };

  const onPinDateWrapper = async (datePropositionId: number) => {
    setLoading(true);
    await onPinDate(datePropositionId);
    setLoading(false);
  };

  return (
    <Card
      loading={loading}
      isPinned={isPinned}
      disabled={!isPinned && (locked || selectionMode)}
    >
      <CardHeader confirmed={isPinned}>
        <RemarksIconButton
          totalRemarks={totalRemarks}
          totalUnSeenRemarks={totalUnSeenRemarks}
          onClick={() => onOpenRemarks(id)}
        />
        {selectionMode && !isPinned && (
          <SelectChipButton
            style={{ zIndex: 10 }}
            onClick={() => onPinDateWrapper(id)}
          />
        )}
        {isPinned && <DoneIconComponent />}
        <ConfirmationDialog
          title="Verwijderen"
          confirmButtonWarningColor
          onConfirm={onDeleteWrapper}
        >
          {({ open: openDialog }) => (
            <CommonMenu>
              {({ open: openMenu }) => ({
                button: (
                  <CommonIconButton
                    disabled={locked}
                    icon={<MoreIcon />}
                    size="small"
                    onClick={openMenu}
                  />
                ),
                items: [
                  {
                    label: "Tijd aanpassen",
                    icon: <EditIcon />,
                    handleClick: () => onEditTimeWrapper(id),
                  },
                  {
                    label: "Verwijderen",
                    icon: <DeleteIcon />,
                    handleClick: () => openDialog(),
                  },
                ],
              })}
            </CommonMenu>
          )}
        </ConfirmationDialog>
      </CardHeader>
      <CardContent>
        <div className={`${selectionMode ? classes.textSelectionMode : ""}`}>
          <div
            className={`
                    ${classes.cardDate} 
                    ${formattedEndDate ? classes.cardDates : ""}
                    ${isPinned ? classes.cardDatePinned : ""}
                `}
          >
            <HeaderExtraSmall title={formattedStartDate} />
            <HeaderExtraSmall title={formattedEndDate || ""} />
          </div>
          <div className={classes.cardTimes}>
            <Text>
              {startTime}
              {startTime && " uur"}
            </Text>
            <Text>
              {endTime}
              {endTime && " uur"}
            </Text>
          </div>
        </div>
        <DateVoting
          locked={locked}
          userVoteType={userVoteType}
          voteAmountYes={voteAmountYes}
          voteAmountNo={voteAmountNo}
          voteAmountMaybe={voteAmountMaybe}
          selectionMode={selectionMode}
          onVote={(voteType) => onVoteWrapper(id, voteType)}
        />

        <CardFooter>
          <TotalUsersInvolved memberVotes={memberVotes} />
        </CardFooter>
      </CardContent>
    </Card>
  );
};

const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: "short",
  day: "2-digit",
  month: "2-digit",
  year: "2-digit",
};

const useStyles = makeStyles((theme) => ({
  card: {
    width: 300,
    [theme.breakpoints.only("xs")]: {
      width: "auto",
    },
  },
  cardContent: {
    paddingTop: 5,
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px",
  },
  cardHeaderPinned: {
    backgroundColor: theme.palette.success.main,
  },
  cardHeaderNotPinned: {
    backgroundColor: theme.palette.error.main,
  },
  cardHeaderPinButtonLocked: {
    color: "#fff",
  },
  cardDate: {
    display: "flex",
    justifyContent: "center",
    "& *": {
      fontWeight: "bold",
    },
  },
  cardDates: {
    justifyContent: "space-between",
  },
  cardDatePinned: {
    color: theme.palette.success.main,
  },
  cardTimes: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 30px",
    minHeight: 20,
  },
  cardVoting: {
    padding: "10px 0",
  },
  cardVotingAmount: {
    textAlign: "center",
    marginTop: 5,
    marginLeft: 4,
  },
  cardVotingAmountYes: {
    color: theme.palette.success.main,
  },
  cardVotingAmountMaybe: {
    color: theme.palette.warning.main,
  },
  cardVotingAmountNo: {
    color: theme.palette.error.main,
  },
  textSelectionMode: {
    position: "relative",
    zIndex: 10,
  },
}));

export default DateProposition;
