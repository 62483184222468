import * as React from "react";

import { makeStyles } from "@material-ui/core";

interface SectionBlockProps {}

export const SectionBlock: React.FC<SectionBlockProps> = ({ children }) => {
  // STYLES
  const classes = useStyles();

  return <section className={classes.section}>{children}</section>;
};

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: "5rem",
    paddingBottom: "5rem",
    [theme.breakpoints.only("xs")]: {
      paddingTop: "3rem",
      paddingBottom: "3rem",
    },
  },
}));

export default SectionBlock;
