import * as React from "react";

import {
  BlocksInfo,
  ContainerLarge,
  HeaderExtraSmall,
  HeaderLarge,
  HeaderNormal,
  HeaderSmall,
  Paragraph,
  SectionBox,
  SectionSmall,
  Step1Image,
  Step2Image,
  Step3Image,
  Step4Image,
} from "../../../presentation";
import { Grid, makeStyles } from "@material-ui/core";

interface HowToPageProps {}

export const HowToPage: React.FC<HowToPageProps> = () => {
  // STYLES
  const classes = useStyles();

  return (
    <ContainerLarge>
      <SectionBox>
        <HeaderLarge title="Hoe werkt het" />
      </SectionBox>

      <SectionSmall>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6}>
            <div className={classes.columnContentWrapper}>
              <HeaderSmall title="Stap 1" color="primary" />
              <HeaderExtraSmall title="Maak een Plokkie aan" />

              <SectionBox>
                <Paragraph>
                  Vul hier de titel van de groepsactiviteit en geef een extra
                  toelichting in de omschrijving. Vervolgens vul je jouw
                  e-mailadres in en een gebruikersnaam. Hiermee koppelen we je
                  e-mailadres aan je Plokkie en kan je hiermee inloggen.
                </Paragraph>
              </SectionBox>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.columnContentWrapper}>
              <img src={Step1Image} style={{ width: "100%", maxWidth: 400 }} />
            </div>
          </Grid>
        </Grid>
      </SectionSmall>

      <SectionSmall>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6} className={classes.mobileOrderSecond}>
            <div className={classes.columnContentWrapper}>
              <img src={Step2Image} style={{ width: "100%", maxWidth: 400 }} />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.columnContentWrapper}>
              <HeaderSmall title="Stap 2" color="primary" />
              <HeaderExtraSmall title="Activeer je Plokkie via e-mail" />

              <SectionBox>
                <Paragraph>
                  Ga naar je e-mail en druk op de link die wij je gestuurd
                  hebben. Gefeliciteerd want je Plokkie is geactiveerd!
                </Paragraph>
              </SectionBox>
            </div>
          </Grid>
        </Grid>
      </SectionSmall>

      <SectionSmall>
        <div style={{ maxWidth: 850, margin: "0 auto", textAlign: "center" }}>
          <HeaderSmall title="Stap 3" color="primary" />
          <HeaderExtraSmall title="Voeg blokkies toe en vul deze aan" />

          <SectionBox>
            <Paragraph>
              Voeg de blokkies toe die je nodig hebt om je groepsactiviteit te
              regelen. Dit doe je door te drukken op de knop “Blokkies”.
              Vervolgens selecteer de blokkies die je wil toevoegen en druk op
              “Opslaan”.
            </Paragraph>
          </SectionBox>

          <SectionBox>
            <Paragraph>
              Na het toevoegen kan je de blokkies al voorzien van wat informatie
              en voorstellen. Maar ook later kunnen de blokkies zowel door jou
              als de andere gebruikers aangevuld worden.
            </Paragraph>
          </SectionBox>

          <SectionBox>
            <img src={Step3Image} style={{ width: "100%", maxWidth: 400 }} />
          </SectionBox>
        </div>
      </SectionSmall>

      <SectionSmall>
        <SectionBox>
          <HeaderNormal
            title="We kennen momenteel de volgende blokkies"
            style={{ textAlign: "center" }}
          />
        </SectionBox>

        <SectionBox>
          <BlocksInfo />
        </SectionBox>
      </SectionSmall>

      <SectionSmall>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6}>
            <div className={classes.columnContentWrapper}>
              <HeaderSmall title="Stap 4" color="primary" />
              <HeaderExtraSmall title="Gebruikers uitnodigen" />

              <SectionBox>
                <Paragraph>
                  Nodig andere leden uit om deel te nemen aan je Plokkie. Dit
                  doe je door te drukken op de knop “Gebruikers uitnodigen” en
                  de link te kopiëren. Deel deze eenvoudig door ze door te
                  sturen via WhatsApp of e-mail.
                </Paragraph>
              </SectionBox>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.columnContentWrapper}>
              <img src={Step4Image} style={{ width: "100%", maxWidth: 400 }} />
            </div>
          </Grid>
        </Grid>
      </SectionSmall>
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  columnContentWrapper: {
    padding: 32,
    textAlign: "center",
    [theme.breakpoints.only("xs")]: {
      padding: 0,
    },
  },
  mobileOrderSecond: {
    [theme.breakpoints.only("xs")]: {
      order: 2,
    },
  },
}));

export default HowToPage;
