import * as React from "react";

import { Typography } from "@material-ui/core";

export interface ParagraphProps {
  style?: React.CSSProperties;
}

export const Paragraph: React.FC<ParagraphProps> = ({ children, style }) => {
  return (
    <Typography color="textPrimary" style={style} variant="body1">
      {children}
    </Typography>
  );
};

export default Paragraph;
