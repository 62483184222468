import * as React from "react";

import DoneIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core";

export interface DoneIconComponentProps {}

export const DoneIconComponent: React.FC<DoneIconComponentProps> = () => {
  // STYLE
  const classes = useStyles();

  return <DoneIcon className={classes.doneIcon} />;
};

const useStyles = makeStyles((theme) => ({
  doneIcon: {
    color: theme.palette.success.main,
  },
}));

export default DoneIconComponent;
