import * as React from "react";

import {
  CommonButton,
  HeaderNormal,
  useDialog,
} from "../../../../presentation";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

export interface InformationDialogProps {
  title: string;
  content: React.ReactNode;
  maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl";
  fullScreen?: boolean;
  children(api: ChildrenApi): React.ReactNode;
}

interface ChildrenApi {
  open(): void;
}

export const InformationDialog: React.FC<InformationDialogProps> = ({
  title,
  content,
  maxWidth = "md",
  fullScreen,
  children,
}) => {
  // STYLES
  const classes = useStyles();
  const theme = useTheme();

  // HOOKS
  const [{ isOpen }, { open, close }] = useDialog();

  // VARIABLES
  const smallScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const _fullScreen = fullScreen === undefined ? smallScreen : fullScreen;

  return (
    <>
      {children({ open })}
      <Dialog
        open={isOpen}
        onClose={close}
        maxWidth={maxWidth}
        fullWidth
        fullScreen={_fullScreen}
      >
        <DialogTitle>
          <HeaderNormal title={title} disableGutter />
        </DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions>
          <CommonButton label="Sluiten" onClick={close} />
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({}));

export default InformationDialog;
