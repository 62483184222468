import * as React from "react";

import StarIcon from "@material-ui/icons/Star";

export interface StarsProps {
  amount: number;
  className?: string;
}

export const Stars: React.FC<StarsProps> = ({ amount, className }) => {
  return (
    <div style={{ textAlign: "center" }}>
      {new Array(amount).fill(0).map((value, index) => (
        <StarIcon fontSize="small" className={className} />
      ))}
    </div>
  );
};

export default Stars;
