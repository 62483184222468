import { ItemType } from "../../application";

const itemOrderMap: any = {};
itemOrderMap[ItemType.Date] = 1;
itemOrderMap[ItemType.Location] = 2;
itemOrderMap[ItemType.Tasks] = 3;
itemOrderMap[ItemType.Costs] = 4;
itemOrderMap[ItemType.Questions] = 5;

export const getItemTypes = (): number[] => {
  const itemTypeKeys = Object.keys(ItemType);

  const itemTypes = itemTypeKeys
    .filter((key) => !isNaN(Number(key)))
    .map((key) => Number(key))
    .sort((a, b) => itemOrderMap[a] - itemOrderMap[b]);

  return itemTypes;
};

export const getItemTypeLabel = (itemType: ItemType) => {
  switch (itemType) {
    case ItemType.Date:
      return "Datum";
    case ItemType.Location:
      return "Locatie";
    case ItemType.Tasks:
      return "Taken";
    case ItemType.Questions:
      return "Vragen";
    case ItemType.Costs:
      return "Kosten";
    default:
      return "Onbekend";
  }
};

export const getItemTypeDescription = (itemType: ItemType) => {
  switch (itemType) {
    case ItemType.Date:
      return "Voeg datums toe en prik als groep de datum die het beste uitkomt.";
    case ItemType.Location:
      return "Voeg locaties toe en kies de beste bestemming voor jullie Plokkie.";
    case ItemType.Tasks:
      return "Moet er werk worden verzet? Voeg hier taken toe en koppel deze aan de juiste gebruikers.";
    case ItemType.Questions:
      return "Heb je een vraag richting de groep, stel deze dan hier.";
    case ItemType.Costs:
      return "Voer kosten op en zie wie wat moet betalen.";
    default:
      return "Onbekend";
  }
};

export const containsDateItem = (itemTypes: number[]): boolean => {
  return itemTypes.indexOf(ItemType.Date) !== -1;
};

export const containsLocationItem = (itemTypes: number[]): boolean => {
  return itemTypes.indexOf(ItemType.Location) !== -1;
};

export const containsTodosItem = (itemTypes: number[]): boolean => {
  return itemTypes.indexOf(ItemType.Tasks) !== -1;
};

export const containsQuestionsItem = (itemTypes: number[]): boolean => {
  return itemTypes.indexOf(ItemType.Questions) !== -1;
};

export const containsCostsItem = (itemTypes: number[]): boolean => {
  return itemTypes.indexOf(ItemType.Costs) !== -1;
};
