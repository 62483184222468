import { INJECTION_TOKENS, TokenService } from "../../../application";

import axios from "axios";
import { container } from "tsyringe";

axios.interceptors.request.use((request) => {
  if (request?.headers?.common == null) {
    throw new Error("Common headers not available in HTTP request.");
  }

  const tokenSvc = container.resolve<TokenService>(
    INJECTION_TOKENS.TokenService
  );
  const token = tokenSvc.getToken();

  if (token != null) {
    const { common: commonHeaders } = request.headers;

    commonHeaders["Authorization"] = `Bearer ${token}`;
  }

  return request;
});
