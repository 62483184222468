import * as React from "react";

import { CommonButton, GenericTextField } from "../../../../../presentation";

import AcceptIcon from "@material-ui/icons/CheckCircle";
import { SignupModel } from "../../../../../application";
import { makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";

export interface RegistrationFormProps {
  loading: boolean;
  email?: string;
  onSubmit(model: SignupModel): void;
}

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
  loading,
  email,
  onSubmit,
}) => {
  // STYLES
  const classes = useStyles();

  // FORM
  const { handleSubmit, register, errors } = useForm();

  // METHODS
  const onSubmitWrapper = (fieldValues: any) => {
    const model = mapToModel(fieldValues);

    onSubmit(model);
  };

  const mapToModel = (fieldValues: any) => {
    const model = new SignupModel();

    model.email = fieldValues.email;
    model.username = fieldValues.username;

    return model;
  };

  return (
    <div className={classes.formWrapper}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmitWrapper)}>
        <div>
          <GenericTextField
            defaultValue={email}
            displayLabel="Email *"
            fieldName="email"
            type="email"
            inputRef={register({ required: true })}
            errorMessage={errors.email && "Geen geldig emailadres"}
          />
        </div>
        <div>
          <GenericTextField
            displayLabel="Gebruikersnaam *"
            fieldName="username"
            inputRef={register({
              required: true,
              minLength: 3,
              maxLength: 25,
            })}
            errorMessage={
              errors.username && "Verplicht, Minimaal 3, Maximaal 25"
            }
          />
        </div>
        <div>
          <CommonButton
            type="submit"
            label="Accepteren"
            startIcon={<AcceptIcon />}
            loading={loading}
          />
        </div>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    maxWidth: 600,
  },
}));

export default RegistrationForm;
