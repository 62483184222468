import * as React from "react";

import { Typography, makeStyles } from "@material-ui/core";

export interface HeaderMediumProps {
  title: string | React.ReactNode;
  bold?: boolean;
  color?: "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
  style?: React.CSSProperties;
  className?: string;
}

export const HeaderMedium: React.FC<HeaderMediumProps> = ({
  title,
  bold = false,
  color = "textPrimary",
  style,
  className,
}) => {
  // STYLES
  const classes = useStyles();
  const styles: React.CSSProperties = {};

  if (bold) styles.fontWeight = 500;
  if (style) Object.assign(styles, style);

  return (
    <Typography
      color={color}
      variant="h3"
      gutterBottom
      className={`${classes.title} ${className}`}
      style={styles}
    >
      {title}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 400,
  },
}));

export default HeaderMedium;
