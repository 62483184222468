import * as React from "react";

import { Box } from "@material-ui/core";

export interface MobileOnlyProps {}

export const MobileOnly: React.FC<MobileOnlyProps> = ({ children }) => {
  return <Box display={{ xs: "block", sm: "none" }}>{children}</Box>;
};

export default MobileOnly;
