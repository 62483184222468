import * as React from "react";

import {
  ButtonBar,
  CommonButton,
  ContainerLarge,
  HeaderNormal,
  LoadingScreen,
  ReasonList,
  SectionBox,
  useDateItemUsecases,
} from "../../../../../presentation";
import { TextField, makeStyles } from "@material-ui/core";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { ChangeTimeFromDatePropositionModel } from "../../../../../application";
import ResetIcon from "@material-ui/icons/RotateLeft";

const useStyles = makeStyles((theme) => ({
  timeField: { width: 150 },
}));

export interface DatePropositionChangeTimeProps {}

interface RouteParams {
  topicId: string;
  dateItemId: string;
  datePropositionId: string;
}

export const DatePropositionChangeTimePage: React.FC<DatePropositionChangeTimeProps> =
  () => {
    // STYLES
    const classes = useStyles();

    // ROUTER
    const routeParams = useParams<RouteParams>();
    const { push } = useHistory();
    const { url } = useRouteMatch();

    // STATE
    const [startTime, setStartTime] = React.useState<string | null>(null);
    const [endTime, setEndTime] = React.useState<string | null>(null);

    // USECASES
    const [
      { loading, loadingUpdate, reasons },
      { getTimesFromDateProposition, changeTimeFromDateProposition },
    ] = useDateItemUsecases();

    // VARIABLES
    const topicId = parseInt(routeParams.topicId);
    const dateItemId = parseInt(routeParams.dateItemId);
    const datePropositionId = parseInt(routeParams.datePropositionId);
    const backUrl = `/topics/${topicId}/date-items/${dateItemId}`;

    React.useEffect(() => {
      fetchTimes(datePropositionId);
    }, []);

    const fetchTimes = async (datePropositionId: number) => {
      const result = await getTimesFromDateProposition(datePropositionId);

      if (result.isSuccess && result.value) {
        const { startTime, endTime } = result.value;

        setStartTime(startTime);
        setEndTime(endTime);
      }
    };

    const handleNavigateBack = () => {
      push(backUrl);
    };

    const handleReset = () => {
      setStartTime("");
      setEndTime("");
    };

    const handleChangeStartTime = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setStartTime(event.target.value);
    };

    const handleChangeEndTime = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setEndTime(event.target.value);
    };

    const handleSave = async () => {
      const model = new ChangeTimeFromDatePropositionModel();

      model.startTime = startTime;
      model.endTime = endTime;

      const result = await changeTimeFromDateProposition(
        datePropositionId,
        model
      );

      if (result.isSuccess) {
        push(backUrl);
      }
    };

    return (
      <ContainerLarge>
        <SectionBox>
          <HeaderNormal title="Tijd aanpassen" />
        </SectionBox>

        {loading && <LoadingScreen />}

        {!loading && (
          <>
            <SectionBox>
              <CommonButton
                label="Reset tijd"
                startIcon={<ResetIcon />}
                onClick={handleReset}
              />
            </SectionBox>

            <SectionBox>
              <TextField
                label="Starttijd (optioneel)"
                InputLabelProps={{ shrink: true }}
                type="time"
                variant="outlined"
                className={classes.timeField}
                style={{ marginRight: 10, marginBottom: 20 }}
                value={startTime}
                onChange={handleChangeStartTime}
              />
              <TextField
                label="Eindtijd (optioneel)"
                InputLabelProps={{ shrink: true }}
                type="time"
                variant="outlined"
                className={classes.timeField}
                value={endTime}
                onChange={handleChangeEndTime}
              />
            </SectionBox>

            <SectionBox>
              <ReasonList reasons={reasons} />
            </SectionBox>

            <SectionBox>
              <ButtonBar>
                <CommonButton label="Annuleren" onClick={handleNavigateBack} />
                <CommonButton
                  label="Opslaan"
                  color="primary"
                  loading={loadingUpdate}
                  variant="contained"
                  onClick={handleSave}
                />
              </ButtonBar>
            </SectionBox>
          </>
        )}
      </ContainerLarge>
    );
  };

export default DatePropositionChangeTimePage;
