import * as React from "react";

import { Chip, makeStyles } from "@material-ui/core";

import Check from "@material-ui/icons/Check";

export interface SelectChipButtonProps {
  style?: React.CSSProperties;
  onClick(): void;
}

export const SelectChipButton: React.FC<SelectChipButtonProps> = ({
  style,
  onClick,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <Chip
      color="primary"
      label="Kies"
      icon={<Check />}
      size="small"
      style={style}
      onClick={onClick}
    />
  );
};

const useStyles = makeStyles((theme) => ({}));

export default SelectChipButton;
