import {
  AuthRepositoryImpl,
  CostsItemRepositoryImpl,
  DateItemRepositoryImpl,
  ItemRepositoryImpl,
  LocationItemRepositoryImpl,
  QuestionsItemRepositoryImpl,
  TasksItemRepositoryImpl,
  TopicRepositoryImpl,
  UserRepositoryImpl,
  WebLocalStorageRepositoryImpl,
} from "../../../infrastructure";
import {
  AuthenticationServiceImpl,
  CostsItemServiceImpl,
  DateItemServiceImpl,
  ItemServiceImpl,
  LocationItemServiceImpl,
  QuestionsItemServiceImpl,
  TasksItemServiceImpl,
  TokenServiceImpl,
  TopicServiceImpl,
  UserServiceImpl,
} from "../../../application";

import { INJECTION_TOKENS } from "../../../application";
import { container } from "tsyringe";

// USER
container.register(INJECTION_TOKENS.UserService, {
  useClass: UserServiceImpl,
});
container.register(INJECTION_TOKENS.UserRepository, {
  useClass: UserRepositoryImpl,
});

// APPLICATION SERVICES
container.register(INJECTION_TOKENS.AuthenticationService, {
  useClass: AuthenticationServiceImpl,
});
container.register(INJECTION_TOKENS.CostsItemService, {
  useClass: CostsItemServiceImpl,
});
container.register(INJECTION_TOKENS.DateItemService, {
  useClass: DateItemServiceImpl,
});
container.register(INJECTION_TOKENS.ItemService, {
  useClass: ItemServiceImpl,
});
container.register(INJECTION_TOKENS.LocationItemService, {
  useClass: LocationItemServiceImpl,
});
container.register(INJECTION_TOKENS.QuestionsItemService, {
  useClass: QuestionsItemServiceImpl,
});
container.register(INJECTION_TOKENS.TasksItemService, {
  useClass: TasksItemServiceImpl,
});
container.register(INJECTION_TOKENS.TokenService, {
  useClass: TokenServiceImpl,
});
container.register(INJECTION_TOKENS.TopicService, {
  useClass: TopicServiceImpl,
});

// INFRASTRUCTURE SERVICES
container.register(INJECTION_TOKENS.AuthRepository, {
  useClass: AuthRepositoryImpl,
});
container.register(INJECTION_TOKENS.CostsItemRepository, {
  useClass: CostsItemRepositoryImpl,
});
container.register(INJECTION_TOKENS.DateItemRepository, {
  useClass: DateItemRepositoryImpl,
});
container.register(INJECTION_TOKENS.ItemRepository, {
  useClass: ItemRepositoryImpl,
});
container.register(INJECTION_TOKENS.LocationItemRepository, {
  useClass: LocationItemRepositoryImpl,
});
container.register(INJECTION_TOKENS.QuestionsItemRepository, {
  useClass: QuestionsItemRepositoryImpl,
});
container.register(INJECTION_TOKENS.TasksItemRepository, {
  useClass: TasksItemRepositoryImpl,
});
container.register(INJECTION_TOKENS.TopicRepository, {
  useClass: TopicRepositoryImpl,
});
container.register(INJECTION_TOKENS.WebStorage, {
  useClass: WebLocalStorageRepositoryImpl,
});
