import * as React from "react";

import {
  ActivitiesDialog,
  AddItemsDialog,
  ButtonBar,
  CommonButton,
  CommonIconButton,
  CommonMenu,
  CommonSnackbar,
  ContainerLarge,
  EmptyBlocksImage,
  ErrorScreen,
  HeaderExtraSmall,
  HeaderLarge,
  HideOnMobile,
  InstructionsDialog,
  InvitationDialog,
  ItemCardList,
  ItemsContainer,
  LoadingScreen,
  MemberAvatars,
  MobileNavigationBar,
  MobileOnly,
  Paragraph,
  SectionBox,
  SectionSmall,
  SendGeneralMessageDialog,
  useDialog,
  useOpenClose,
  useTopicUsecases,
} from "../../../presentation";
import { Badge, Box, makeStyles, useMediaQuery } from "@material-ui/core";
import { ItemType, Result, UserProductToursModel } from "../../../application";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useDeviceInfo, useUserService } from "../../hooks";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import AddMobileIcon from "@material-ui/icons/WidgetsOutlined";
import BlokkiesIcon from "@material-ui/icons/WidgetsOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import EmailIcon from "@material-ui/icons/Email";
import EmailMobileIcon from "@material-ui/icons/EmailOutlined";
import InstructionsIcon from "@material-ui/icons/Help";
import NotificationsIcon from "@material-ui/icons/NotificationsNoneOutlined";
import PeopleAltIcon from "@material-ui/icons/GroupAdd";
import ProductTourIcon from "@material-ui/icons/EmojiObjectsOutlined";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";

export interface TopicStartPageProps {}

export const TopicStartPage: React.FC<TopicStartPageProps> = () => {
  const [{ isSmallDevice }] = useDeviceInfo();

  // APPLICATION
  const [
    { topic: topicVM, loading: loadingTopic, reasons: reasonsTopic },
    { getTopic, sendGeneralMessage },
  ] = useTopicUsecases();
  const [userService] = useUserService();

  // STATE
  const [
    { isOpen: isSnackbarOpen },
    { open: openSnackbar, close: closeSnackbar },
  ] = useOpenClose();
  const [productTours, setProductTours] =
    React.useState<UserProductToursModel | null>(null);
  const [run, setRun] = React.useState(false);
  const [steps, setSteps] = React.useState<Step[]>([]);

  // ROUTER
  const { topicId } = useParams<RouteParams>();
  const { push } = useHistory();
  const { url } = useRouteMatch();

  // PROPERTIES
  const topicIdAsNumber = parseInt(topicId);

  // EFFECTS
  React.useEffect(() => {
    if (!topicIdAsNumber) return;

    init();
  }, []);

  React.useEffect(() => {
    setSteps(isSmallDevice ? mobileTourSteps : tourSteps);
  }, [isSmallDevice]);

  // STYLES
  const classes = useStyles();

  // DIALOGS
  const [
    { isOpen: isAddItemsDialogOpen },
    { open: openAddItemsDialog, close: closeAddItemsDialog },
  ] = useDialog();
  const [
    { isOpen: isSendGeneralMessageOpen },
    {
      open: openSendGeneralMessageDialog,
      close: closeSendGeneralMessageDialog,
    },
  ] = useDialog();
  const [
    { isOpen: isInstructionsDialogOpen },
    { open: openInstructionsDialog, close: closeInstructionsDialog },
  ] = useDialog();
  const [
    { isOpen: isActivityDialogOpen },
    { open: openActivityDialog, close: closeActivityDialog },
  ] = useDialog();

  // METHODS
  const init = async () => {
    const result = await userService.getProductTours();
    if (result.isSuccess && result.value) {
      const retrievedProductTours = result.value;
      setRun(!retrievedProductTours.hadPlokkieStartPageTour);
      setProductTours(retrievedProductTours);
    }

    await getTopic(topicIdAsNumber);
  };

  const handleJoyrideCallback = ({ status }: any) => {
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);

      if (!productTours) return;

      const updatedProductTours = { ...productTours };
      updatedProductTours.hadPlokkieStartPageTour = true;
      setProductTours(updatedProductTours);

      userService.updateProductTours(updatedProductTours);
    }
  };

  const handleNavigationToUpdateTopicPage = () => {
    push(`${url}/update`);
  };

  const handleItemClick = (itemId: number, itemType: ItemType) => {
    let navigateTo: string = `${url}`;

    switch (itemType) {
      case ItemType.Date:
        navigateTo += `/date-items/${itemId}`;
        break;
      case ItemType.Location:
        navigateTo += `/location-items/${itemId}`;
        break;
      case ItemType.Tasks:
        navigateTo += `/tasks-items/${itemId}`;
        break;
      case ItemType.Questions:
        navigateTo += `/questions-items/${itemId}`;
        break;
      case ItemType.Costs:
        navigateTo += `/costs-items/${itemId}`;
        break;
    }

    push(navigateTo);
  };

  const handleSendMessage = async (message: string): Promise<Result> => {
    const result = await sendGeneralMessage(topicIdAsNumber, message);

    if (result.isSuccess) openSnackbar();

    return result;
  };

  const closeAddItemsDialogWrapper = async () => {
    closeAddItemsDialog();
    await getTopic(topicIdAsNumber);
  };

  const closeActivityDialogWrapper = async () => {
    closeActivityDialog();
    await getTopic(topicIdAsNumber);
  };

  return (
    <>
      <ItemsContainer topicId={topicIdAsNumber}>
        {({
          itemsVM,
          loading: loadingItems,
          reasons: reasonsItems,
          addItems,
        }) => (
          <>
            <ContainerLarge>
              <>
                <>{!topicVM && loadingTopic && <LoadingScreen />}</>

                <>
                  {topicVM && (
                    <>
                      <Joyride
                        steps={steps}
                        run={run}
                        callback={handleJoyrideCallback}
                        continuous
                        disableCloseOnEsc
                        disableOverlayClose
                        disableScrolling
                        showSkipButton
                        locale={{
                          back: "Terug",
                          close: "Sluiten",
                          last: "Klaar",
                          next: "Volgende",
                          skip: "Overslaan",
                        }}
                      />

                      <Box display="flex" alignItems="center">
                        <div style={{ flexGrow: 1 }}>
                          <HeaderExtraSmall
                            title="Gebruikers:"
                            style={{
                              display: "inline-block",
                              marginRight: 8,
                            }}
                          />

                          <div style={{ display: "inline-block" }}>
                            <MemberAvatars members={topicVM?.members} />
                          </div>
                        </div>

                        <div>
                          <MobileOnly>
                            <InvitationDialog
                              registrationUrl={topicVM?.registrationUrl}
                            >
                              {({ open }) => (
                                <CommonIconButton
                                  className="mobile-tour-step-2"
                                  icon={<PeopleAltIcon fontSize="large" />}
                                  onClick={open}
                                />
                              )}
                            </InvitationDialog>
                          </MobileOnly>
                        </div>

                        <div style={{ display: "inline-block" }}>
                          <CommonMenu>
                            {({ open }) => ({
                              button: (
                                <CommonIconButton
                                  className="tour-step-3"
                                  icon={<SettingsIcon fontSize="large" />}
                                  onClick={open}
                                />
                              ),
                              items: [
                                {
                                  label: "Aanpassen",
                                  icon: <EditIcon />,
                                  handleClick:
                                    handleNavigationToUpdateTopicPage,
                                },
                                {
                                  label: "Product tour",
                                  icon: <ProductTourIcon />,
                                  handleClick: () => setRun(true),
                                },
                              ],
                            })}
                          </CommonMenu>
                        </div>
                      </Box>

                      <SectionBox>
                        <HeaderLarge title={topicVM.title} />
                        <Paragraph>{topicVM.description}</Paragraph>
                      </SectionBox>
                    </>
                  )}
                </>
              </>

              <>
                {!loadingItems && itemsVM && (
                  <div>
                    <HideOnMobile>
                      <ButtonBar>
                        <CommonButton
                          className="tour-step-1"
                          label="Blokkies"
                          startIcon={<BlokkiesIcon />}
                          onClick={openAddItemsDialog}
                        />

                        <InvitationDialog
                          registrationUrl={topicVM?.registrationUrl}
                        >
                          {({ open }) => (
                            <CommonButton
                              className="tour-step-2"
                              label="Gebruikers uitnodigen"
                              startIcon={<PeopleAltIcon />}
                              onClick={open}
                            />
                          )}
                        </InvitationDialog>

                        <CommonButton
                          label="Informeer gebruikers"
                          startIcon={<EmailIcon />}
                          onClick={openSendGeneralMessageDialog}
                        />

                        <CommonButton
                          label="Hoe werkt het"
                          startIcon={<InstructionsIcon />}
                          onClick={openInstructionsDialog}
                        />

                        <CommonButton
                          label="Activiteiten"
                          startIcon={
                            <Badge
                              badgeContent={
                                topicVM?.unSeenActivity ? "!" : undefined
                              }
                              color="error"
                              showZero
                            >
                              <NotificationsIcon />
                            </Badge>
                          }
                          onClick={openActivityDialog}
                        />
                      </ButtonBar>
                    </HideOnMobile>

                    {itemsVM.length > 0 && (
                      <SectionBox>
                        <ItemCardList
                          items={itemsVM}
                          onItemClick={handleItemClick}
                        />
                      </SectionBox>
                    )}

                    {itemsVM.length === 0 && (
                      <SectionSmall>
                        <div style={{ textAlign: "center" }}>
                          <div>
                            <img
                              src={EmptyBlocksImage}
                              style={{ width: "100%", maxWidth: 250 }}
                            />
                          </div>

                          <SectionBox>
                            <HeaderExtraSmall title="Er zijn nog geen Blokkies toegevoegd." />
                          </SectionBox>
                        </div>
                      </SectionSmall>
                    )}
                  </div>
                )}
              </>

              <>
                <ErrorScreen reasons={reasonsTopic} />
              </>
            </ContainerLarge>

            <MobileNavigationBar>
              {() => ({
                buttons: [
                  {
                    icon: <AddMobileIcon className="mobile-tour-step-1" />,
                    onClick: openAddItemsDialog,
                  },
                  {
                    icon: <InstructionsIcon />,
                    onClick: openInstructionsDialog,
                  },
                  {
                    icon: (
                      <Badge
                        badgeContent={topicVM?.unSeenActivity ? "!" : undefined}
                        color="error"
                        showZero
                      >
                        <NotificationsIcon />
                      </Badge>
                    ),
                    onClick: openActivityDialog,
                  },
                  {
                    icon: <EmailMobileIcon />,
                    onClick: openSendGeneralMessageDialog,
                  },
                ],
              })}
            </MobileNavigationBar>

            {/* DIALOGS */}
            <AddItemsDialog
              isOpen={isAddItemsDialogOpen}
              topicId={topicIdAsNumber}
              existingItemTypes={itemsVM.map((i) => i.itemType)}
              loading={loadingItems}
              reasons={reasonsItems}
              addItems={addItems}
              onClose={closeAddItemsDialogWrapper}
            />

            <SendGeneralMessageDialog
              isOpen={isSendGeneralMessageOpen}
              onClose={closeSendGeneralMessageDialog}
              onSend={handleSendMessage}
            />

            <InstructionsDialog
              isOpen={isInstructionsDialogOpen}
              onClose={closeInstructionsDialog}
            />

            <ActivitiesDialog
              isOpen={isActivityDialogOpen}
              topicId={topicIdAsNumber}
              onClose={closeActivityDialogWrapper}
            />
          </>
        )}
      </ItemsContainer>
      <CommonSnackbar
        isOpen={isSnackbarOpen}
        title="E-mailbericht is verzonden."
        onClose={closeSnackbar}
      />
    </>
  );
};

const useStyles = makeStyles((theme: any) => ({}));

export default TopicStartPage;

interface RouteParams {
  topicId: string;
}

const tourSteps: Step[] = [
  {
    target: ".tour-step-1",
    title: "Blokkies toevoegen",
    content:
      "Voeg één of meerdere blokkies toe. Dit zijn hulpmiddelen die jullie als groep kunnen gebruiken om het regelen makkelijker te maken. Denk bv. aan een Datum blokkie, waarmee je als groep eenvoudig een datum kunt kiezen.",
    disableBeacon: true,
  },
  {
    target: ".tour-step-2",
    title: "Gebruikers uitnodigen",
    content: "Nodig familie, vrienden, kennisen uit en regel het samen.",
  },
  {
    target: ".tour-step-3",
    title: "Titel aanpassen",
    content: "Je kunt hier de titel of omschrijving van je Plokkie aanpassen.",
  },
];
const mobileTourSteps: Step[] = [
  {
    target: ".mobile-tour-step-1",
    title: "Blokkies toevoegen",
    content:
      "Voeg één of meerdere blokkies toe. Dit zijn hulpmiddelen die jullie als groep kunnen gebruiken om het regelen makkelijker te maken. Denk bv. aan een Datum blokkie, waarmee je als groep eenvoudig een datum kunt kiezen.",
    disableBeacon: true,
  },
  {
    target: ".mobile-tour-step-2",
    title: "Gebruikers uitnodigen",
    content: "Nodig familie, vrienden, kennisen uit en regel het samen.",
  },
  {
    target: ".tour-step-3",
    title: "Titel aanpassen",
    content: "Je kunt hier de titel of omschrijving van je Plokkie aanpassen.",
  },
];
