import * as React from "react";

import {
  GenericVoteType,
  LocationItemModel,
  LocationItemService,
  LocationPropositionRemarksModel,
  Result,
} from "../../../application";

import { useLocationItemService } from "../../../presentation";

interface HookState {
  locationItemModel: LocationItemModel | null;
  remarksModel: LocationPropositionRemarksModel | null;
  loading: boolean;
  reasons: string[] | null;
}

interface HookMethods extends LocationItemService {}

export const useLocationItemUsecases = (): [HookState, HookMethods] => {
  const [locationItemSvc] = useLocationItemService();
  const [locationItemModel, setLocationItemModel] =
    React.useState<LocationItemModel | null>(null);
  const [remarksModel, setRemarksModel] =
    React.useState<LocationPropositionRemarksModel | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [reasons, setReasons] = React.useState<string[] | null>(null);

  const getLocationItem = async (
    itemId: number
  ): Promise<Result<LocationItemModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await locationItemSvc.getLocationItem(itemId);
    setLoading(false);

    if (result.isSuccess && result.value) setLocationItemModel(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const addlocationProposition = async (
    locationItemId: number,
    name: string,
    locationLink: string,
    streetName: string,
    zipCode: string,
    location: string
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await locationItemSvc.addlocationProposition(
      locationItemId,
      name,
      locationLink,
      streetName,
      zipCode,
      location
    );
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const removeLocationProposition = async (
    propositionId: number
  ): Promise<Result> => {
    setReasons(null);
    const result = await locationItemSvc.removeLocationProposition(
      propositionId
    );

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const updateLocationProposition = async (
    propositionId: number,
    name: string,
    locationLink: string,
    streetName: string,
    zipCode: string,
    location: string
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await locationItemSvc.updateLocationProposition(
      propositionId,
      name,
      locationLink,
      streetName,
      zipCode,
      location
    );
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const vote = async (
    propositionId: number,
    voteType: GenericVoteType
  ): Promise<Result> => {
    setReasons(null);
    const result = await locationItemSvc.vote(propositionId, voteType);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const getRemarks = async (
    propositionId: number
  ): Promise<Result<LocationPropositionRemarksModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await locationItemSvc.getRemarks(propositionId);
    setLoading(false);

    if (result.isSuccess && result.value) setRemarksModel(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const addRemark = async (
    propositionId: number,
    text: string
  ): Promise<Result> => {
    setReasons(null);
    const result = await locationItemSvc.addRemark(propositionId, text);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const removeRemark = async (remarkId: number): Promise<Result> => {
    setReasons(null);
    const result = await locationItemSvc.removeRemark(remarkId);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const confirm = async (propositionId: number): Promise<Result> => {
    setReasons(null);
    const result = await locationItemSvc.confirm(propositionId);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  return [
    { locationItemModel, remarksModel, loading, reasons },
    {
      getLocationItem,
      addlocationProposition,
      removeLocationProposition,
      updateLocationProposition,
      vote,
      getRemarks,
      addRemark,
      removeRemark,
      confirm,
    },
  ];
};
