import * as React from "react";

import { Backdrop, makeStyles } from "@material-ui/core";

import { LoadingSpinner } from "../../../../presentation";

export interface LoadingBackdropProps {
  show: boolean;
  size?: number;
}

export const LoadingBackdrop: React.FC<LoadingBackdropProps> = ({
  show,
  size = 50,
}) => {
  const classes = useStyles();

  return (
    <Backdrop open={show} className={classes.backdrop}>
      <div className={classes.spinnerContainer}>
        <LoadingSpinner size={size} />
      </div>
    </Backdrop>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 999999,
    position: "absolute",
  },
  spinnerContainer: {},
}));

export default LoadingBackdrop;
