import * as React from "react";

import {
  ButtonBar,
  ButtonItem,
  CommonButton,
  CommonIconButton,
  CommonMenu,
  CommonPaper,
  ContainerLarge,
  HeaderNormal,
  HideOnMobile,
  ItemStatusBar,
  LoadingScreen,
  LocationPropositionList,
  MobileNavigationBar,
  MobileOnly,
  OpenActionsReasonsBox,
  Paragraph,
  PinIcon,
  RemarksDialog,
  SectionBox,
  useDialog,
  useLocationItemUsecases,
} from "../../../../presentation";
import {
  GenericVoteType,
  ItemType,
  LocationPropositionModel,
  Result,
} from "../../../../application";
import { makeStyles, useTheme } from "@material-ui/core";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import AddIcon from "@material-ui/icons/AddCircle";
import AddMobileIcon from "@material-ui/icons/AddCircleOutline";
import BackIcon from "@material-ui/icons/ArrowBack";
import CancelMobileIcon from "@material-ui/icons/CloseOutlined";
import DatePinnedIcon from "@material-ui/icons/Done";
import SortIcon from "@material-ui/icons/SortOutlined";
import UndoIcon from "@material-ui/icons/Replay";

export interface LocationItemPageProps {}

interface RouterParams {
  topicId: string;
  locationItemId: string;
}

export const LocationItemPage: React.FC<LocationItemPageProps> = () => {
  // STYLES
  const classes = useStyles();
  const theme = useTheme();

  // ROUTER
  const params = useParams<RouterParams>();
  const { push } = useHistory();
  const { url } = useRouteMatch();

  // STATE
  const [selectionMode, setSelectionMode] = React.useState(false);
  const [sortType, setSortType] = React.useState<number>(1);
  const [remarksParentId, setRemarksParentId] = React.useState<number>(0);

  // USECASES
  const [
    { locationItemModel, loading, reasons, remarksModel },
    {
      getLocationItem,
      confirm,
      removeLocationProposition,
      vote,
      getRemarks,
      addRemark,
      removeRemark,
    },
  ] = useLocationItemUsecases();

  // DIALOGS
  const [
    { isOpen: isRemarksDialogOpen },
    { open: openRemarksDialog, close: closeRemarksDialog },
  ] = useDialog();

  // VARIABLES
  const topicId = parseInt(params.topicId);
  const locationItemId = parseInt(params.locationItemId);
  const backUrl = `/topics/${topicId}`;
  const newPropositionUrl = `${url}/new-proposition`;
  const updatePropositionUrl = (propositionId: number) =>
    `${url}/propositions/${propositionId}/update`;
  const propositionRemarksUrl = (propositionId: number) =>
    `${url}/propositions/${propositionId}/remarks`;

  React.useEffect(() => {
    loadItem();
  }, []);

  // METHODS
  const handleNavigateBack = () => {
    push(backUrl);
  };

  const handleNavigateNewProposition = () => {
    push(newPropositionUrl);
  };

  const loadItem = async () => {
    if (!locationItemId) return;

    const result = await getLocationItem(locationItemId);

    if (result.value && result.value.done === true) setSortType(2);
  };

  const handleOpenRemarks = async (id: number) => {
    await getRemarks(id);
    setRemarksParentId(id);
    openRemarksDialog();
  };

  const handleRemarksSend = async (message: string): Promise<Result> => {
    const result = await addRemark(remarksParentId, message);

    if (result.isSuccess) await getRemarks(remarksParentId);

    return result;
  };

  const handleRemarksDelete = async (remarkId: number) => {
    const result = await removeRemark(remarkId);

    if (result.isSuccess) await getRemarks(remarksParentId);
  };

  const handleRemarksClose = () => {
    loadItem();
    closeRemarksDialog();
  };

  const handleConfirm = async (propositionId: number) => {
    const result = await confirm(propositionId);
    setSelectionMode(false);

    if (result.isSuccess) loadItem();
  };

  const handleEdit = async (propositionId: number) => {
    const url = updatePropositionUrl(propositionId);

    push(url);
  };

  const handleRemove = async (propositionId: number) => {
    const result = await removeLocationProposition(propositionId);

    if (result.isSuccess) loadItem();
  };

  const handleVote = async (
    propositionId: number,
    voteType: GenericVoteType
  ) => {
    const result = await vote(propositionId, voteType);

    if (result.isSuccess) loadItem();
  };

  const handleUnConfirm = async () => {
    const proposition = locationItemModel?.propositions.find(
      (dp) => dp.pinned === true
    );
    if (!proposition) return;

    await handleConfirm(proposition.id);
  };

  const renderDefinitiveButton = (mobile: boolean = false) => {
    if (locationItemModel && !locationItemModel.done && !selectionMode) {
      const style: React.CSSProperties = {
        color: "#fff",
      };

      return (
        <CommonButton
          color={mobile ? "primary" : undefined}
          disabled={!locationItemModel.propositions.length}
          label="Prik"
          startIcon={<PinIcon style={mobile ? style : undefined} />}
          variant={mobile ? "contained" : "outlined"}
          onClick={() => setSelectionMode(true)}
        />
      );
    }

    if (selectionMode) {
      return (
        <CommonButton
          label="Annuleer"
          variant="contained"
          className={classes.cancelSelectionButton}
          onClick={() => setSelectionMode(false)}
        />
      );
    }

    if (locationItemModel && locationItemModel.done) {
      return (
        <CommonButton
          color="primary"
          label="Geprikt"
          startIcon={<DatePinnedIcon />}
          onClick={handleUnConfirm}
        />
      );
    }

    return null;
  };

  const renderButton = (): ButtonItem => {
    let icon = null;
    let onClickHandler = () => {};

    if (locationItemModel && !locationItemModel.done && !selectionMode) {
      icon = <PinIcon />;
      onClickHandler = () => setSelectionMode(true);
    } else if (!locationItemModel?.done && selectionMode) {
      icon = <CancelMobileIcon style={{ color: theme.palette.error.main }} />;
      onClickHandler = () => setSelectionMode(false);
    } else {
      icon = <UndoIcon />;
      onClickHandler = handleUnConfirm;
    }

    return {
      icon: icon,
      onClick: onClickHandler,
    };
  };

  const sortPropositions = (
    propositions: LocationPropositionModel[],
    sortType: number
  ) => {
    switch (sortType) {
      case 1:
        return propositions.sort((a, b) => {
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;

          return 0;
        });
      case 2:
        return propositions.sort((a, b) => {
          const totalPointsA = a.votes.reduce((previous, current) => {
            let points = 0;

            switch (current.voteType) {
              case GenericVoteType.Yes:
                points = 2;
                break;
              case GenericVoteType.Maybe:
                points = 1;
                break;
            }

            return previous + points;
          }, 0);
          const totalPointsB = b.votes.reduce((previous, current) => {
            let points = 0;

            switch (current.voteType) {
              case GenericVoteType.Yes:
                points = 2;
                break;
              case GenericVoteType.Maybe:
                points = 1;
                break;
            }

            return previous + points;
          }, 0);

          if (totalPointsA > totalPointsB) return -1;
          if (totalPointsA < totalPointsB) return 1;

          return 0;
        });
      default:
        return propositions;
    }
  };

  return (
    <ContainerLarge>
      {locationItemModel && (
        <ItemStatusBar
          itemType={ItemType.Location}
          done={locationItemModel.done}
        />
      )}

      <MobileOnly>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <HeaderNormal
              title="Locatie"
              disableGutter
              style={{ margin: "8px 0" }}
            />
          </div>
          <div>
            <CommonMenu>
              {({ open }) => ({
                button: <CommonIconButton icon={<SortIcon />} onClick={open} />,
                items: [
                  {
                    label: "Aangemaakt op",
                    selected: sortType === 1,
                    handleClick: () => setSortType(1),
                  },
                  {
                    label: "Beste score",
                    selected: sortType === 2,
                    handleClick: () => setSortType(2),
                  },
                ],
              })}
            </CommonMenu>
          </div>
        </div>
      </MobileOnly>

      <HideOnMobile>
        <SectionBox>
          <CommonButton
            label="Naar overzicht"
            startIcon={<BackIcon />}
            variant="text"
            onClick={handleNavigateBack}
          />
        </SectionBox>

        <SectionBox>
          <HeaderNormal title="Locatie" />
          <Paragraph>
            Voeg locaties toe en kies de beste bestemming voor jullie Plokkie.
          </Paragraph>
        </SectionBox>
      </HideOnMobile>

      {locationItemModel && locationItemModel.hasOpenActions && (
        <SectionBox>
          <OpenActionsReasonsBox
            reasons={[
              "Er zijn nog locatievoorstel(len) waar jij niet op hebt gestemd",
            ]}
          />
        </SectionBox>
      )}

      <CommonPaper>
        <HideOnMobile>
          <SectionBox>
            <ButtonBar>
              <CommonButton
                disabled={!locationItemModel ? true : locationItemModel.done}
                label="Toevoegen"
                startIcon={<AddIcon />}
                onClick={handleNavigateNewProposition}
              />

              {renderDefinitiveButton()}

              <CommonMenu>
                {({ open }) => ({
                  button: (
                    <CommonButton
                      label="Sorteren"
                      startIcon={<SortIcon />}
                      onClick={open}
                    />
                  ),
                  items: [
                    {
                      label: "Aangemaakt op",
                      selected: sortType === 1,
                      handleClick: () => setSortType(1),
                    },
                    {
                      label: "Beste score",
                      selected: sortType === 2,
                      handleClick: () => setSortType(2),
                    },
                  ],
                })}
              </CommonMenu>
            </ButtonBar>
          </SectionBox>
        </HideOnMobile>

        {loading && !locationItemModel && <LoadingScreen />}
        {locationItemModel && locationItemModel.propositions.length > 0 && (
          <SectionBox>
            <LocationPropositionList
              propositions={sortPropositions(
                locationItemModel.propositions,
                sortType
              )}
              selectionMode={selectionMode}
              done={locationItemModel.done}
              onOpenRemarks={handleOpenRemarks}
              onConfirm={handleConfirm}
              onEdit={handleEdit}
              onRemove={handleRemove}
              onVote={handleVote}
            />
          </SectionBox>
        )}

        {locationItemModel && locationItemModel.propositions.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <Paragraph>Er zijn nog geen locaties toegevoegd.</Paragraph>
          </div>
        )}
      </CommonPaper>

      <MobileNavigationBar>
        {() => ({
          buttons: [
            renderButton(),
            {
              icon: <AddMobileIcon />,
              disabled:
                !locationItemModel || selectionMode
                  ? true
                  : locationItemModel.done,
              onClick: handleNavigateNewProposition,
            },
            { icon: <BackIcon />, onClick: handleNavigateBack },
          ],
        })}
      </MobileNavigationBar>

      <RemarksDialog
        open={isRemarksDialogOpen}
        subject={remarksModel?.subject}
        remarks={remarksModel?.remarks}
        onClose={handleRemarksClose}
        onSend={handleRemarksSend}
        onDelete={handleRemarksDelete}
      />
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  cancelSelectionButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export default LocationItemPage;
