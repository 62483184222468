import * as React from "react";

import {
  ButtonBar,
  CommonIconButton,
  SmileExcellentIcon,
  SmileLikeIcon,
  SmileNeutralIcon,
} from "../../../../../../presentation";
import { makeStyles, useTheme } from "@material-ui/core";

import { GenericVoteType } from "../../../../../../application";
import Stars from "./stars";

export interface VotingProps {
  voteAmountYes: string;
  voteAmountNo: string;
  voteAmountMaybe: string;
  locked: boolean;
  userVoteType: GenericVoteType | null;
  selectionMode?: boolean;
  disableStars?: boolean;
  small?: boolean;
  onVote(voteType: GenericVoteType): Promise<void>;
}

export const Voting: React.FC<VotingProps> = ({
  voteAmountYes,
  voteAmountNo,
  voteAmountMaybe,
  locked,
  userVoteType,
  selectionMode = false,
  disableStars = false,
  small = false,
  onVote,
}) => {
  // STYLES
  const classes = useStyles();
  const theme = useTheme();
  const iconSize = "inherit";

  return (
    <div className={`${classes.votingWrapper} ${small ? "small" : ""}`}>
      <ButtonBar justifyContent="space-around">
        <div>
          <CommonIconButton
            badgeContent={`${voteAmountYes}x`}
            badgeColor="default"
            disabled={locked || userVoteType === GenericVoteType.Yes}
            icon={<SmileExcellentIcon fontSize={iconSize} />}
            className={`${classes.votingButton} ${small ? "small" : ""} ${
              classes.votingIconButtonExcellent
            } ${userVoteType === GenericVoteType.Yes && "selected"}`}
            onClick={() => onVote(GenericVoteType.Yes)}
          />
          {!disableStars && (
            <Stars
              amount={3}
              className={`${classes.stars} ${
                userVoteType === GenericVoteType.Yes && "selected"
              }`}
            />
          )}
        </div>
        <div>
          <CommonIconButton
            badgeContent={`${voteAmountMaybe}x`}
            badgeColor="default"
            disabled={locked || userVoteType === GenericVoteType.Maybe}
            icon={<SmileLikeIcon fontSize={iconSize} />}
            className={`${classes.votingButton} ${small ? "small" : ""} ${
              classes.votingIconButtonLike
            } ${userVoteType === GenericVoteType.Maybe && "selected"}`}
            onClick={() => onVote(GenericVoteType.Maybe)}
          />
          {!disableStars && (
            <Stars
              amount={2}
              className={`${classes.stars} ${
                userVoteType === GenericVoteType.Maybe && "selected"
              }`}
            />
          )}
        </div>
        <div>
          <CommonIconButton
            badgeContent={`${voteAmountNo}x`}
            badgeColor="default"
            disabled={locked || userVoteType === GenericVoteType.No}
            icon={<SmileNeutralIcon fontSize={iconSize} />}
            className={`${classes.votingButton} ${small ? "small" : ""} ${
              classes.votingIconButtonNeutral
            } ${userVoteType === GenericVoteType.No && "selected"}`}
            onClick={() => onVote(GenericVoteType.No)}
          />
          {!disableStars && (
            <Stars
              amount={1}
              className={`${classes.stars} ${
                userVoteType === GenericVoteType.No && "selected"
              }`}
            />
          )}
        </div>
      </ButtonBar>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  votingWrapper: {
    "&.small": {
      "& .MuiSvgIcon-root": {
        fontSize: "1.5rem",
      },
      "& .MuiBadge-badge": {
        fontSize: 10,
      },
    },
  },
  votingAmount: {
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 5,
    marginLeft: 4,
  },
  votingButton: {
    color: "#d3d3d3",
    transition: "background-color 1000ms",
    "& .MuiSvgIcon-root": {
      fontSize: "2.5rem",
    },
    "& .MuiBadge-badge": {
      color: theme.palette.text.primary,
    },
    "&.small": {
      padding: "0 6px",
    },
    zIndex: 10,
  },
  votingIconButtonExcellent: {
    "&.selected": {
      color: "#23a936",
    },
    "&:hover": {
      color: "#23a936",
    },
  },
  votingIconButtonLike: {
    "&.selected": {
      color: theme.palette.primary.light,
    },
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
  votingIconButtonNeutral: {
    "&.selected": {
      color: theme.palette.warning.main,
    },
    "&:hover": {
      color: theme.palette.warning.main,
    },
  },
  textSelectionMode: {
    position: "relative",
    zIndex: 10,
  },
  stars: {
    color: "#d3d3d3",
    "&.selected": {
      color: "#fdcc0d",
    },
  },
}));

export default Voting;
