import { injectable, inject } from "tsyringe";
import jwtDecoder from "jwt-decode";

import {
  INJECTION_TOKENS,
  PayloadModel,
  TokenService,
  type DeviceStorageRepository,
} from "../../../application";

@injectable()
export class TokenServiceImpl implements TokenService {
  private readonly TOKEN_STORAGE_KEY: string = "token";

  constructor(
    @inject(INJECTION_TOKENS.WebStorage)
    private deviceStorage: DeviceStorageRepository
  ) {}

  saveToken(token: string): void {
    this.deviceStorage.setItem(this.TOKEN_STORAGE_KEY, token);
  }

  getToken(): string | null {
    return this.deviceStorage.getItem(this.TOKEN_STORAGE_KEY);
  }

  getTokenPayload(): PayloadModel | null {
    const token = this.getToken();
    if (token == null) return null;

    let payload: PayloadModel | null = null;
    try {
      payload = jwtDecoder<PayloadModel>(token);
    } catch (error) {
      console.log(error);
    }

    return payload;
  }

  clearToken(): void {
    this.deviceStorage.removeItem(this.TOKEN_STORAGE_KEY);
  }
}
