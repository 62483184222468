import * as React from "react";

import { CommonIconButton, PinIcon } from "../../../../../../presentation";

import { makeStyles } from "@material-ui/core";

export interface ConfirmIconButtonProps {
  locked: boolean;
  style?: React.CSSProperties;
  onClick(): void;
}

export const ConfirmIconButton: React.FC<ConfirmIconButtonProps> = ({
  locked,
  style,
  onClick,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <CommonIconButton
      icon={<PinIcon />}
      className={`${classes.confirmButton}`}
      style={style}
      size="small"
      onClick={onClick}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    zIndex: 10,
  },
  cardHeaderPinButtonLocked: {
    color: "#fff",
  },
}));

export default ConfirmIconButton;
