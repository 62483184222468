import "./App.css";

import { MainContent, NavigationBarView } from "../presentation";

import React from "react";
import ReactGA from "react-ga4";
import { BrowserRouter as Router } from "react-router-dom";

export function App() {
  ReactGA.initialize("G-Z93MVJ4S92");

  return (
    <div className="App">
      <Router>
        {/* <NavigationBarView /> */}
        <MainContent />
      </Router>
    </div>
  );
}

export default App;
