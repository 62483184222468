import * as React from "react";

import {
  ContainerLarge,
  HeaderLarge,
  HeaderNormal,
  LoadingScreen,
  Paragraph,
  ReasonList,
  RegistrationForm,
  SectionBox,
  Text,
  TopicRegistrationContainer,
  UserContext,
} from "../../../presentation";
import { useHistory, useParams } from "react-router-dom";

import { Result } from "../../../application";

export interface SignupPageProps {}

export const SignupPage: React.FC<SignupPageProps> = () => {
  // ROUTER
  const routeParams = useParams<RouteParams>();
  const history = useHistory();

  // CONTEXT
  const { user } = React.useContext(UserContext);

  // VARIABLES
  const { topicId, registrationKey } = routeParams;
  const topicIdAsNumber = parseInt(topicId);

  // METHODS
  const navigateOnSuccess = (signupPromise: Promise<Result>): void => {
    signupPromise.then((result) => {
      if (result.isSuccess) {
        history.replace("/members/sign-up-success");
      }
    });
  };

  return (
    <>
      <TopicRegistrationContainer
        topicId={topicIdAsNumber}
        registrationKey={registrationKey}
      >
        {({
          viewModel,
          loadingGetByRegistrationKey,
          loadingSignup,
          reasons,
          signup,
        }) => (
          <ContainerLarge>
            <>{loadingGetByRegistrationKey && <LoadingScreen />}</>

            <>
              {!loadingGetByRegistrationKey && viewModel && (
                <>
                  <SectionBox>
                    <HeaderNormal title="Uitnodiging voor:" />
                    <HeaderLarge
                      title={viewModel.title}
                      color="primary"
                      style={{ marginBottom: 0 }}
                    />
                    <Text>Aangemaakt door: {viewModel.adminName}</Text>
                  </SectionBox>

                  <SectionBox>
                    <Paragraph>
                      Vul je gegevens in om de uitnodiging te accepteren.
                    </Paragraph>
                    <RegistrationForm
                      loading={loadingSignup}
                      email={user?.email}
                      onSubmit={(model) =>
                        navigateOnSuccess(
                          signup(topicIdAsNumber, registrationKey, model)
                        )
                      }
                    />
                  </SectionBox>
                </>
              )}
            </>

            <>
              <ReasonList reasons={reasons} />
            </>
          </ContainerLarge>
        )}
      </TopicRegistrationContainer>
    </>
  );
};

export default SignupPage;

interface RouteParams {
  topicId: string;
  registrationKey: string;
}
