import * as React from "react";

import {
  Aligner,
  CommonButton,
  CommonIconButton,
  ContainerLarge,
  EasyToUseImage,
  HeaderExtraSmall,
  HeaderLarge,
  HeaderMedium,
  HeaderNormal,
  HeaderSmall,
  HeroImage,
  HideOnMobile,
  HomeImage5,
  ItemTypeImage,
  OrdenedImage,
  OrganizeImage,
  Paragraph,
  PeopleImage,
  ProgressionImage,
  PuppetImage1,
  PuppetImage2,
  PuppetImage3,
  SectionBox,
  SectionSmall,
} from "../../../presentation";
import {
  Box,
  Button,
  Grid,
  Link,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import EmailIcon from "@material-ui/icons/Email";
import InstagramIcon from "@material-ui/icons/Instagram";
import { ItemType } from "../../../application";
import { useHistory } from "react-router-dom";

export interface HomePageProps {}

export const HomePage: React.FC<HomePageProps> = () => {
  // HOOKS
  const { push } = useHistory();

  // STYLES
  const classes = useStyles();
  const theme = useTheme();

  // VARIABLES
  const currentYear = new Date().getFullYear();

  // METHODS
  const handleNavigation = (url: string) => {
    push(url);
  };

  const handleBrowserNavigation = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <section className={classes.heroSection}>
        <div className={classes.sectionContent} style={{ paddingTop: 0 }}>
          <ContainerLarge>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={6}>
                <SectionBox>
                  <Box display="flex" alignItems="baseline">
                    <HeaderLarge
                      title="Plokkie"
                      className={classes.heroTitle}
                    />
                    <div style={{ marginLeft: 32 }}>
                      <img src={PuppetImage2} style={{ maxHeight: 100 }} />
                    </div>
                  </Box>
                  <HeaderNormal
                    title="Ondersteuning met een blokkie"
                    className={classes.heroSubtitle}
                  />
                </SectionBox>

                <SectionBox>
                  <HeaderExtraSmall
                    title={
                      <>
                        <p>
                          Plokkie maakt groepsactiviteiten organiseren simpel én
                          plezierig! Samen een geschikte datum prikken, de
                          perfecte locatie kiezen en kosten eenvoudig verdelen.
                        </p>
                        <p>
                          Ontdek de kracht van Plokkie door de App nu te
                          downloaden voor zowel iOS als Android en breng je
                          vrienden en familie samen voor onvergetelijke
                          momenten! Heb je feedback? We horen het graag!
                        </p>
                      </>
                    }
                    className={classes.heroParagraph}
                  />
                </SectionBox>

                <SectionSmall>
                  <Box display="flex">
                    <div
                      className={classes.heroButtonWrapper}
                      style={{ flexGrow: 1 }}
                    >
                      <CommonButton
                        color="primary"
                        label="Download de App"
                        size="large"
                        variant="contained"
                        className={classes.heroButton}
                        onClick={() => handleNavigation("/redirect")}
                      />
                    </div>
                    {/* <HideOnMobile>
                      <div style={{ marginTop: -65 }}>
                        <img src={PuppetImage3} style={{ maxHeight: 175 }} />
                      </div>
                    </HideOnMobile> */}
                  </Box>
                </SectionSmall>

                {/* <HideOnMobile>
                  <div>
                    <img src={PuppetImage1} style={{ maxHeight: 150 }} />
                  </div>
                </HideOnMobile> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ maxWidth: 500, margin: "auto" }}>
                  <img src={HeroImage} className={classes.image} />
                </div>
              </Grid>
            </Grid>
          </ContainerLarge>
        </div>
      </section>

      <section style={{ backgroundColor: "#fff" }}>
        <div className={classes.sectionContent}>
          <ContainerLarge>
            <SectionSmall>
              <div className={classes.sectionTextMiddle}>
                <HeaderLarge title="Plan het samen" />
                <HeaderExtraSmall title="In Plokkie kunnen alle deelnemers actief meedenken. Bepaal zelf wie jij uitnodigt en regel het samen! Van familiereünie tot vrijgezellenfeest en van buurtbarbecue tot vriendenweekend, de mogelijkheden zijn eindeloos!" />
              </div>
            </SectionSmall>

            <SectionSmall>
              <div style={{ maxWidth: 750, margin: "auto" }}>
                <img src={OrganizeImage} className={classes.image} />
              </div>
            </SectionSmall>
          </ContainerLarge>
        </div>
      </section>

      <section className={classes.sectionPlokkie}>
        <div className={classes.overlay}></div>
        <div className={classes.sectionContent} style={{ padding: "15rem 0" }}>
          <ContainerLarge>
            <div className={classes.sectionTextMiddle}>
              <HeaderLarge
                title="Beleef samen onvergetelijke momenten."
                style={{ color: "#fff" }}
              />
              {/* <HeaderExtraSmall
                title="Een plukje mensen waarmee je graag iets wil regelen."
                style={{ color: "#fff" }}
              /> */}
            </div>
          </ContainerLarge>
        </div>
      </section>

      <section>
        <div className={classes.sectionContent} style={{ padding: "10rem 0" }}>
          <ContainerLarge>
            <SectionBox>
              <div className={classes.sectionTextMiddle}>
                <HeaderLarge title="Wat kun je ermee" />
                <HeaderExtraSmall
                  title={
                    <>
                      <div>
                        We hebben allerlei hulpmiddelen geïntroduceerd om het
                        regelen makkelijker te maken, denk bijvoorbeeld aan:
                      </div>
                    </>
                  }
                />
              </div>
            </SectionBox>

            <SectionBox>
              <Grid container justify="center">
                <Grid item xs={12} md={4}>
                  <div className={classes.exampleWrapper}>
                    <div className={classes.exampleImageWrapper}>
                      <ItemTypeImage
                        itemType={ItemType.Date}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                    <div>
                      <HeaderSmall color="primary" title="Datum kiezer" />
                    </div>
                    <div>
                      <Paragraph>
                        Voeg datums toe en prik met je groep de perfecte datum!
                      </Paragraph>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className={classes.exampleWrapper}>
                    <div className={classes.exampleImageWrapper}>
                      <ItemTypeImage
                        itemType={ItemType.Location}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                    <div>
                      <HeaderSmall color="primary" title="Locatie kiezer" />
                    </div>
                    <div>
                      <Paragraph>
                        Voeg locaties toe en kies jullie ideale bestemming!
                      </Paragraph>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className={classes.exampleWrapper}>
                    <div className={classes.exampleImageWrapper}>
                      <ItemTypeImage
                        itemType={ItemType.Costs}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                    <div>
                      <HeaderSmall color="primary" title="Kosten beheer" />
                    </div>
                    <div>
                      <Paragraph>
                        Beheer je uitgaven in een handomdraai en verdeel ze
                        zonder gedoe!
                      </Paragraph>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </SectionBox>
          </ContainerLarge>
        </div>
      </section>

      <section style={{ backgroundColor: "#fff" }}>
        <div className={classes.sectionContent}>
          <ContainerLarge>
            <SectionBox>
              <HeaderLarge
                title="We zetten de voordelen voor je op een rijtje"
                style={{
                  textAlign: "center",
                }}
              />
            </SectionBox>
          </ContainerLarge>
        </div>
      </section>

      <section style={{ backgroundColor: "#fff" }}>
        <div className={classes.sectionContent}>
          <ContainerLarge>
            <SectionBox>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={6} className={classes.columnSecondOrder}>
                  <SectionBox>
                    <div style={{ maxWidth: 400, margin: "auto" }}>
                      <img src={OrdenedImage} className={classes.image} />
                    </div>
                  </SectionBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SectionBox>
                    <div style={{ textAlign: "center" }}>
                      <HeaderMedium bold title="Overzicht" />
                      <HeaderExtraSmall
                        title="Zeg vaarwel tegen het eindeloos zoeken in WhatsApp-groepen! Plokkie brengt overzicht en gemak in één handige app."
                        style={{ fontWeight: 400 }}
                      />
                    </div>
                  </SectionBox>
                </Grid>
              </Grid>
            </SectionBox>
          </ContainerLarge>
        </div>
      </section>

      <section style={{ backgroundColor: "#fff" }}>
        <div className={classes.sectionContent}>
          <ContainerLarge>
            <SectionBox>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={6}>
                  <SectionBox>
                    <div style={{ textAlign: "center" }}>
                      <HeaderMedium bold title="Keuzes maken" />
                      <HeaderExtraSmall
                        title="Plokkie maakt groepsbeslissingen makkelijk, snel en aangenaam!"
                        style={{ fontWeight: 400 }}
                      />
                    </div>
                  </SectionBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SectionBox>
                    <div style={{ height: 400, margin: "auto" }}>
                      <img src={ProgressionImage} className={classes.image} />
                    </div>
                  </SectionBox>
                </Grid>
              </Grid>
            </SectionBox>
          </ContainerLarge>
        </div>
      </section>

      <section style={{ backgroundColor: "#fff" }}>
        <div className={classes.sectionContent}>
          <ContainerLarge>
            <SectionBox>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={6} className={classes.columnSecondOrder}>
                  <SectionBox>
                    <div style={{ maxWidth: 400, margin: "auto" }}>
                      <img src={EasyToUseImage} className={classes.image} />
                    </div>
                  </SectionBox>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SectionBox>
                    <div style={{ textAlign: "center" }}>
                      {/* <HeaderMedium bold title="Eenvoudig in gebruik" /> */}
                      <HeaderMedium bold title="Gebruiksvriendelijkheid" />
                      {/* <HeaderExtraSmall
                        title="Kom je er toch niet helemaal aan uit? Geen probleem, we hebben ook tips geplaatst."
                        style={{ fontWeight: 400 }}
                      /> */}
                      <HeaderExtraSmall
                        title="Bij Plokkie is gebruiksvriendelijkheid onze prioriteit. We streven naar eenvoud en toegankelijkheid. Als er iets onduidelijk is of verbeterd kan worden, laat het ons weten. We staan klaar om jouw ervaring te verbeteren!"
                        style={{ fontWeight: 400 }}
                      />
                    </div>
                  </SectionBox>
                </Grid>
              </Grid>
            </SectionBox>
          </ContainerLarge>
        </div>
      </section>

      <section style={{ backgroundColor: "#fff" }}>
        <div className={classes.sectionContent}>
          <ContainerLarge>
            <SectionBox>
              <div style={{ maxWidth: 600, margin: "auto" }}>
                <img
                  src={HomeImage5}
                  className={classes.image}
                  style={{ borderRadius: 100 }}
                />
              </div>
            </SectionBox>

            <SectionBox>
              <div style={{ textAlign: "center" }}>
                <HeaderMedium bold title="Het is gratis!" />
                <HeaderExtraSmall
                  title="Plokkie is 100% gratis! Probeer het vandaag en ontdek de voordelen!😊🎉"
                  style={{ fontWeight: 400 }}
                />
                <SectionSmall>
                  <div className={classes.heroButtonWrapper}>
                    <CommonButton
                      color="primary"
                      label="Download de App"
                      size="large"
                      variant="contained"
                      className={classes.heroButton}
                      onClick={() => handleNavigation("/redirect")}
                    />
                  </div>
                </SectionSmall>
              </div>
            </SectionBox>
          </ContainerLarge>
        </div>
      </section>

      <section style={{ backgroundColor: theme.palette.text.primary }}>
        <div className={classes.sectionContent}>
          <ContainerLarge>
            <SectionSmall>
              <div className={classes.footerContentWrapper}>
                <div>
                  <ul className={classes.footerLinkList}>
                    {/* <li>
                      <HeaderExtraSmall
                        title={
                          <span onClick={() => handleNavigation("/how-to")}>
                            Hoe werkt het
                          </span>
                        }
                      />
                    </li> */}
                    <li>
                      <HeaderExtraSmall
                        title={
                          <span onClick={() => handleNavigation("/about-us")}>
                            Over ons
                          </span>
                        }
                      />
                    </li>
                    <li>
                      <HeaderExtraSmall
                        title={
                          <span onClick={() => handleNavigation("/contact")}>
                            Feedback en contact
                          </span>
                        }
                      />
                    </li>
                    {/* <li>
                      <HeaderExtraSmall
                        title={
                          <span onClick={() => handleNavigation("/roadmap")}>
                            Roadmap
                          </span>
                        }
                      />
                    </li> */}
                  </ul>
                </div>
                <div>
                  <CommonIconButton
                    icon={<InstagramIcon fontSize="large" />}
                    style={{ color: "#fff" }}
                    onClick={() =>
                      handleBrowserNavigation(
                        "https://www.instagram.com/team_plokkie/"
                      )
                    }
                  />
                  <CommonIconButton
                    icon={<EmailIcon fontSize="large" />}
                    style={{ color: "#fff" }}
                    onClick={() =>
                      handleBrowserNavigation("mailto:info@plokkie.nl")
                    }
                  />
                </div>
              </div>
            </SectionSmall>

            <SectionBox>
              <div style={{ textAlign: "center" }}>
                <HeaderExtraSmall
                  title={`© ${currentYear} Make IT Count. Alle rechten voorbehouden.`}
                  style={{ color: "#fff", fontWeight: 300 }}
                />
              </div>
            </SectionBox>
          </ContainerLarge>
        </div>
      </section>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  sectionContent: {
    position: "relative",
    padding: "5rem 0",
    "&:first-child": {
      [theme.breakpoints.only("xs")]: {
        paddingTop: "1rem",
      },
    },
  },
  heroSection: {},
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.4)",
  },
  heroRow: {
    display: "flex",
    alignItems: "center",
  },
  heroButtonWrapper: {
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },
  heroTitle: {
    // color: "#fff",
  },
  heroSubtitle: {},
  heroParagraph: {},
  heroButton: {
    fontSize: "1.5rem",
  },
  sectionTextMiddle: {
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  columnSecondOrder: {
    [theme.breakpoints.only("xs")]: {
      order: 2,
    },
  },
  sectionPlokkie: {
    position: "relative",
    // backgroundColor: "#016770",
    background: `url(${PeopleImage}) no-repeat center center`,
    backgroundSize: "cover",
  },
  exampleWrapper: {
    maxWidth: 300,
    margin: "auto",
    textAlign: "center",
  },
  exampleImageWrapper: {
    maxWidth: 80,
    margin: "16px auto",
  },
  footerContentWrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
    },
  },
  footerLinkList: {
    listStyle: "none",
    "& > li": {
      margin: 8,
    },
    "& span": {
      color: "#fff",
      cursor: "pointer",
    },
    "& span:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export default HomePage;
