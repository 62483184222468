import * as React from "react";

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import {
  InformationDialog,
  MembersHelper,
  Paragraph,
} from "../../../../../presentation";

import { MemberViewModel } from "../../../../../application";

export interface MembersDialogProps {
  members: MemberViewModel[];
  children(api: ChildrenApi): React.ReactNode;
}

interface ChildrenApi {
  open(): void;
}

export const MembersDialog: React.FC<MembersDialogProps> = ({
  members,
  children,
}) => {
  return (
    <InformationDialog
      title="Gebruikers"
      content={
        <div>
          <List>
            {members
              .sort((a, b) =>
                a.isAdmin === b.isAdmin ? 0 : a.isAdmin ? -1 : 1
              )
              .map((m, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar style={{ textTransform: "uppercase" }}>
                      {MembersHelper.getInitials(m.username)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Paragraph>{`${m.username}${
                        m.isAdmin ? " (Beheerder)" : ""
                      }`}</Paragraph>
                    }
                    secondary={`${m.email}`}
                  />
                </ListItem>
              ))}
          </List>
        </div>
      }
      maxWidth="sm"
    >
      {children}
    </InformationDialog>
  );
};

export default MembersDialog;
