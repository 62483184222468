import * as React from "react";

import { Avatar, Tooltip, useMediaQuery, useTheme } from "@material-ui/core";
import { MembersDialog, MembersHelper } from "../../../../../presentation";

import { AvatarGroup } from "@material-ui/lab";
import { MemberViewModel } from "../../../../../application";

export interface MemberAvatarsProps {
  members: MemberViewModel[];
}

export const MemberAvatars: React.FC<MemberAvatarsProps> = ({ members }) => {
  // STYLES
  const theme = useTheme();

  // VARIABLES
  const smallScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const maxAvatar = smallScreen ? 3 : 10;

  return (
    <MembersDialog members={members}>
      {({ open }) => (
        <AvatarGroup
          max={maxAvatar}
          style={{ cursor: "pointer" }}
          onClick={open}
        >
          {members.map((member, index) => (
            <Tooltip key={index} title={member.username}>
              <Avatar style={{ textTransform: "uppercase" }}>
                {MembersHelper.getInitials(member.username)}
              </Avatar>
            </Tooltip>
          ))}
        </AvatarGroup>
      )}
    </MembersDialog>
  );
};

export default MemberAvatars;
