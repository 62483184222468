import * as React from "react";

import ActionsIcon from "@material-ui/icons/NewReleasesOutlined";
import { makeStyles } from "@material-ui/core";

interface OpenActionsIconProps {}

export const OpenActionsIcon: React.FC<OpenActionsIconProps> = () => {
  // STYLES
  const classes = useStyles();

  return <ActionsIcon classes={{ root: classes.actionIcon }} />;
};

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    color: theme.palette.error.main,
  },
}));

export default OpenActionsIcon;
