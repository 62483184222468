import * as React from "react";

import {
  ActivityModel,
  MemberViewModel,
  Result,
  TopicViewModel,
  UserTopicModel,
  UserTopicsModel,
} from "../../../application";

import { useTopicService } from "../../../presentation";

interface HookState {
  topic: TopicViewModel | null;
  members: MemberViewModel[] | null;
  activities: ActivityModel[] | null;
  userTopics: UserTopicModel[];
  loading: boolean;
  reasons: string[] | null;
}

interface HookMethods {
  getTopic(topicId: number): Promise<Result<TopicViewModel> | Result>;
  getActivities(topicId: number): Promise<Result<ActivityModel[]> | Result>;
  updateTopic(
    topicId: number,
    title: string,
    description: string
  ): Promise<Result>;
  getMembers(topicId: number): Promise<Result>;
  getUserTopics(page: number): Promise<Result<UserTopicsModel> | Result>;
  sendGeneralMessage(topicId: number, message: string): Promise<Result>;
}

export const useTopicUsecases = (): [HookState, HookMethods] => {
  const [topicSvc] = useTopicService();
  const [topic, setTopic] = React.useState<TopicViewModel | null>(null);
  const [members, setMembers] = React.useState<MemberViewModel[] | null>(null);
  const [activities, setActivities] = React.useState<ActivityModel[] | null>(
    null
  );
  const [userTopics, setUserTopics] = React.useState<UserTopicModel[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [reasons, setReasons] = React.useState<string[] | null>(null);

  const getTopic = async (
    topicId: number
  ): Promise<Result<TopicViewModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await topicSvc.getTopic(topicId);
    setLoading(false);

    if (result.isSuccess && result.value) setTopic(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const getActivities = async (
    topicId: number
  ): Promise<Result<ActivityModel[]> | Result> => {
    const result = await topicSvc.getActivities(topicId);

    if (result.isSuccess && result.value) setActivities(result.value);

    return result;
  };

  const updateTopic = async (
    topicId: number,
    title: string,
    description: string
  ) => {
    setReasons(null);
    setLoading(true);
    const result = await topicSvc.updateTopic(topicId, title, description);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const getMembers = async (topicId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await topicSvc.getMembers(topicId);
    setLoading(false);

    if (result.isSuccess && result.value) setMembers(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return Result.toGenericResult(result);
  };

  const getUserTopics = async (
    page: number
  ): Promise<Result<UserTopicsModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await topicSvc.getUserTopics(page);
    setLoading(false);

    if (result.isSuccess && result.value) {
      const retrievedUserTopics = result.value.topics;
      const newUserTopics = [...userTopics, ...retrievedUserTopics];

      setUserTopics(newUserTopics);
    }
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const sendGeneralMessage = async (
    topicId: number,
    message: string
  ): Promise<Result> => {
    setReasons(null);
    const result = await topicSvc.sendGeneralMessage(topicId, message);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  return [
    { topic, members, activities, userTopics, loading, reasons },
    {
      getTopic,
      getActivities,
      updateTopic,
      getMembers,
      getUserTopics,
      sendGeneralMessage,
    },
  ];
};
