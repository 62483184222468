import * as React from "react";

import {
  GenericVoteType,
  LocationPropositionModel,
} from "../../../../../../application";
import { Grid, makeStyles } from "@material-ui/core";

import { LocationProposition } from "../../../../../../presentation";

export interface LocationPropositionListProps {
  done: boolean;
  selectionMode: boolean;
  propositions: LocationPropositionModel[];
  onOpenRemarks(propositionId: number): void;
  onConfirm(propositionId: number): Promise<void>;
  onEdit(propositionId: number): Promise<void>;
  onRemove(propositionId: number): Promise<void>;
  onVote(propositionId: number, voteType: GenericVoteType): Promise<void>;
}

export const LocationPropositionList: React.FC<LocationPropositionListProps> =
  ({
    done,
    selectionMode,
    propositions,
    onOpenRemarks,
    onConfirm,
    onEdit,
    onRemove,
    onVote,
  }) => {
    // STYLES
    const classes = useStyles();

    return (
      <div>
        <Grid container>
          {propositions.map((p) => (
            <Grid
              key={p.id}
              item
              xs={12}
              sm="auto"
              classes={{
                root: classes.gridItem,
              }}
            >
              <LocationProposition
                model={p}
                selectionMode={selectionMode}
                locked={done}
                onOpenRemarks={onOpenRemarks}
                onConfirm={onConfirm}
                onEdit={onEdit}
                onRemove={onRemove}
                onVote={onVote}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

const useStyles = makeStyles((theme) => ({
  gridItem: {
    marginRight: 30,
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
}));

export default LocationPropositionList;
