import * as React from "react";

import { Badge, makeStyles } from "@material-ui/core";

import UnSeenRemarks from "@material-ui/icons/Chat";

interface UnSeenRemarksNotificationProps {
  totalUnSeenRemarks: number;
}

export const UnSeenRemarksNotification: React.FC<UnSeenRemarksNotificationProps> =
  ({ totalUnSeenRemarks }) => {
    // STYLES
    const classes = useStyles();

    if (totalUnSeenRemarks === 0) return null;

    return (
      <Badge badgeContent={totalUnSeenRemarks} color="error">
        <UnSeenRemarks classes={{ root: classes.icon }} />
      </Badge>
    );
  };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#fff",
  },
}));

export default UnSeenRemarksNotification;
