import * as React from "react";

import { Box, makeStyles, useTheme } from "@material-ui/core";
import {
  CommonButton,
  ContainerLarge,
  HeaderExtraSmall,
  HeaderLarge,
  HeaderNormal,
  HeaderSmall,
  Paragraph,
  RoadmapImage,
  SectionBox,
  SectionSmall,
  UnderConstructionImage,
} from "../../../presentation";

import DoneImageIcon from "@material-ui/icons/Done";
import TodoImageIcon from "@material-ui/icons/Remove";
import { useHistory } from "react-router-dom";

export interface RoadmapPageProps {}

export const RoadmapPage: React.FC<RoadmapPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // HOOKS
  const { push } = useHistory();

  // METHODS
  const handleNavigation = () => {
    push("/contact");
  };

  return (
    <ContainerLarge>
      <SectionBox>
        <HeaderLarge title="Roadmap" />
      </SectionBox>

      <SectionBox>
        <HeaderExtraSmall title="Met deze roadmap willen we jou alvast een idee geven waar wij ons de komende tijd mee bezig gaan houden. Graag horen we jullie ideeën en suggesties, zodat we deze kunnen meenemen in de doorontwikkeling van Plokkie." />
      </SectionBox>

      <SectionBox>
        <CommonButton
          color="primary"
          label="Feedback en contact"
          size="large"
          variant="contained"
          onClick={handleNavigation}
        />
      </SectionBox>

      {/* <SectionSmall>
        <div style={{ textAlign: "center" }}>
          <img
            src={UnderConstructionImage}
            style={{ width: "100%", maxWidth: 600 }}
          />
        </div>
      </SectionSmall> */}

      <SectionBox>
        <div>
          <img src={RoadmapImage} className={classes.image} />
        </div>
      </SectionBox>

      <SectionSmall>
        <SectionBox>
          <HeaderNormal title="Toelichting op bovenstaande doorontwikkelingen" />
        </SectionBox>

        <SectionBox>
          <HeaderSmall
            title="2021 Q4"
            color="primary"
            style={{ fontWeight: "bold" }}
          />
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <DoneIcon />
            <HeaderExtraSmall title="Livegang Plokkie" />
          </Box>
          <Paragraph>
            Op 1 oktober 2021 is Plokkie officieel live gegaan 🎉.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <DoneIcon />
            <HeaderExtraSmall title="Toevoegen instructies" />
          </Box>
          <Paragraph>
            De eerste feedback die binnen kwam vroeg om extra instructies. Dus
            we hebben een “Hoe werkt het” toegevoegd aan het menu. Ook hebben we
            een kleine instructie gemaakt voor de mensen, die direct vanuit een
            Plokkie geraadpleegd kan worden.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <DoneIcon />
            <HeaderExtraSmall title="Notificaties via e-mail" />
          </Box>
          <Paragraph>
            Om op de hoogte te blijven van alle activiteiten hebben we een
            notificatie systeem gebouwd waarmee de gebruikers via e-mail worden
            geïnformeerd.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <DoneIcon />
            <HeaderExtraSmall title="Overzicht opmerkingen" />
          </Box>
          <Paragraph>
            Via rode bolletjes kan je voortaan zien wat je nog niet gelezen
            hebt. Zo weet je precies waar je moet kijken als er een nieuwe
            opmerking is geplaatst.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <DoneIcon />
            <HeaderExtraSmall title="E-mail notificatie instellingen" />
          </Box>
          <Paragraph>
            We hebben gemerkt dat er veel notificatie e-mails gestuurd kunnen
            worden (ook dankzij de feedback van Bart Z.). Daarom hebben we
            tussentijds een oplossing ontwikkeld waarmee de gebruiker zelf kan
            instellen of hij / zij e-mails wilt ontvangen.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <DoneIcon />
            <HeaderExtraSmall title="Nieuwe Plokkie direct openen" />
          </Box>
          <Paragraph>
            Als je al bent ingelogd en een nieuwe Plokkie aanmaakt, dan moet je
            nu nog via de e-mail desbetreffende Plokkie activeren. We willen het
            zo gaan maken dat je direct door kunt naar je Plokkie. Dit is alleen
            mogelijk wanneer je al bent ingelogd.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <HeaderSmall
            title="2022 Q1"
            color="primary"
            style={{ fontWeight: "bold" }}
          />
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <DoneIcon />
            <HeaderExtraSmall title="Notificaties e-mail optimaliseren" />
          </Box>
          <Paragraph>
            Het notificeren via e-mail werkt al top! Maar we hebben wel gemerkt
            dat je nu erg veel mailtjes krijgt. Daarom willen we dit iets beter
            gaan balanceren zodat je wel op de hoogte wordt gehouden zonder dat
            je overspoeld wordt door e-mails.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <DoneIcon />
            <HeaderExtraSmall title="Activiteiten opvolgen" />
          </Box>
          <Paragraph>
            Op Plokkie vinden er veel activiteiten plaats. Daarom is het
            belangrijk dat er één duidelijk overzicht beschikbaar komt van alle
            activiteiten op chronologische volgorde. Daarmee zal het nog
            makkelijker zijn om deze op te volgen.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <HeaderSmall
            title="2022 Q2"
            color="primary"
            style={{ fontWeight: "bold" }}
          />
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="Kleine bugs oplossen" />
          </Box>
          <Paragraph>
            Ook wij hebben helaas soms kleine bugs in onze software :P, die
            willen we natuurlijk oplossen.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="Optimalisaties doorvoeren" />
          </Box>
          <Paragraph>
            Hier willen we allerlei kleine aanpassingen doorvoeren om het
            gebruikersgemak verder te verbeteren.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <HeaderSmall
            title="2022 Q3 / Q4"
            color="primary"
            style={{ fontWeight: "bold" }}
          />
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="Beheer openstaande acties" />
          </Box>
          <Paragraph>
            In Plokkie ontstaan er meerdere acties die de leden moeten
            voltooien. Denk aan het stemmen, beantwoorden van een vraag of het
            afvinken van een taak. Om dit goed te kunnen beheren willen we een
            totaaloverzicht maken van alle openstaande acties. Zodat je precies
            kan zien van wie wat nog verwacht wordt.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="Taken toewijzen uitbreiden" />
          </Box>
          <Paragraph>
            In de praktijk zie je vaak dat een taak ook door meerdere personen
            uitgevoerd kan worden. Daarom willen we het mogelijk maken dat één
            taak ook aan meerdere personen toegewezen kan worden.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="Native App IOS & Android" />
          </Box>
          <Paragraph>
            Het ontwikkelen van een native app voor zowel IOS als Android zal
            bijdragen aan de gebruiksvriendelijkheid. Ook kun je dan gebruik
            maken van alle primaire functies die op je telefoon zitten. Denk
            bijvoorbeeld aan de camera voor het toevoegen van een profielfoto,
            of de GPS voor het opslaan van locatiegegevens.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="Beheerder rol" />
          </Box>
          <Paragraph>
            De beheerder binnen Plokkie heeft een belangrijke rol. We willen
            deze rol graag nog beter ondersteunen zodat het voor de beheerder
            nog makkelijker wordt om alles te coördineren.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="Profielfoto" />
          </Box>
          <Paragraph>
            Gebruikersnamen zeggen al veel, maar het toevoegen van een
            profielfoto zou het toch net wat leuker maken!
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="Notificaties via app" />
          </Box>
          <Paragraph>
            Nadat de app ontwikkeld is geeft dit ook de mogelijkheid om
            notificaties direct naar je telefoon te sturen! Daarmee is het delen
            en opvolgen van acties via Plokkie nog makkelijker!
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="Uitbreiding locatie blokkie" />
          </Box>
          <Paragraph>
            Het locatie blokkie willen we graag uitbreiden met het toevoegen van
            afbeeldingen en de mogelijkheid om meerdere locaties te kiezen. Het
            kan namelijk zo zijn dat de groepsactiviteit op meerdere locaties
            plaatsvind!
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="Boodschappen blokkie" />
          </Box>
          <Paragraph>
            Bij groepsactiviteiten worden ook vaak boodschappen gedaan. Daarom
            willen we daarvoor ook een blokkie gaan opzetten. Hoe deze er
            precies uit komt te zien is nog een verrassing, maar een
            boodschappenlijstje komt er sowieso in!
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="Plannings blokkie" />
          </Box>
          <Paragraph>
            Hoe handig is het als je ook een planning kan opzetten van de
            groepsactiviteit. Zeker als je meerdere activiteiten op de planning
            hebt staan! Hoe deze eruit komt te zien is nog een verrassing!
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="Beheer mijn Plokkies uitbreiden" />
          </Box>
          <Paragraph>
            Het beheer binnen een Plokkie is heel belangrijk maar als je
            meerdere Plokkies hebt wil je deze ook kunnen beheren over de
            Plokkies heen. Daarom willen we aan de “Mijn Plokkies” pagina nog
            extra mogelijkheden toevoegen. Denk aan een zoek- en filterfunctie
            maar ook een optie om Plokkies uiteindelijk af te sluiten.
          </Paragraph>
        </SectionBox>

        <SectionBox>
          <Box display="flex" alignItems="center">
            <TodoIcon />
            <HeaderExtraSmall title="???" />
          </Box>
          <Paragraph>
            Op basis van alle feedback die we krijgen proberen we de juiste
            doorontwikkelingen en prioriteiten te bepalen. Daarom ontvangen we
            graag jouw feedback en komt jouw punt wellicht hier tussen te staan
            😊.
          </Paragraph>
        </SectionBox>
      </SectionSmall>
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  sectionContent: {
    position: "relative",
    padding: "5rem 0",
  },
  image: {
    width: "100%",
    height: "100%",
  },
}));

const DoneIcon = () => {
  // STYLES
  const theme = useTheme();

  return <DoneImageIcon style={{ color: theme.palette.success.main }} />;
};

const TodoIcon = () => {
  // STYLES
  const theme = useTheme();

  return <TodoImageIcon style={{ color: theme.palette.secondary.main }} />;
};

export default RoadmapPage;
