import { injectable, inject } from "tsyringe";
import {
  AddDatePropositionsModel,
  AddDatePropositionRequest,
  AddDatePropositionsRequest,
  AddRemarkRequest,
  INJECTION_TOKENS,
  ChangeTimeFromDatePropositionModel,
  ChangeTimeFromDatePropositionRequest,
  DatePropositionTimesModel,
  DateUtils,
  DatePropositionRemarksModel,
  GenericVoteType,
  type DateItemRepository,
  Result,
  TimesHelper,
  VoteOnDatePropositionRequest,
  DateItemService,
} from "../../../application";

@injectable()
export class DateItemServiceImpl implements DateItemService {
  constructor(
    @inject(INJECTION_TOKENS.DateItemRepository)
    private dateItemRepo: DateItemRepository
  ) {}

  async addDatePropositions(
    dateItemId: number,
    model: AddDatePropositionsModel
  ): Promise<Result<void>> {
    const request = new AddDatePropositionsRequest();
    const { startTime, endTime, datePropositions } = model;

    // TRANSFORM TIMES
    if (startTime) {
      const splitResult = TimesHelper.splitTime(startTime);
      if (splitResult.isFailed || !splitResult.value)
        return splitResult.toResult();

      const [hours, minutes] = splitResult.value;
      request.startTimeHours = hours;
      request.startTimeMinutes = minutes;
    }

    if (endTime) {
      const splitResult = TimesHelper.splitTime(endTime);
      if (splitResult.isFailed || !splitResult.value)
        return splitResult.toResult();

      const [hours, minutes] = splitResult.value;
      request.endTimeHours = hours;
      request.endTimeMinutes = minutes;
    }

    datePropositions.forEach((dp) => {
      let datePropositionRequest = new AddDatePropositionRequest();

      // TRANSLATE LOCALE-DATE TO UTC DATE-ONLY
      datePropositionRequest.startDate = DateUtils.localToUtcDate(
        dp.localStartDate
      );

      request.datePropositions.push(datePropositionRequest);
    });

    return await this.dateItemRepo.addDatePropositions(dateItemId, request);
  }

  async removeDateProposition(
    datePropositionId: number
  ): Promise<Result<void>> {
    return await this.dateItemRepo.removeDateProposition(datePropositionId);
  }

  async voteOnDateProposition(
    datePropositionId: number,
    voteType: GenericVoteType
  ): Promise<Result<void>> {
    const request = new VoteOnDatePropositionRequest();

    request.voteType = voteType;

    return await this.dateItemRepo.voteOnDateProposition(
      datePropositionId,
      request
    );
  }

  async getTimesFromDateProposition(
    datePropositionId: number
  ): Promise<Result<DatePropositionTimesModel> | Result<void>> {
    return this.dateItemRepo.getTimesFromDateProposition(datePropositionId);
  }

  async changeTimeFromDateProposition(
    datePropositionId: number,
    model: ChangeTimeFromDatePropositionModel
  ): Promise<Result<void>> {
    const request = new ChangeTimeFromDatePropositionRequest();

    if (model.startTime) {
      const splitResult = TimesHelper.splitTime(model.startTime);
      if (splitResult.isFailed || !splitResult.value)
        return splitResult.toResult();

      const [hours, minutes] = splitResult.value;
      request.startTimeHours = hours;
      request.startTimeMinutes = minutes;
    }

    if (model.endTime) {
      const splitResult = TimesHelper.splitTime(model.endTime);
      if (splitResult.isFailed || !splitResult.value)
        return splitResult.toResult();

      const [hours, minutes] = splitResult.value;
      request.endTimeHours = hours;
      request.endTimeMinutes = minutes;
    }

    return await this.dateItemRepo.changeTimeFromDateProposition(
      datePropositionId,
      request
    );
  }

  async pinDate(datePropositionId: number): Promise<Result<void>> {
    return await this.dateItemRepo.pinDate(datePropositionId);
  }

  async getRemarks(
    datePropositionId: number
  ): Promise<Result<DatePropositionRemarksModel> | Result<void>> {
    return await this.dateItemRepo.getRemarks(datePropositionId);
  }

  async addRemark(
    datePropositionId: number,
    remark: string
  ): Promise<Result<void>> {
    const request = new AddRemarkRequest(remark);

    return await this.dateItemRepo.addRemark(datePropositionId, request);
  }

  async removeRemark(remarkId: number): Promise<Result<void>> {
    return await this.dateItemRepo.removeRemark(remarkId);
  }
}
