import {} from "../..";
import "./token.interceptor";

import { Result } from "../../../application";
import axios from "axios";

const _baseUrl = "/api/";

export const get = async <TValue>(
  relativeUrl: string
): Promise<Result<TValue> | Result> => {
  try {
    const { data: result } = await axios.get<Result<TValue>>(
      `${_baseUrl}${relativeUrl}`
    );

    return result;
  } catch (error) {
    console.log(error);
    return Result.Error();
  }
};

export const post = async <TValue>(
  relativeUrl: string,
  body: any
): Promise<Result<TValue> | Result> => {
  try {
    const { data: result } = await axios.post<Result<TValue>>(
      `${_baseUrl}${relativeUrl}`,
      body
    );

    return result;
  } catch (error) {
    console.log(error);
    return Result.Error();
  }
};

export const put = async <TValue>(
  relativeUrl: string,
  body?: any
): Promise<Result<TValue> | Result> => {
  try {
    const { data: result } = await axios.put<Result<TValue>>(
      `${_baseUrl}${relativeUrl}`,
      body
    );

    return result;
  } catch (error) {
    console.log(error);
    return Result.Error();
  }
};

export const remove = async <TValue>(
  relativeUrl: string
): Promise<Result<TValue> | Result> => {
  try {
    const { data: result } = await axios.delete<Result<TValue>>(
      `${_baseUrl}${relativeUrl}`
    );

    return result;
  } catch (error) {
    console.log(error);
    return Result.Error();
  }
};
