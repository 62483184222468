import * as React from "react";

import {
  Card,
  CardContent,
  CardHeader,
  CommonIconButton,
  CommonMenu,
  ConfirmationDialog,
  Paragraph,
  RemarksIconButton,
  Text,
} from "../../../../../presentation";
import { CostModel, NumberFormatUtils } from "../../../../../application";

import DeleteIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/EditOutlined";
import MoreOptionsIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core";

export interface CostProps {
  cost: CostModel;
  blocked: boolean;
  onOpenRemarks(costId: number): void;
  onEdit(costId: number): void;
  onRemove(costId: number): Promise<void>;
}

export const Cost: React.FC<CostProps> = ({
  cost,
  blocked,
  onOpenRemarks,
  onEdit,
  onRemove,
}) => {
  // STYLES
  const classes = useStyles();

  // STATE
  const [loading, setLoading] = React.useState(false);

  // VARIABLES
  const { id, amount, description, totalRemarks, totalUnSeenRemarks } = cost;

  // METHODS
  const onRemoveWrapper = async (costId: number) => {
    setLoading(true);
    await onRemove(costId);
    setLoading(false);
  };

  return (
    <Card isPinned={false} loading={loading}>
      <CardHeader confirmed={false}>
        <RemarksIconButton
          totalRemarks={totalRemarks}
          totalUnSeenRemarks={totalUnSeenRemarks}
          onClick={() => onOpenRemarks(id)}
        />
        <ConfirmationDialog
          title="Verwijderen"
          confirmButtonWarningColor
          onConfirm={() => onRemoveWrapper(id)}
        >
          {({ open: openDialog }) => (
            <CommonMenu>
              {({ open: openMenu }) => ({
                button: (
                  <CommonIconButton
                    disabled={blocked}
                    icon={<MoreOptionsIcon />}
                    size="small"
                    onClick={openMenu}
                  />
                ),
                items: [
                  {
                    label: "Kosten aanpassen",
                    icon: <EditIcon />,
                    handleClick: () => onEdit(id),
                  },
                  {
                    label: "Verwijderen",
                    icon: <DeleteIcon />,
                    handleClick: openDialog,
                  },
                ],
              })}
            </CommonMenu>
          )}
        </ConfirmationDialog>
      </CardHeader>
      <CardContent>
        <div className={classes.contentWrapper}>
          <div className={classes.descriptionContainer}>
            <Paragraph>{description}</Paragraph>
          </div>
          <div className={classes.amountContainer}>
            <Text>
              <strong>
                €{" "}
                {NumberFormatUtils.format(amount, { minimumFractionDigits: 2 })}
              </strong>
            </Text>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: "flex",
    alignItems: "center",
  },
  descriptionContainer: {
    flexGrow: 1,
  },
  amountContainer: {},
}));

export default Cost;
