import * as React from "react";

import {
  BlocksInfo,
  CommonInfoDialog,
  HeaderExtraSmall,
  Paragraph,
  SectionBox,
} from "../../../../../presentation";

import { useTheme } from "@material-ui/core";

interface InstructionsDialogProps {
  isOpen: boolean;
  onClose(): void;
}

export const InstructionsDialog: React.FC<InstructionsDialogProps> = ({
  isOpen,
  onClose,
}) => {
  // STYLES
  const theme = useTheme();

  return (
    <CommonInfoDialog
      open={isOpen}
      title="Hoe werkt het"
      content={
        <div>
          <SectionBox>
            <HeaderExtraSmall
              title={
                <span>
                  <span style={{ color: theme.palette.primary.main }}>
                    Welkom
                  </span>
                  , leuk dat jullie Plokkie gaan gebruiken!
                </span>
              }
            />
          </SectionBox>

          <SectionBox>
            <Paragraph>
              Hier even een korte toelichting om je op weg te helpen:
            </Paragraph>
          </SectionBox>

          <SectionBox>
            <Paragraph>
              Plokkie werkt met verschillende blokkies die jullie moet helpen om
              de groepsactiviteit te regelen. Afhankelijk van wat je wil regelen
              kan je zelf bepalen welke blokkies voor jouw groepsactiviteit van
              toepassing zijn. Voeg de juiste blokkies toe als deze nog niet
              zijn toegevoegd en vul deze aan met jouw inbreng.
            </Paragraph>
          </SectionBox>

          <SectionBox>
            <Paragraph>
              Op dit moment heb je de volgende blokkies tot je beschikking:
            </Paragraph>
          </SectionBox>

          <SectionBox>
            <BlocksInfo />
          </SectionBox>

          <SectionBox>
            <HeaderExtraSmall title="Succes met het gebruiken van Plokkie maar vooral ook veel plezier!" />
          </SectionBox>
        </div>
      }
      onClose={onClose}
    />
  );
};

export default InstructionsDialog;
