import * as React from "react";

import { Container, Grid, Hidden, makeStyles } from "@material-ui/core";
import {
  ContainerLarge,
  CreateTopicContainer,
  CreateTopicFormView,
  CreateTopicImage,
  HeaderLarge,
  HeaderNormal,
  ReasonList,
  SectionSmall,
  UserContext,
} from "../../../presentation";

import { Result } from "../../../application";
import { useHistory } from "react-router-dom";

export interface CreateTopicPageProps {}

export const CreateTopicPage: React.FC<CreateTopicPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // ROUTER
  const history = useHistory();

  // CONTEXT
  const { user } = React.useContext(UserContext);

  // METHODS
  const navigateOnSuccess = (
    createTopicPromise: Promise<Result<number> | Result>
  ): Promise<Result<number> | Result> => {
    createTopicPromise.then((result) => {
      if (result.isSuccess && result.value) {
        if (user?.email) {
          history.replace(`/topics/${result.value}`);
        } else {
          history.replace("/topics/created");
        }
      }
    });

    return createTopicPromise;
  };

  return (
    <>
      <CreateTopicContainer>
        {({ reasons, createTopic }) => (
          <SectionSmall>
            <ContainerLarge>
              <Grid container justify="center" alignItems="center">
                <Hidden smDown>
                  <Grid item xs={12} sm={6}>
                    <div style={{ maxWidth: 500, margin: "auto" }}>
                      <img src={CreateTopicImage} className={classes.image} />
                    </div>
                  </Grid>
                </Hidden>
                <Grid item xs={12} sm={6}>
                  <Container maxWidth="sm">
                    <HeaderLarge title="Wat wil je regelen?" />
                    <CreateTopicFormView
                      email={user?.email}
                      onCreate={(model) =>
                        navigateOnSuccess(createTopic(model))
                      }
                    />
                    <ReasonList reasons={reasons} />
                  </Container>
                </Grid>
              </Grid>
            </ContainerLarge>
          </SectionSmall>
        )}
      </CreateTopicContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100%",
  },
}));

export default CreateTopicPage;
