import * as React from "react";

import {
  AnswerVoteType,
  GenericVoteType,
  QuestionsItemModel,
  QuestionsItemService,
  RemarksModel,
  Result,
} from "../../../application";

import { useQuestionsItemService } from "../../../presentation";

interface HookState {
  questionsItem: QuestionsItemModel | null;
  remarks: RemarksModel | null;
  loading: boolean;
  reasons: string[] | null;
}

interface HookMethods extends QuestionsItemService {}

export const useQuestionsItemUsecases = (): [HookState, HookMethods] => {
  const [questionsItemSvc] = useQuestionsItemService();
  const [questionsItem, setQuestionsItem] =
    React.useState<QuestionsItemModel | null>(null);
  const [remarks, setRemarks] = React.useState<RemarksModel | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [reasons, setReasons] = React.useState<string[] | null>(null);

  const getQuestionsItem = async (
    itemId: number
  ): Promise<Result<QuestionsItemModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.getQuestionsItem(itemId);
    setLoading(false);

    if (result.isSuccess && result.value) setQuestionsItem(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const addQuestion = async (
    itemId: number,
    description: string
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.addQuestion(itemId, description);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const updateQuestion = async (
    questionId: number,
    description: string
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.updateQuestion(
      questionId,
      description
    );
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const removeQuestion = async (questionId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.removeQuestion(questionId);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const getQuestionRemarks = async (
    questionId: number
  ): Promise<Result<RemarksModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.getQuestionRemarks(questionId);
    setLoading(false);

    if (result.isSuccess && result.value) setRemarks(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const addQuestionRemark = async (
    questionId: number,
    text: string
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.addQuestionRemark(questionId, text);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const removeQuestionRemark = async (remarkId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.removeQuestionRemark(remarkId);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const addAnswer = async (
    questionId: number,
    description: string
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.addAnswer(questionId, description);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const updateAnswer = async (
    answerId: number,
    description: string
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.updateAnswer(answerId, description);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const removeAnswer = async (answerId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.removeAnswer(answerId);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const voteOnAnswer = async (
    answerId: number,
    voteType: GenericVoteType
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.voteOnAnswer(answerId, voteType);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const chooseAnswer = async (answerId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.chooseAnswer(answerId);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const unChooseAnswer = async (answerId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.unChooseAnswer(answerId);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const getAnswerRemarks = async (
    answerId: number
  ): Promise<Result<RemarksModel> | Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.getAnswerRemarks(answerId);
    setLoading(false);

    if (result.isSuccess && result.value) setRemarks(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const addAnswerRemark = async (
    answerId: number,
    text: string
  ): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.addAnswerRemark(answerId, text);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  const removeAnswerRemark = async (remarkId: number): Promise<Result> => {
    setReasons(null);
    setLoading(true);
    const result = await questionsItemSvc.removeAnswerRemark(remarkId);
    setLoading(false);

    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  return [
    { questionsItem, remarks, loading, reasons },
    {
      getQuestionsItem,
      addQuestion,
      updateQuestion,
      removeQuestion,
      getQuestionRemarks,
      addQuestionRemark,
      removeQuestionRemark,
      addAnswer,
      updateAnswer,
      removeAnswer,
      voteOnAnswer,
      chooseAnswer,
      unChooseAnswer,
      getAnswerRemarks,
      addAnswerRemark,
      removeAnswerRemark,
    },
  ];
};
