export class Result<TValue = void> {
  isSuccess: boolean;
  isFailed: boolean;
  isException: boolean;
  reasons: string[];
  value: TValue | null = null;

  constructor(
    isSuccess: boolean,
    isFailed: boolean,
    isExcepion: boolean,
    reasons: string[]
  ) {
    this.isSuccess = isSuccess;
    this.isFailed = isFailed;
    this.isException = isExcepion;
    this.reasons = reasons;
  }

  public toResult(): Result {
    const self = this;

    return new Result(
      self.isSuccess,
      self.isFailed,
      self.isException,
      self.reasons
    );
  }

  public static toGenericResult(result: Result<any>): Result {
    return new Result(
      result.isSuccess,
      result.isFailed,
      result.isException,
      result.reasons
    );
  }

  public static Ok(): Result {
    return new Result(true, false, false, []);
  }

  public static WithValue<T>(value: T) {
    let result = new Result<T>(true, false, false, []);

    result.value = value;

    return result;
  }

  public static Fail(reason: string, reasons?: string[]): Result {
    if (!reasons && !reason)
      throw new Error("Er moet op zijn minst één reden zijn benieuwd.");

    let _reasons: string[] = [];

    if (reason) _reasons.push(reason);
    if (reasons) _reasons.concat(reasons);

    return new Result(false, true, false, _reasons);
  }

  public static Error(): Result {
    const reasons = [];
    reasons.push("Er is iets misgegaan, probeer het later nog eens.");

    return new Result(false, true, true, reasons);
  }
}
