import * as React from "react";

// MATERIAL-UI HOOKS
import { makeStyles } from "@material-ui/core";

// MATERIAL-UI COMPONENTS
import MuiCardContent from "@material-ui/core/CardContent";

export interface CardContentProps {}

export const CardContent: React.FC<CardContentProps> = ({ children }) => {
  // STYLES
  const classes = useStyles();

  return (
    <MuiCardContent classes={{ root: classes.cardContent }}>
      {children}
    </MuiCardContent>
  );
};

const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingTop: 5,
    "&:last-child": {
      paddingBottom: 5,
    },
  },
}));

export default CardContent;
