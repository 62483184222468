import * as React from "react";

import {
  ButtonBar,
  CommonButton,
  ContainerLarge,
  GenericTextField,
  HeaderLarge,
  LoadingScreen,
  MultilineTextField,
  ReasonList,
  SectionBox,
  useTopicUsecases,
} from "../../../presentation";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";

export interface UpdateTopicPageProps {}

interface RouteParams {
  topicId: string;
}

export const UpdateTopicPage: React.FC<UpdateTopicPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // USECASES
  const [{ topic, loading, reasons }, { getTopic, updateTopic }] =
    useTopicUsecases();

  // ROUTER
  const routeParams = useParams<RouteParams>();
  const { push } = useHistory();

  // FORM
  const { register, handleSubmit, errors } = useForm();

  // VARIABLES
  const topicId = parseInt(routeParams.topicId);
  const routeBack = `/topics/${topicId}`;

  // EFFECTS
  React.useEffect(() => {
    if (topicId) getTopic(topicId);
  }, [topicId]);

  // METHODS
  const handleNavigationBack = () => {
    push(routeBack);
  };

  const handleActualSubmit = async (fieldValues: any) => {
    const result = await updateTopic(
      topicId,
      fieldValues.title,
      fieldValues.description
    );

    if (result.isSuccess) handleNavigationBack();
  };

  return (
    <ContainerLarge>
      <SectionBox>
        <HeaderLarge title="Plokkie aanpassen" />
      </SectionBox>

      <SectionBox>
        {!topic && loading && <LoadingScreen />}
        <div className={classes.formWrapper}>
          {topic && (
            <form
              autoComplete="off"
              onSubmit={handleSubmit(handleActualSubmit)}
            >
              <div>
                <GenericTextField
                  defaultValue={topic.title}
                  displayLabel="Onderwerp *"
                  fieldName="title"
                  inputRef={register({
                    required: true,
                    minLength: 3,
                    maxLength: 100,
                  })}
                  errorMessage={errors.title && "Minimaal 3, Maximaal 100"}
                />
              </div>
              <div>
                <MultilineTextField
                  defaultValue={topic.description}
                  displayLabel="Omschrijving *"
                  fieldName="description"
                  rows={8}
                  inputRef={register({ required: true, maxLength: 1000 })}
                  errorMessage={
                    errors.description && "Verplicht, Maximaal 1000"
                  }
                />
              </div>

              <SectionBox>
                <ButtonBar>
                  <CommonButton
                    label="Annuleren"
                    onClick={handleNavigationBack}
                  />
                  <CommonButton
                    label="Opslaan"
                    loading={loading}
                    type="submit"
                    color="primary"
                    variant="contained"
                  />
                </ButtonBar>
              </SectionBox>
            </form>
          )}
        </div>
      </SectionBox>

      {reasons && (
        <SectionBox>
          <ReasonList reasons={reasons} />
        </SectionBox>
      )}
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    maxWidth: 600,
  },
}));

export default UpdateTopicPage;
