const getInitials = (username: string) => {
  const words = username.split(" ");

  if (words.length === 1) {
    // FIRST 2 LETTERS
    return username.substring(0, 2);
  } else if (words.length > 1) {
    // FIRST LETTER OF FIRST AND LAST WORD
    const firstWord = words[0];
    const lastWord = words[words.length - 1];

    return `${firstWord.substring(0, 1)}${lastWord.substring(0, 1)}`;
  } else {
    // UNKNOWN USERNAME
    return "??";
  }
};

export { getInitials };
