import * as React from "react";

import { OpenActionsIcon, Paragraph, Text } from "../../../../../presentation";

import { makeStyles } from "@material-ui/core";

interface OpenActionsReasonsBoxProps {
  reasons: string[];
}

export const OpenActionsReasonsBox: React.FC<OpenActionsReasonsBoxProps> = ({
  reasons,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <OpenActionsIcon />
        </div>
        <div style={{ marginLeft: 8 }}>
          <Paragraph>Actie vereist</Paragraph>
        </div>
      </div>

      {reasons.length > 1 && <Text>Mogelijke redenen:</Text>}
      <Text className={classes.errorText}>
        <ul>
          {reasons.map((reason) => (
            <li>{reason}</li>
          ))}
        </ul>
      </Text>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: theme.palette.error.main,
  },
}));

export default OpenActionsReasonsBox;
