import React, { useEffect, useState } from "react";
import { Result, UserModel } from "../../application";

import { useAuthenticationService } from "../../presentation";

export interface IUserContext {
  user: UserModel | null;
  isAuthenticated: boolean | null;
  loading: boolean;
  login(userKey: string): Promise<Result>;
  logout(): void;
}

export const UserContext = React.createContext<IUserContext>(
  {} as IUserContext
);

export interface UserProviderProps {}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserModel | null>(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [authSvc] = useAuthenticationService();

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = () => {
    const user = authSvc.getCurrentUser();
    const authenticated = user ? true : false;

    setUser(user);
    setIsAuthenticated(authenticated);
  };

  const login = async (userKey: string): Promise<Result> => {
    setLoading(true);
    const result = await authSvc.login(userKey);
    setLoading(false);
    if (result.isSuccess) {
      getCurrentUser();
    }

    return result;
  };

  const logout = (): void => {
    authSvc.logout();
    window.open("/", "_self");
  };

  return (
    <UserContext.Provider
      value={{ user, isAuthenticated, loading, login, logout }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
