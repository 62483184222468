import {
  Result,
  UpdateUserPreferencesModel,
  UserPreferencesModel,
  UserProductToursModel,
  UserRepository,
} from "../../../application";
import { get, put } from "../../../infrastructure";

export class UserRepositoryImpl implements UserRepository {
  async getPreferences(
    userId: number
  ): Promise<Result<UserPreferencesModel> | Result<void>> {
    return await get<UserPreferencesModel>(`users/${userId}/preferences`);
  }

  async updatePreferences(
    model: UpdateUserPreferencesModel
  ): Promise<Result<void>> {
    const request = {
      notifyAboutItemChanges: model.notifyAboutItemChanges,
      notifyAboutRemarks: model.notifyAboutRemarks,
    };

    return await put(`users/${model.userId}/preferences`, request);
  }

  async getProductTours(): Promise<
    Result<UserProductToursModel> | Result<void>
  > {
    return await get<UserProductToursModel>(`users/product-tours`);
  }

  async updateProductTours(
    model: UserProductToursModel
  ): Promise<Result<void>> {
    return await put(`users/product-tours`, model);
  }
}
