import * as React from "react";

import {
  ContainerLarge,
  ErrorImage,
  HeaderNormal,
  ReasonList,
  SectionBox,
} from "../../../../presentation";

import { makeStyles } from "@material-ui/core";

export interface ErrorScreenProps {
  reasons: string[] | null;
}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({ reasons }) => {
  // STYLES
  const classes = useStyles();

  if (!reasons || reasons.length === 0) return null;

  return (
    <div>
      <ContainerLarge>
        <SectionBox>
          <div style={{ maxWidth: 350, margin: "auto" }}>
            <img src={ErrorImage} className={classes.image} />
          </div>
        </SectionBox>

        <SectionBox>
          <HeaderNormal
            title="Er is iets misgegaan"
            style={{ textAlign: "center" }}
          />
        </SectionBox>

        <SectionBox>
          <ReasonList centerText reasons={reasons} />
        </SectionBox>
      </ContainerLarge>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100%",
  },
}));
