import * as React from "react";

import { Paper, makeStyles } from "@material-ui/core";

export interface CommonPaperProps {}

export const CommonPaper: React.FC<CommonPaperProps> = ({ children }) => {
  // STYLES
  const classes = useStyles();

  return (
    <Paper className={classes.paper} style={{ padding: 16 }}>
      {children}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "16px 0",
    [theme.breakpoints.only("xs")]: {
      marginLeft: -16,
      marginRight: -16,
    },
  },
}));
