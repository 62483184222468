import * as React from "react";

import {
  CommonIconButton,
  GenericTextField,
} from "../../../../../../presentation";
import { makeStyles, useTheme } from "@material-ui/core";

import { Result } from "../../../../../../application";
import SendIcon from "@material-ui/icons/Send";

export interface RemarkInputProps {
  onSend(message: string): Promise<Result>;
}

export const RemarkInput: React.FC<RemarkInputProps> = ({ onSend }) => {
  // STYLES
  const classes = useStyles();
  const theme = useTheme();

  // STATE
  const [text, setText] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);

  // REFS
  const inputRef = React.createRef<HTMLInputElement>();

  // METHODS
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const message = text;
    if (!message) return;

    setLoading(true);
    const result = await onSend(message);
    setLoading(false);

    if (result.isSuccess) setText("");
  };

  return (
    <div className={classes.remarkInput}>
      <form
        autoComplete="off"
        onSubmit={(e) => handleSubmit(e)}
        className={classes.remarkForm}
      >
        <div className={classes.remarkInputTextContainer}>
          <GenericTextField
            displayLabel="Opmerking"
            fieldName="text"
            errorMessage={null}
            value={text}
            onChange={handleChange}
          />
        </div>
        <div className={classes.remarkInputButtonContainer}>
          <CommonIconButton
            disabled={!text}
            icon={<SendIcon />}
            loading={loading}
            type="submit"
            className={classes.remarkInputButton}
          />
        </div>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  remarkInput: {
    display: "flex",
  },
  remarkForm: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  remarkInputTextContainer: {
    flexGrow: 1,
  },
  remarkInputButtonContainer: {},
  remarkInputButton: {
    color: theme.palette.primary.main,
    top: 3,
    "& .MuiSvgIcon-root": {
      fontSize: "2.5rem",
    },
  },
}));

export default RemarkInput;
