import * as React from "react";

import {
  AnswerModel,
  GenericVoteType,
  Result,
} from "../../../../../application";
import {
  Card,
  CardContent,
  CommonIconButton,
  CommonMenu,
  ConfirmationDialog,
  DoneIconComponent,
  RemarksIconButton,
  Text,
  TotalUsersInvolved,
  Voting,
} from "../../../../../presentation";
import { Chip, makeStyles, useTheme } from "@material-ui/core";

import CheckBoxIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import MoreOptionsIcon from "@material-ui/icons/MoreVert";

export interface AnswerProps {
  answer: AnswerModel;
  totalUsers: number;
  selectionMode: boolean;
  blocked: boolean;
  onOpenAnswerRemarks(answerId: number): void;
  onRemove(answerId: number): Promise<Result>;
  onLike(answerId: number): Promise<Result>;
  onDislike(answerId: number): Promise<Result>;
  onVote(answerId: number, voteType: GenericVoteType): Promise<Result>;
  onChoose(answerId: number): Promise<Result>;
  onUnChoose(answerId: number): Promise<Result>;
}

export const Answer: React.FC<AnswerProps> = ({
  answer,
  totalUsers,
  selectionMode,
  blocked,
  onOpenAnswerRemarks,
  onRemove,
  onLike,
  onDislike,
  onVote,
  onChoose,
  onUnChoose,
}) => {
  // STYLES
  const classes = useStyles();
  const theme = useTheme();

  // STATE
  const [loading, setLoading] = React.useState(false);
  const [loadingVote, setLoadingVote] = React.useState(false);

  // VARIABLES
  const {
    id,
    description,
    chosen,
    totalRemarks,
    totalUnSeenRemarks,
    totalExcellent,
    totalLikes,
    totalNeutral,
    memberVotes,
    contextData,
  } = answer;

  // METHODS
  const onRemoveWrapper = async (answerId: number) => {
    setLoading(true);
    await onRemove(answerId);
    setLoading(false);
  };

  const onLikeWrapper = async (answerId: number) => {
    setLoadingVote(true);
    await onLike(answerId);
    setLoadingVote(false);
  };

  const onDislikeWrapper = async (answerId: number) => {
    setLoadingVote(true);
    await onDislike(answerId);
    setLoadingVote(false);
  };

  const onVoteWrapper = async (answerId: number, voteType: GenericVoteType) => {
    setLoadingVote(true);
    await onVote(answerId, voteType);
    setLoadingVote(false);
  };

  const onChooseWrapper = async (answerId: number) => {
    setLoading(true);
    await onChoose(answerId);
    setLoading(false);
  };

  const onUnChooseWrapper = async (answerId: number) => {
    setLoading(true);
    await onUnChoose(answerId);
    setLoading(false);
  };

  return (
    <Card
      fullWidth
      loading={loading || loadingVote}
      disabled={!chosen && (blocked || selectionMode)}
    >
      <CardContent>
        {(selectionMode || blocked) && (
          <div className={classes.topbar}>
            {!chosen && (selectionMode || blocked) && (
              <Chip
                label="Kies"
                icon={
                  <CheckBoxIcon style={{ color: theme.palette.success.main }} />
                }
                size="small"
                variant="outlined"
                onClick={() => onChooseWrapper(id)}
              />
            )}
            {chosen && (
              <CommonIconButton
                icon={<DoneIconComponent />}
                size="small"
                onClick={() => onUnChooseWrapper(id)}
              />
            )}
          </div>
        )}
        <div className={classes.header}>
          <div>
            <RemarksIconButton
              totalRemarks={totalRemarks}
              totalUnSeenRemarks={totalUnSeenRemarks}
              onClick={() => onOpenAnswerRemarks(id)}
            />
          </div>

          <div
            className={`${classes.headerDescription} ${
              selectionMode ? classes.textSelectionMode : ""
            }`}
          >
            <Text>{description}</Text>
          </div>
          <div>
            <ConfirmationDialog
              title="Verwijderen"
              confirmButtonWarningColor
              onConfirm={() => onRemoveWrapper(id)}
            >
              {({ open: openDialog }) => (
                <CommonMenu>
                  {({ open: openMenu }) => ({
                    button: (
                      <CommonIconButton
                        disabled={blocked}
                        icon={<MoreOptionsIcon />}
                        size="small"
                        style={{ marginRight: -10 }}
                        onClick={openMenu}
                      />
                    ),
                    items: [
                      {
                        label: "Verwijderen",
                        icon: <DeleteIcon />,
                        handleClick: openDialog,
                      },
                    ],
                  })}
                </CommonMenu>
              )}
            </ConfirmationDialog>
          </div>
        </div>
        <div className={classes.bottomBar}>
          <div className={classes.bottomBarLeftColumn}>
            <TotalUsersInvolved memberVotes={memberVotes} />
          </div>
          <div className={`${selectionMode ? classes.textSelectionMode : ""}`}>
            <Voting
              voteAmountYes={`${totalExcellent}`}
              voteAmountMaybe={`${totalLikes}`}
              voteAmountNo={`${totalNeutral}`}
              userVoteType={contextData.userVote}
              locked={blocked}
              selectionMode={selectionMode}
              disableStars={true}
              small={true}
              onVote={(voteType: GenericVoteType) =>
                onVoteWrapper(id, voteType)
              }
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  topbar: {
    position: "relative",
    marginTop: -5,
    marginLeft: -16,
    marginRight: -16,
    borderBottom: "1px solid #eee",
    padding: "5px 0",
    textAlign: "center",
    zIndex: 10,
  },
  header: {
    display: "flex",
    alignItems: "baseline",
    paddingLeft: 6,
    marginLeft: -16,
  },
  headerDescription: {
    flexGrow: 1,
    wordBreak: "break-all",
    marginLeft: 10,
  },
  headerButton: {},
  bottomBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 6px 0",
    marginLeft: -16,
    marginRight: -16,
  },
  bottomBarLeftColumn: { zIndex: 10 },
  chipButton: { left: 6 },
  textSelectionMode: {
    position: "relative",
    zIndex: 10,
  },
}));

export default Answer;
