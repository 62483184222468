import * as React from "react";

import { CostDivision } from "../../../../../presentation";
import { CostDivisionModel } from "../../../../../application";
import { makeStyles } from "@material-ui/core";

export interface CostDivisionListProps {
  costDivisions: CostDivisionModel[];
  onChange(userId: number, share: number): void;
}

export const CostDivisionList: React.FC<CostDivisionListProps> = ({
  costDivisions,
  onChange,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div>
      {costDivisions.map(({ userId, username, share, amount }) => (
        <div className={classes.costDivisionWrapper}>
          <CostDivision
            userId={userId}
            username={username}
            share={share}
            amount={amount}
            onChange={onChange}
          />
        </div>
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  costDivisionWrapper: {
    margin: "16px 4px",
  },
}));

export default CostDivisionList;
