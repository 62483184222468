import * as React from "react";

import { DateItemModel, Result } from "../../../application";

import { useItemService } from "../../../presentation";

interface HookState {
  dateItem: DateItemModel | null;
  loading: boolean;
  reasons: string[] | null;
}

interface HookMethods {
  loadDateItem(id: number): Promise<Result<DateItemModel> | Result>;
}

export const useItemUsecases = (): [HookState, HookMethods] => {
  const [itemSvc] = useItemService();
  const [dateItem, setDateItem] = React.useState<DateItemModel | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [reasons, setReasons] = React.useState<string[] | null>(null);

  const loadDateItem = async (
    id: number
  ): Promise<Result<DateItemModel> | Result> => {
    setLoading(true);
    const result = await itemSvc.getDateItem(id);
    setLoading(false);

    if (result.isSuccess && result.value) setDateItem(result.value);
    if (result.isFailed) setReasons(result.reasons);

    return result;
  };

  return [{ dateItem, loading, reasons }, { loadDateItem }];
};
