import * as React from "react";

import {
  CommonButton,
  HeaderNormal,
  HeaderSmall,
} from "../../../../presentation";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

interface CommonInfoDialogProps {
  open: boolean;
  title: string | React.ReactNode;
  content: React.ReactNode;
  maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl";
  fullScreen?: boolean;
  onClose(): void;
}

export const CommonInfoDialog: React.FC<CommonInfoDialogProps> = ({
  open,
  title,
  content,
  maxWidth = "md",
  fullScreen,
  onClose,
}) => {
  // STYLES
  const theme = useTheme();

  // VARIABLES
  const smallScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const _fullScreen = fullScreen === undefined ? smallScreen : fullScreen;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth
      fullScreen={_fullScreen}
    >
      <DialogTitle>
        <HeaderNormal title={title} disableGutter />
      </DialogTitle>
      <DialogContent style={{ position: "relative" }} dividers>
        {content}
      </DialogContent>
      <DialogActions>
        <CommonButton label="Sluiten" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({}));

export default CommonInfoDialog;
