import * as React from "react";

import { DateItemService } from "../../../application";
import { INJECTION_TOKENS } from "../../../application";
import { useDependencyInjectionService } from "../../../presentation";

export const useDateItemService = (): [DateItemService] => {
  const [containerSvc] = useDependencyInjectionService();
  const dateItemSvc = containerSvc.getService<DateItemService>(
    INJECTION_TOKENS.DateItemService
  );

  return [dateItemSvc];
};
