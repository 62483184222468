import * as React from "react";

import {
  CommonButton,
  InformationDialog,
  VotedUserList,
} from "../../../../../../presentation";
import { makeStyles, useTheme } from "@material-ui/core";

import { MemberVoteModel } from "../../../../../../application";
import PersonIcon from "@material-ui/icons/PersonOutlineOutlined";

export interface TotalUsersInvolvedProps {
  memberVotes: MemberVoteModel[];
}

export const TotalUsersInvolved: React.FC<TotalUsersInvolvedProps> = ({
  memberVotes,
}) => {
  // STYLES
  const classes = useStyles();
  const theme = useTheme();

  // VARIABLES
  const totalUsers = memberVotes.length;
  const totalVotes = memberVotes.filter((mv) => mv.isVoted === true).length;
  const allVoted = totalVotes > 1 && totalVotes === totalUsers;

  return (
    <InformationDialog
      title="Wat hebben de gebruikers gestemd?"
      content={<VotedUserList memberVotes={memberVotes} />}
    >
      {({ open }) => (
        <CommonButton
          color={allVoted ? "primary" : "default"}
          label={`${totalVotes}/${totalUsers}`}
          startIcon={
            <PersonIcon style={{ color: theme.palette.text.primary }} />
          }
          variant="text"
          onClick={open}
        />
      )}
    </InformationDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: { display: "flex", alignItems: "center" },
}));

export default TotalUsersInvolved;
