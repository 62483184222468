import * as React from "react";

import {
  CommonButton,
  ContainerLarge,
  HeaderExtraSmall,
  HeaderLarge,
  SectionBlock,
  SectionBox,
} from "../../../presentation";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { AboutUsImage } from "../../../presentation";
import { useHistory } from "react-router-dom";

export interface AboutUsPageProps {}

export const AboutUsPage: React.FC<AboutUsPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // HOOKS
  const { push, goBack } = useHistory();

  // METHODS
  const handleNavigation = () => {
    push("/contact");
  };

  return (
    <ContainerLarge>
      <SectionBox>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton size="medium" onClick={() => goBack()}>
            <ArrowBackIcon />
          </IconButton>

          <HeaderLarge title="Over ons" style={{ margin: 0 }} />
        </div>
      </SectionBox>

      <SectionBlock>
        <SectionBox>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <SectionBox>
                <div className={classes.aboutUsImage}></div>
              </SectionBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.aboutUsText}>
                <SectionBox>
                  <HeaderExtraSmall title="Wij zijn Stefan en Merlijn, jonge ondernemers en de visionairs achter Plokkie. Ons doel? Mensen inspireren om samen onvergetelijke avonturen te beleven door het plannen van activiteiten te vereenvoudigen." />
                </SectionBox>
                <SectionBox>
                  <HeaderExtraSmall title="Met Plokkie willen we de samenleving versterken door positieve verbindingen te creëren. Vragen of feedback? Neem gerust contact met ons op. We zijn er altijd voor je!" />
                </SectionBox>

                <SectionBox>
                  <CommonButton
                    color="primary"
                    label="Feedback en contact"
                    size="large"
                    variant="contained"
                    onClick={handleNavigation}
                  />
                </SectionBox>
              </div>
            </Grid>
          </Grid>
        </SectionBox>
      </SectionBlock>
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  sectionContent: {
    position: "relative",
    padding: "5rem 0",
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.4)",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  aboutUsImage: {
    background: `url(${AboutUsImage})`,
    backgroundSize: "cover",
    backgroundPositionX: -35,
    width: 350,
    height: 350,
    margin: "auto",
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: 350,
    [theme.breakpoints.only("xs")]: {
      maxWidth: "100%",
    },
  },
  aboutUsText: {
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },
}));

export default AboutUsPage;
