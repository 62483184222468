import * as React from "react";

import {
  CalendarImage,
  CostsImage,
  LocationImage,
  QuestionsImage,
  TasksImage,
} from "../../../../../presentation";

import { ItemType } from "../../../../../application";

export interface ItemTypeImageProps {
  itemType: ItemType;
  className?: string;
  style?: React.CSSProperties;
}

export const ItemTypeImage: React.FC<ItemTypeImageProps> = ({
  itemType,
  className,
  style,
}) => {
  const getImage = (itemType: ItemType) => {
    switch (itemType) {
      case ItemType.Date:
        return CalendarImage;
      case ItemType.Location:
        return LocationImage;
      case ItemType.Tasks:
        return TasksImage;
      case ItemType.Questions:
        return QuestionsImage;
      case ItemType.Costs:
        return CostsImage;
      default:
        return "unknown";
    }
  };

  return <img src={getImage(itemType)} className={className} style={style} />;
};

export default ItemTypeImage;
