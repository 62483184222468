import * as React from "react";

import { Grid, makeStyles } from "@material-ui/core";
import { ItemOverviewViewModel, ItemType } from "../../../../../application";

import { ItemCard } from "../../../../../presentation";

export interface ItemCardListProps {
  items: ItemOverviewViewModel[];
  onItemClick(itemId: number, itemType: ItemType): void;
}

const useStyles = makeStyles((theme) => ({
  gridItem: {
    marginRight: 30,
    marginBottom: 30,
    [theme.breakpoints.only("xs")]: {
      marginRight: 0,
    },
  },
}));

export const ItemCardList: React.FC<ItemCardListProps> = ({
  items,
  onItemClick,
}) => {
  const classes = useStyles();

  return (
    <Grid container>
      {items.map((item) => (
        <Grid
          item
          key={item.id}
          xs={12}
          sm="auto"
          classes={{ root: classes.gridItem }}
        >
          <ItemCard
            itemId={item.id}
            itemType={item.itemType}
            label={item.itemTypeLabel}
            summaryText={item.summaryText}
            done={item.done}
            hasOpenActions={item.hasOpenActions}
            totalUnSeenRemarks={item.totalUnSeenRemarks}
            onClick={onItemClick}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ItemCardList;
