import * as React from "react";

import {
  Aligner,
  ContainerLarge,
  HeaderLarge,
  HeaderSmall,
  Paragraph,
  SectionBox,
  SuccessImage,
} from "../../../presentation";

import { makeStyles } from "@material-ui/core";

export interface SignupSuccessPageProps {}

export const SignupSuccessPage: React.FC<SignupSuccessPageProps> = () => {
  // STYLES
  const classes = useStyles();

  return (
    <>
      <Aligner horizontal>
        <ContainerLarge>
          <SectionBox>
            <div style={{ maxWidth: 500, margin: "auto" }}>
              <img src={SuccessImage} className={classes.image} />
            </div>
          </SectionBox>

          <SectionBox>
            <HeaderLarge title="Het is gelukt!" color="primary" />
            <HeaderSmall title="Je bent geregistreerd." />
          </SectionBox>

          <SectionBox>
            <div style={{ maxWidth: 350, margin: "auto", textAlign: "center" }}>
              <Paragraph>
                Ga naar je mailbox om verder te gaan met deze Plokkie.
              </Paragraph>
            </div>
          </SectionBox>
        </ContainerLarge>
      </Aligner>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100%",
  },
}));

export default SignupSuccessPage;
