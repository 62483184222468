import * as React from "react";

import { CostLineModel, NumberFormatUtils } from "../../../../../application";
import { Grid, makeStyles } from "@material-ui/core";
import {
  HeaderExtraSmall,
  Paragraph,
  SectionBox,
} from "../../../../../presentation";

export interface CostLinesProps {
  costLines: CostLineModel[];
}

export const CostLines: React.FC<CostLinesProps> = ({ costLines }) => {
  // STYLES
  const classes = useStyles();

  return (
    <div>
      <SectionBox>
        <HeaderExtraSmall title="Totale kosten" />
      </SectionBox>

      <SectionBox>
        {costLines.map((line, index) => (
          <Grid key={index} container>
            <Grid item xs={6} md={4}>
              <Paragraph>{line.username}</Paragraph>
            </Grid>
            <Grid item xs={6} md={2}>
              <div className={classes.amountWrapper}>
                <Paragraph>
                  <strong>
                    €{" "}
                    {NumberFormatUtils.format(line.amount, {
                      minimumFractionDigits: 2,
                    })}
                  </strong>
                </Paragraph>
              </div>
            </Grid>
          </Grid>
        ))}
      </SectionBox>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  amountWrapper: {
    [theme.breakpoints.only("xs")]: {
      textAlign: "right",
    },
  },
}));

export default CostLines;
