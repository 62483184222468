import * as React from "react";

import { SvgIcon } from "@material-ui/core";

export interface SmileNeutralIconProps {
  fontSize?: "small" | "inherit" | "large" | "default";
}

export const SmileNeutralIcon: React.FC<SmileNeutralIconProps> = ({
  fontSize = "default",
}) => {
  return (
    <SvgIcon fontSize={fontSize} viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M8,16 L8,14 L16,14 L16,16 L8,16 Z M14,11 L14,9 L17,9 L17,11 L14,11 Z M7,11 L7,9 L10,9 L10,11 L7,11 Z"
      />
      {/* <g>
        <path d="m12 24c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12zm0-23c-6.065 0-11 4.935-11 11s4.935 11 11 11 11-4.935 11-11-4.935-11-11-11z" />
      </g>
      <g>
        <path d="m7 11c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.551 0-1 .449-1 1s.449 1 1 1 1-.449 1-1-.449-1-1-1z" />
      </g>
      <g>
        <path d="m17 11c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.551 0-1 .449-1 1s.449 1 1 1 1-.449 1-1-.449-1-1-1z" />
      </g>
      <g>
        <path d="m15.5 18h-7c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h7c.276 0 .5.224.5.5s-.224.5-.5.5z" />
      </g> */}
    </SvgIcon>
  );
};

export default SmileNeutralIcon;
