import * as React from "react";

import { INJECTION_TOKENS } from "../../../application";
import { UserService } from "../../../application";
import { useDependencyInjectionService } from "../../../presentation";

export const useUserService = (): [UserService] => {
  const [dependencyInjectionSvc] = useDependencyInjectionService();
  const userService = dependencyInjectionSvc.getService<UserService>(
    INJECTION_TOKENS.UserService
  );

  return [userService];
};
