import {
  INJECTION_TOKENS,
  UserService,
  Result,
  UpdateUserPreferencesModel,
  UserPreferencesModel,
  UserProductToursModel,
  type UserRepository,
} from "../../../application";
import { inject, injectable } from "tsyringe";

@injectable()
export class UserServiceImpl implements UserService {
  constructor(
    @inject(INJECTION_TOKENS.UserRepository)
    private userRepository: UserRepository
  ) {}

  async getPreferences(
    userId: number
  ): Promise<Result<UserPreferencesModel> | Result<void>> {
    return await this.userRepository.getPreferences(userId);
  }

  async updatePreferences(
    model: UpdateUserPreferencesModel
  ): Promise<Result<void>> {
    return await this.userRepository.updatePreferences(model);
  }

  async getProductTours(): Promise<
    Result<UserProductToursModel> | Result<void>
  > {
    return await this.userRepository.getProductTours();
  }

  async updateProductTours(
    model: UserProductToursModel
  ): Promise<Result<void>> {
    return await this.userRepository.updateProductTours(model);
  }
}
