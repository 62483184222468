import * as React from "react";

import {
  Aligner,
  CommonButton,
  ContainerLarge,
  HeaderLarge,
  LoadingScreen,
  Paragraph,
  SectionBox,
  useTopicUsecases,
} from "../../../presentation";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  makeStyles,
} from "@material-ui/core";

import { DateUtils } from "../../../application";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";

export interface MyTopicsPageProps {}

export const MyTopicsPage: React.FC<MyTopicsPageProps> = () => {
  // STYLES
  const classes = useStyles();

  // STATE
  const [page, setPage] = React.useState(0);

  // USECASES
  const [{ userTopics, loading, reasons }, { getUserTopics }] =
    useTopicUsecases();

  React.useEffect(() => {
    loadUserTopics();
  }, []);

  // METHODS
  const loadUserTopics = async () => {
    const newPage = page + 1;
    setPage(newPage);

    await getUserTopics(newPage);
  };

  const handleLoadMore = async () => {
    await loadUserTopics();
  };

  return (
    <ContainerLarge>
      <SectionBox>
        <HeaderLarge title="Mijn Plokkies" />
      </SectionBox>

      <SectionBox>
        {!userTopics.length && loading && <LoadingScreen />}
        {userTopics && userTopics.length !== 0 && (
          <Paper>
            <List>
              {userTopics.map((ut) => (
                <div key={ut.topicId}>
                  <div className={classes.item}>
                    <ListItem
                      button
                      component={Link}
                      to={`/topics/${ut.topicId}`}
                    >
                      <ListItemText
                        primary={<Paragraph>{ut.title}</Paragraph>}
                        secondary={`Laatst gezien op: ${
                          ut.lastSeenOn
                            ? DateUtils.getShortDateString2(ut.lastSeenOn)
                            : "-"
                        }`}
                      />
                    </ListItem>
                  </div>
                  <Divider />
                </div>
              ))}
            </List>

            <SectionBox>
              <Aligner horizontal>
                <CommonButton
                  label="Meer laden"
                  loading={loading}
                  startIcon={<ExpandMoreIcon />}
                  onClick={handleLoadMore}
                />
              </Aligner>
            </SectionBox>
          </Paper>
        )}
      </SectionBox>
    </ContainerLarge>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    margin: "16px 0",
  },
}));

export default MyTopicsPage;
