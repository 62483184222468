import * as React from "react";

import { ItemImage } from "../../../../../presentation";
import { ItemType } from "../../../../../application";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    position: "relative",
    width: (props: any) => props.size,
    height: (props: any) => props.size,
    borderRadius: (props: any) => props.size,
    border: "1px solid #ddd",
    backgroundColor: "#fff",
  },
}));

export interface ItemImageContainerProps {
  itemType: ItemType;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}

export const ItemImageContainer: React.FC<ItemImageContainerProps> = ({
  itemType,
  size = 100,
  style,
  className,
}) => {
  // STYLES
  const classes = useStyles({ size });

  return (
    <div className={`${classes.imageContainer} ${className}`} style={style}>
      <ItemImage itemType={itemType} />
    </div>
  );
};

export default ItemImageContainer;
