import * as React from "react";

import {
  CalculationLineModel,
  NumberFormatUtils,
} from "../../../../../application";
import { Grid, makeStyles } from "@material-ui/core";
import {
  HeaderExtraSmall,
  LongArrowRightIcon,
  Paragraph,
  SectionBox,
} from "../../../../../presentation";

export interface CostCalculationLinesProps {
  calculationLines: CalculationLineModel[];
}

export const CostCalculationLines: React.FC<CostCalculationLinesProps> = ({
  calculationLines,
}) => {
  // STYLES
  const classes = useStyles();

  return (
    <div>
      <SectionBox>
        <HeaderExtraSmall title="Verrekening" />
      </SectionBox>

      <SectionBox>
        {calculationLines.map((line, index) => (
          <Grid key={index} container alignItems="center">
            <Grid item xs={4} md={2}>
              <Paragraph>{line.namePayer}</Paragraph>
            </Grid>
            <Grid item xs={4} md={2}>
              <div className={classes.amountWrapper}>
                <Paragraph>
                  <strong>
                    €{" "}
                    {NumberFormatUtils.format(line.amountToPay, {
                      minimumFractionDigits: 2,
                    })}
                  </strong>
                </Paragraph>
                <div className={classes.arrowIcon}>
                  <LongArrowRightIcon />
                </div>
              </div>
            </Grid>
            <Grid item xs={4} md={2}>
              <div className={classes.nameReceiverWrapper}>
                <Paragraph>{line.nameReceiver}</Paragraph>
              </div>
            </Grid>
          </Grid>
        ))}
        {calculationLines.length === 0 && (
          <Paragraph>Er zijn nog geen kosten opgevoerd.</Paragraph>
        )}
      </SectionBox>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  amountWrapper: {
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },
  nameReceiverWrapper: {
    [theme.breakpoints.only("xs")]: {
      textAlign: "right",
    },
  },
  arrowIcon: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.warning.main,
      fontSize: "5rem",
      margin: "-25px 0",
    },
  },
}));

export default CostCalculationLines;
