import * as React from "react";

import { GenericTextFieldProps } from "./generic-text-field";
import { TextField } from "@material-ui/core";

export interface MultilineTextFieldProps extends GenericTextFieldProps {
  rows: number;
  rowsMax?: number;
}

export const MultilineTextField: React.FC<MultilineTextFieldProps> = ({
  displayLabel,
  fieldName,
  type = "text",
  inputRef,
  errorMessage,
  rows,
  rowsMax = rows,
  defaultValue,
}) => {
  return (
    <TextField
      label={displayLabel}
      name={fieldName}
      type={type}
      multiline
      rows={rows}
      rowsMax={rowsMax}
      defaultValue={defaultValue}
      inputRef={inputRef}
      error={!!errorMessage}
      helperText={errorMessage}
      margin="normal"
      fullWidth
      variant="outlined"
    />
  );
};

export default MultilineTextField;
