import { inject, injectable } from "tsyringe";
import {
  INJECTION_TOKENS,
  AddLocationPropositionRequest,
  AddRemarkRequest,
  GenericVoteType,
  type LocationItemRepository,
  LocationItemModel,
  LocationPropositionRemarksModel,
  Result,
  UpdateLocationPropositionRequest,
  VoteOnLocationPropositionRequest,
  LocationItemService,
} from "../../../application";

@injectable()
export class LocationItemServiceImpl implements LocationItemService {
  constructor(
    @inject(INJECTION_TOKENS.LocationItemRepository)
    private locationItemRepo: LocationItemRepository
  ) {}

  async getLocationItem(
    itemId: number
  ): Promise<Result<LocationItemModel> | Result<void>> {
    return await this.locationItemRepo.getLocationItem(itemId);
  }

  async addlocationProposition(
    locationItemId: number,
    name: string,
    locationLink: string,
    streetName: string,
    zipCode: string,
    location: string
  ): Promise<Result<void>> {
    const request: AddLocationPropositionRequest = {
      name,
      locationLink,
      streetName,
      zipCode,
      location,
    };

    return await this.locationItemRepo.addlocationProposition(
      locationItemId,
      request
    );
  }

  async removeLocationProposition(
    propositionId: number
  ): Promise<Result<void>> {
    return await this.locationItemRepo.removeLocationProposition(propositionId);
  }

  async updateLocationProposition(
    propositionId: number,
    name: string,
    locationLink: string,
    streetName: string,
    zipCode: string,
    location: string
  ): Promise<Result<void>> {
    const request: UpdateLocationPropositionRequest = {
      name,
      locationLink,
      streetName,
      zipCode,
      location,
    };

    return await this.locationItemRepo.updateLocationProposition(
      propositionId,
      request
    );
  }

  async vote(
    propositionId: number,
    voteType: GenericVoteType
  ): Promise<Result<void>> {
    const request: VoteOnLocationPropositionRequest = {
      voteType: voteType,
    };

    return await this.locationItemRepo.vote(propositionId, request);
  }

  async getRemarks(
    propositionId: number
  ): Promise<Result<LocationPropositionRemarksModel> | Result<void>> {
    return await this.locationItemRepo.getRemarks(propositionId);
  }

  async addRemark(propositionId: number, text: string): Promise<Result<void>> {
    const request = new AddRemarkRequest(text);

    return await this.locationItemRepo.addRemark(propositionId, request);
  }

  async removeRemark(remarkId: number): Promise<Result<void>> {
    return await this.locationItemRepo.removeRemark(remarkId);
  }

  async confirm(propositionId: number): Promise<Result<void>> {
    return await this.locationItemRepo.confirm(propositionId);
  }
}
