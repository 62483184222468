import { inject, injectable } from "tsyringe";
import {
  ActivityModel,
  INJECTION_TOKENS,
  TopicService,
  type TopicRepository,
  CreateTopicModel,
  TopicViewModel,
  Result,
  AddItemsModel,
  ItemOverviewViewModel,
  TopicRegistrationViewModel,
  SignupModel,
  MemberViewModel,
  UserTopicsModel,
} from "../../../application";

@injectable()
export class TopicServiceImpl implements TopicService {
  constructor(
    @inject(INJECTION_TOKENS.TopicRepository)
    private httpClient: TopicRepository
  ) {}

  async createTopic(model: CreateTopicModel): Promise<Result<number> | Result> {
    return await this.httpClient.createTopic(model);
  }

  async getTopic(id: number): Promise<Result<TopicViewModel> | Result> {
    return await this.httpClient.getTopic(id);
  }

  async getActivities(
    id: number
  ): Promise<Result<ActivityModel[]> | Result<void>> {
    return await this.httpClient.getActivities(id);
  }

  async updateTopic(
    topicId: number,
    title: string,
    description: string
  ): Promise<Result<void>> {
    return await this.httpClient.updateTopic(topicId, title, description);
  }

  async addItems(id: number, model: AddItemsModel): Promise<Result<void>> {
    return await this.httpClient.addItems(id, model);
  }

  async getItems(
    id: number
  ): Promise<Result<ItemOverviewViewModel[]> | Result<void>> {
    return await this.httpClient.getItems(id);
  }

  async getByRegistrationKey(
    id: number,
    registrationKey: string
  ): Promise<Result<TopicRegistrationViewModel> | Result> {
    return await this.httpClient.getByRegistrationKey(id, registrationKey);
  }

  async signup(
    id: number,
    registrationKey: string,
    model: SignupModel
  ): Promise<Result> {
    return await this.httpClient.signup(id, registrationKey, model);
  }

  async getMembers(
    topicId: number
  ): Promise<Result<MemberViewModel[]> | Result<void>> {
    return await this.httpClient.getMembers(topicId);
  }

  async getUserTopics(
    page: number
  ): Promise<Result<UserTopicsModel> | Result<void>> {
    return await this.httpClient.getUserTopics(page);
  }

  async sendGeneralMessage(
    topicId: number,
    message: string
  ): Promise<Result<void>> {
    return await this.httpClient.sendGeneralMessage(topicId, message);
  }
}
