import {
  ActivityModel,
  AddItemsModel,
  CreateTopicModel,
  ItemOverviewViewModel,
  MemberViewModel,
  Result,
  SignupModel,
  TopicRegistrationViewModel,
  TopicRepository,
  TopicViewModel,
  UserTopicsModel,
} from "../../../application";
import { get, post, put } from "../../../infrastructure";

export class TopicRepositoryImpl implements TopicRepository {
  private readonly CONTROLLER_NAME: string = "topics";

  async createTopic(model: CreateTopicModel): Promise<Result<number> | Result> {
    return await post<number>(this.CONTROLLER_NAME, model);
  }

  async getTopic(id: number): Promise<Result<TopicViewModel> | Result> {
    return await get<TopicViewModel>(`${this.CONTROLLER_NAME}/${id}`);
  }

  async getActivities(
    id: number
  ): Promise<Result<ActivityModel[]> | Result<void>> {
    return await get<ActivityModel[]>(
      `${this.CONTROLLER_NAME}/${id}/activities`
    );
  }

  async updateTopic(
    topicId: number,
    title: string,
    description: string
  ): Promise<Result<void>> {
    const request = { title, description };

    return await put(`topics/${topicId}`, request);
  }

  async addItems(id: number, model: AddItemsModel): Promise<Result<void>> {
    return await post(`${this.CONTROLLER_NAME}/${id}/add-items`, model);
  }

  async getItems(
    id: number
  ): Promise<Result<ItemOverviewViewModel[]> | Result<void>> {
    return await get<ItemOverviewViewModel[]>(
      `${this.CONTROLLER_NAME}/${id}/items`
    );
  }

  async getByRegistrationKey(
    id: number,
    registrationKey: string
  ): Promise<Result<void> | Result<TopicRegistrationViewModel>> {
    return await get(
      `${this.CONTROLLER_NAME}/${id}/for-registration/${registrationKey}`
    );
  }

  async signup(
    id: number,
    registrationKey: string,
    model: SignupModel
  ): Promise<Result<void>> {
    return await post(
      `${this.CONTROLLER_NAME}/${id}/sign-up/${registrationKey}`,
      model
    );
  }

  async getMembers(
    topicId: number
  ): Promise<Result<MemberViewModel[]> | Result<void>> {
    return await get<MemberViewModel[]>(`topics/${topicId}/members`);
  }

  async getUserTopics(
    page: number
  ): Promise<Result<UserTopicsModel> | Result<void>> {
    return await get<UserTopicsModel>(`topics/user-topics/${page}`);
  }

  async sendGeneralMessage(
    topicId: number,
    message: string
  ): Promise<Result<void>> {
    const request = { message };

    return await post(`topics/${topicId}/send-message`, request);
  }
}
